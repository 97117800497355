import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { useLiveQuery } from 'dexie-react-hooks';
import { DataGrid, plPL, GridColDef } from '@mui/x-data-grid';
import {
  Box,
  IconButton,
} from '@mui/material';
import {
  AddCircleOutline as IconAddCircleOutline,
  Search as IconSearch,
} from '@mui/icons-material';

import { DS } from '../_services';
import PosHistoryDialog from './PosHistoryDialog';
import CreateTicketDialog from './CreateTicketDialog';

function DeviceTab(props) {
  const { visitSession, specAction } = props;
  const { clientId } = useParams();
  const [detailDialog, setDetailDialog] = useState({ open: false });
  const [createTicketDialog, setCreateTicketDialog] = useState({ open: false });

  /**
   * @type {GridColDef[]}
   */
  const columns = [
    {
      field: 'id',
      headerName: '',
      sortable: false,
      filterable: false,
      width: 80,
      renderCell: (params) => (
        <>
          <IconButton
            size="small"
            color="info"
            onClick={(event) => viewDetailHandler(event, params.row.id)}
          >
            <IconSearch />
          </IconButton>
          {visitSession.visitInProgress && (
            <IconButton
              size="small"
              color="info"
              onClick={(event) => createTicketHandler(event, params.row.id)}
            >
              <IconAddCircleOutline />
            </IconButton>
          )}
        </>
      ),
    },
    { field: 'posType', headerName: 'Pos', width: 300, valueGetter: (param) => param.row.posType.name },
    { field: 'barcode', headerName: 'Barcode', width: 100, valueGetter: (param) => param.row.barcode },
    { field: 'tagId', headerName: 'TAG ID', width: 100, valueGetter: (param) => param.row.tagId },
    { field: 'sap4Id', headerName: 'SAP4 ID', width: 100, valueGetter: (param) => param.row.sap4Id },
    {
      field: 'lastSanitDate',
      headerName: 'Ost. sanit.',
      width: 120,
      valueGetter: (param) => (param.row.lastSanitDate
        ? moment(String(param.row.lastSanitDate)).format('YYYY-MM-DD')
        : ''),
    },
    {
      field: 'nextSanitDate',
      headerName: 'Nast. sanit.',
      width: 120,
      valueGetter: (param) => (param.row.nextSanitDate
        ? moment(String(param.row.nextSanitDate)).format('YYYY-MM-DD')
        : ''),
    },
    {
      field: 'lastInspDate',
      headerName: 'Ost. przegląd',
      width: 120,
      valueGetter: (param) => (param.row.lastInspDate
        ? moment(String(param.row.lastInspDate)).format('YYYY-MM-DD')
        : ''),
    },
    {
      field: 'nextInspDate',
      headerName: 'Nast. przegląd',
      width: 120,
      valueGetter: (param) => (param.row.nextInspDate
        ? moment(String(param.row.nextInspDate)).format('YYYY-MM-DD')
        : ''),
    },
  ];

  /**
   * @type {TPos[]}
   */
  const rows = useLiveQuery(async () => DS.Pos.findAllPosForClient(parseInt(clientId, 10)));

  /**
   * @param {Event} event
   * @param {number} posId
   */
  const viewDetailHandler = (event, posId) => {
    event.defaultMuiPrevented = true;
    setDetailDialog((s) => ({
      ...s,
      open: true,
      posId,
    }));
  };

  /**
   * @param {Event} event
   * @param {number} posId
   */
  const createTicketHandler = (event, posId) => {
    event.defaultMuiPrevented = true;
    setCreateTicketDialog((s) => ({
      ...s,
      open: true,
      posId,
      clientId,
    }));
  };

  const onAfterCreateTicket = () => {
    setCreateTicketDialog((s) => ({ open: false }));
    if (specAction && (specAction instanceof Function)) {
      specAction(1);
    }
  };

  return (
    <Box component="div" sx={{ display: 'flex', height: 'calc(100vh - 140px)' }}>
      <Box component="div" sx={{ flexGrow: 1 }}>
        <DataGrid
          rows={rows || []}
          columns={columns}
          sx={{ mt: 1 }}
          autoPageSize
          pageSize={25}
          rowsPerPageOptions={[5, 10, 25]}
          hideFooterSelectedRowCount
          density="compact"
          localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
        />
        {detailDialog.open && (
          <PosHistoryDialog
            onAccept={() => setDetailDialog((s) => ({ open: false }))}
            maxWidth="md"
            open={detailDialog.open}
            posId={detailDialog.posId}
          />
        )}
        {createTicketDialog.open && (
          <CreateTicketDialog
            onAccept={onAfterCreateTicket}
            onDecline={() => setCreateTicketDialog((s) => ({ open: false }))}
            maxWidth="sm"
            open={createTicketDialog.open}
            posId={createTicketDialog.posId}
          />
        )}
      </Box>
    </Box>
  );
}

DeviceTab.propTypes = {
  visitSession: PropTypes.shape(Object).isRequired,
  specAction: PropTypes.func,
};

DeviceTab.defaultProps = {
  specAction: null,
};

export default DeviceTab;
