import React from 'react';
import {
  FileUpload as IconFileUpload,
  FileDownload as IconFileDownload,
  CloudUpload as IconCloudUpload,
  CloudDownload as IconCloudDownload,
  Check as IconCheck,
  Error as IconError,
  // eslint-disable-next-line no-unused-vars
  SvgIconComponent,
} from '@mui/icons-material';

import { synchroConstants } from '../_constants';
import { apiService } from './api.service';
import { db } from './db';

let setUploadStep;
let setDownloadStep;

/**
 * @param uploadStepSetter {function}
 * @param downloadStepSetter {function}
 */
const init = (uploadStepSetter, downloadStepSetter) => {
  setUploadStep = uploadStepSetter;
  setDownloadStep = downloadStepSetter;
};

/**
 * @returns {Promise<boolean>}
 */
const synchroUpload = async () => {
  try {
    await upload(
      'ipcosJson/putTicket',
      'ticket',
      findAllTicketForSync,
      async (result) => {
        await setTicketAsSynced(result.id, result.idPda, result.idNew, result.idPdaNew);
      },
    );
    await upload(
      'ipcosJson/putTicketLog',
      'ticketLog',
      findAllTicketLogForSync,
      async (result) => {
        await setTicketLogAsSynced(result.id, result.idPda, result.idNew, result.idPdaNew);
      },
    );
    await upload(
      'ipcosJson/putVisit',
      'visit',
      findAllVisitForSync,
      async (result) => {
        await setVisitAsSynced(result.id, result.idPda, result.idNew, result.idPdaNew);
      },
    );
    await upload(
      'ipcosJson/putVisitTicket',
      'visitTicket',
      findAllVisitTicketForSync,
      async (result) => {
        await setVisitTicketAsSynced(result.id, result.idPda, result.idNew, result.idPdaNew);
      },
    );
    await upload(
      'ipcosJson/putActivity',
      'activity',
      findAllActivityForSync,
      async (result) => {
        await setActivityAsSynced(result.id, result.idPda, result.idNew, result.idPdaNew);
      },
    );
    await upload(
      'ipcosJson/putActivitySpare',
      'activitySpare',
      findAllActivitySpareForSync,
      async (result) => {
        await setActivitySpareAsSynced(result.id, result.idPda, result.idNew, result.idPdaNew);
      },
    );
    await upload(
      'ipcosJson/putTicketReport',
      'ticketReport',
      findAllTicketReportForSync,
      async (result) => {
        await setTicketReportAsSynced(result.id, result.idPda, result.idNew, result.idPdaNew);
      },
    );
    await upload(
      'ipcosJson/putVisitDay',
      'visitDay',
      findAllVisitDayForSync,
      async (result) => {
        await setVisitDayAsSynced(result.id, result.idPda, result.idNew, result.idPdaNew);
      },
    );
    // await upload('ipcosJson/putUserDay', 'userDay');
    await upload(
      'ipcosJson/putSpareOrder',
      'spareOrder',
      findAllSpareOrderForSync,
      async (result) => {
        await setSpareOrderAsSynced(result.id, result.idPda, result.idNew, result.idPdaNew);
      },
    );
    await upload(
      'ipcosJson/putSpareShift',
      'spareShift',
      findAllSpareShiftForSync,
      async (result) => {
        await setSpareShiftAsSynced(result.id, result.idPda, result.idNew, result.idPdaNew);
      },
    );
    // await upload('ipcosJson/putPosInventory', 'posInventory');
    // await upload('ipcosJson/putPosInventoryDetail', 'posInventoryDetail');
    await upload(
      'ipcosJson/putPosValve',
      'posValve',
      findAllPosValveForSync,
      async (result) => {
        await setPosValveAsSynced(result.id, result.idPda, result.idNew, result.idPdaNew);
      },
    );

    return Promise.resolve(true);
  } catch (err) {
    updateUploadLastStep(synchroConstants.ERROR, err.message);

    return Promise.reject(err);
  }
};

/**
 * @returns {Promise<boolean>}
 */
const synchroDownload = async () => {
  try {
    await download('ipcosJson/getTicket', 'ticket');
    await download('ipcosJson/getActivity', 'activity');
    await download('ipcosJson/getActivityCategory', 'activityCategory');
    await download('ipcosJson/getActivitySpare', 'activitySpare');
    await download('ipcosJson/getActivityType', 'activityType');
    await download('ipcosJson/getClient', 'client');
    await download('ipcosJson/getOtherActivityType', 'otherActivityType');
    await download('ipcosJson/getPos', 'pos');
    await download('ipcosJson/getSpareCategory', 'spareCategory');
    await download('ipcosJson/getSpareCategoryType', 'spareCategoryType');
    await download('ipcosJson/getSpareOrder', 'spareOrder');
    await download('ipcosJson/getSpareShipment', 'spareShipment');
    await download('ipcosJson/getOwnSpareStock', 'spareShift');
    await download('ipcosJson/getSpareType', 'spareType');
    await download('ipcosJson/getOtherUser', 'otherUser');
    await download('ipcosJson/getTicketReport', 'ticketReport');
    await download('ipcosJson/getTicketLog', 'ticketLog');
    await download('ipcosJson/getTicketHistory', 'ticketHistory');
    await download('ipcosJson/getTicketField', 'ticketField');
    await download('ipcosJson/getTicketResolveReason', 'ticketResolveReason');
    await download('ipcosJson/getTicketType', 'ticketType');
    await download('ipcosJson/getUserDay', 'userDay');
    await download('ipcosJson/getVisit', 'visit');
    await download('ipcosJson/getVisitDay', 'visitDay');
    await download('ipcosJson/getVisitTicket', 'visitTicket');
    await download('ipcosJson/getRefurbishmentType', 'refurbishmentType');
    await download('ipcosJson/getRefurbishment', 'refurbishment');
    await download('ipcosJson/getRefurbishmentSpare', 'refurbishmentSpare');
    await download('ipcosJson/getRefurbishmentLog', 'refurbishmentLog');
    await download('ipcosJson/getPosType', 'posType');
    await download('ipcosJson/getPosInventory', 'posInventory');
    await download('ipcosJson/getPosInventoryDetail', 'posInventoryDetail');
    await download('ipcosJson/getImproperTicketReason', 'improperTicketReason');
    await download('ipcosJson/getPosValve', 'posValve');

    return Promise.resolve(true);
  } catch (err) {
    updateDownloadLastStep(synchroConstants.ERROR, err.message);

    return Promise.reject(err);
  }
};

/**
 * @param url {string}
 * @param table {string}
 * @param dbReader {Promise<*>}
 * @param dbUpdater {Promise<*>}
 * @returns {Promise<*>}
 */
const upload = async (url, table, dbReader, dbUpdater) => {
  setUploadStep((a) => [
    ...a,
    {
      key: table,
      icon: statusIcon(synchroConstants.DB_READ_PENDING),
      text: table,
      status: synchroConstants.DB_READ_PENDING,
    },
  ]);

  const data = await dbReader();
  if (data.length === 0) {
    updateUploadStep(table, synchroConstants.OK, '');
    return Promise.resolve(true);
  }

  updateUploadStep(table, synchroConstants.UPLOAD_PENDING, '');

  try {
    const uploadResult = await apiService.post(url, data, true);
    if (!uploadResult) {
      return Promise.resolve(false);
    }

    updateUploadStep(table, synchroConstants.DB_WRITE_PENDING, '');
    Object.entries(uploadResult).forEach(([idx, item]) => {
      if (parseInt(item.error, 10) !== 0) {
        throw new Error(item.comment);
      }
      dbUpdater(item);
    });

    updateUploadStep(table, synchroConstants.OK, '');
  } catch (err) {
    return Promise.reject(err);
  }
  return Promise.resolve(true);
};

/**
 * @param url {string}
 * @param table {string}
 * @returns {Promise<*>}
 */
const download = (url, table) => {
  setDownloadStep((a) => [
    ...a,
    {
      key: table,
      icon: statusIcon(synchroConstants.DOWNLOAD_PENDING),
      text: table,
      status: synchroConstants.DOWNLOAD_PENDING,
    },
  ]);

  return apiService.get(url, true)
    .then((resultTable) => {
      updateDownloadStep(table, synchroConstants.DB_WRITE_PENDING, '');
      return db[table].clear()
        .then(() => db[table].bulkAdd(resultTable))
        .then(() => {
          updateDownloadStep(table, synchroConstants.OK, '');
        })
        .catch((err) => {
          updateDownloadStep(table, synchroConstants.ERROR, err.message);
          return Promise.reject(err.message);
        });
    })
    .catch((err) => Promise.reject(err));
};

/**
 * @param table {string}
 * @param status {string}
 * @param description {string|null}
 */
const updateUploadStep = (table, status, description) => updateStep(setUploadStep, table, status, description);

/**
 * @param table {string}
 * @param status {string}
 * @param description {string|null}
 */
const updateDownloadStep = (table, status, description) => updateStep(setDownloadStep, table, status, description);

/**
 * @param updatedList {function}
 * @param table {string}
 * @param status {string}
 * @param description {string|null}
 */
const updateStep = (updatedList, table, status, description) => {
  updatedList((ad) => (
    ad.map((a) => (a.key === table
      ? { ...a, icon: statusIcon(status), status, description: description || a.description }
      : { ...a }))
  ));
};

/**
 * @param status {string}
 * @param description {string|null}
 */
const updateUploadLastStep = (status, description) => updateLastStep(setUploadStep, status, description);

/**
 * @param status {string}
 * @param description {string|null}
 */
const updateDownloadLastStep = (status, description) => updateLastStep(
  setDownloadStep,
  status,
  description,
);

/**
 * @param updatedList {function}
 * @param status {string}
 * @param description {string|null}
 */
const updateLastStep = (updatedList, status, description) => {
  updatedList((ad) => (
    ad.map((a, idx) => (idx === ad.length - 1
      ? { ...a, status, icon: statusIcon(status), description: description || a.description }
      : { ...a }))
  ));
};

/**
 * @param status {string}
 * @returns {SvgIconComponent}
 */
const statusIcon = (status) => {
  const status2Icon = {
    [synchroConstants.DB_READ_PENDING]: <IconFileUpload />,
    [synchroConstants.UPLOAD_PENDING]: <IconCloudUpload />,
    [synchroConstants.DB_WRITE_PENDING]: <IconFileDownload />,
    [synchroConstants.DOWNLOAD_PENDING]: <IconCloudDownload />,
    [synchroConstants.ERROR]: <IconError />,
    [synchroConstants.OK]: <IconCheck />,
  };
  return status2Icon[status];
};

/**
 * Return all record needs to be synchronized with backend
 * @returns {Promise<Array<TVisitDay>>}
 */
const findAllVisitDayForSync = async () => db.visitDay
  .where('statusPda')
  .notEqual('S')
  .toArray();

/**
 * Mark given record as synchronized with backend
 * @param id {number}
 * @param idPda {string}
 * @param idNew {number}
 * @param idPdaNew {string}
 * @returns {Promise<number>}
 */
const setVisitDayAsSynced = async (id, idPda, idNew, idPdaNew) => db.visitDay
  .update({ id, idPda }, { id: idNew, idPda: idPdaNew, statusPda: 'S' });

/**
 * Fetch all ticket need to be synchronized with backend
 * @return {Promise<Array<TTicket>>}
 */
const findAllTicketForSync = async () => db.ticket
  .where('statusPda')
  .notEqual('S')
  .toArray();

/**
 * Mark given record as synchronized with backend
 * @param id {number}
 * @param idPda {string}
 * @param idNew {number}
 * @param idPdaNew {string}
 * @returns {Promise<number>}
 */
const setTicketAsSynced = async (id, idPda, idNew, idPdaNew) => {
  await db.transaction(
    'rw',
    db.ticket,
    db.ticketReport,
    db.ticketLog,
    db.activity,
    db.visit,
    db.visitTicket,
    db.posInventory,
    async () => {
      await db.ticket.update(
        { id: Number(id), idPda },
        { id: Number(idNew), idPda: idPdaNew },
      );
      await db.ticketLog
        .where({ ticketId: Number(id), ticketIdPda: idPda })
        .modify({ ticketId: Number(idNew), ticketIdPda: idPdaNew });
      await db.ticketReport
        .where({ ticketId: Number(id), ticketIdPda: idPda })
        .modify({ ticketId: Number(idNew), ticketIdPda: idPdaNew });
      await db.activity
        .where({ ticketId: Number(id), ticketIdPda: idPda })
        .modify({ ticketId: Number(idNew), ticketIdPda: idPdaNew });
      await db.visitTicket
        .where({ ticketId: Number(id), ticketIdPda: idPda })
        .modify({ ticketId: Number(idNew), ticketIdPda: idPdaNew });
      await db.posInventory
        .where({ ticketId: Number(id), ticketIdPda: idPda })
        .modify({ ticketId: Number(idNew), ticketIdPda: idPdaNew });
      await db.ticket.update(
        { id: Number(idNew), idPda: idPdaNew },
        { statusPda: 'S' },
      );
    },
  );
};

/**
 * Fetch all ticketLog need to be synchronized with backend
 * @return {Promise<Array<TTicketLog>>}
 */
const findAllTicketLogForSync = async () => db.ticketLog
  .where('statusPda')
  .notEqual('S')
  .toArray();

/**
 * Mark given record as synchronized with backend
 * @param id {number}
 * @param idPda {string}
 * @param idNew {number}
 * @param idPdaNew {string}
 * @returns {Promise<number>}
 */
const setTicketLogAsSynced = async (id, idPda, idNew, idPdaNew) => {
  await db.transaction(
    'rw',
    db.ticketLog,
    async () => {
      await db.ticketLog.update(
        { id: Number(id), idPda },
        { id: Number(idNew), idPda: idPdaNew, statusPda: 'S' },
      );
    },
  );
};

/**
 * Fetch all visit need to be synchronized with backend
 * @return {Promise<Array<TVisit>>}
 */
const findAllVisitForSync = async () => db.visit
  .where('statusPda')
  .notEqual('S')
  .toArray();

/**
 * Mark given record as synchronized with backend
 * @param id {number}
 * @param idPda {string}
 * @param idNew {number}
 * @param idPdaNew {string}
 * @returns {Promise<number>}
 */
const setVisitAsSynced = async (id, idPda, idNew, idPdaNew) => {
  await db.transaction(
    'rw',
    db.visit,
    db.visitTicket,
    db.ticketReport,
    db.activity,
    async () => {
      await db.visit.update(
        { id: Number(id), idPda },
        { id: Number(idNew), idPda: idPdaNew },
      );
      await db.ticketReport
        .where({ visitId: Number(id), visitIdPda: idPda })
        .modify({ visitId: Number(idNew), visitIdPda: idPdaNew });
      await db.activity
        .where({ visitId: Number(id), visitIdPda: idPda })
        .modify({ visitId: Number(idNew), visitIdPda: idPdaNew });
      await db.visitTicket
        .where({ visitId: Number(id), visitIdPda: idPda })
        .modify({ visitId: Number(idNew), visitIdPda: idPdaNew });
      await db.visit.update(
        { id: Number(idNew), idPda: idPdaNew },
        { statusPda: 'S' },
      );
    },
  );
};

/**
 * Fetch all visitTicket need to be synchronized with backend
 * @return {Promise<Array<TVisitTicket>>}
 */
const findAllVisitTicketForSync = async () => db.visitTicket
  .where('statusPda')
  .notEqual('S')
  .toArray();

/**
 * Mark given record as synchronized with backend
 * @param id {number}
 * @param idPda {string}
 * @param idNew {number}
 * @param idPdaNew {string}
 * @returns {Promise<number>}
 */
const setVisitTicketAsSynced = async (id, idPda, idNew, idPdaNew) => {
  await db.transaction(
    'rw',
    db.visitTicket,
    async () => {
      await db.visitTicket
        .where({ ticketId: Number(id), visitId: Number(idNew) })
        .modify({ statusPda: 'S' });
    },
  );
};

/**
 * Fetch all activity need to be synchronized with backend
 * @return {Promise<Array<TActivity>>}
 */
const findAllActivityForSync = async () => db.activity
  .where('statusPda')
  .notEqual('S')
  .toArray();

/**
 * Mark given record as synchronized with backend
 * @param id {number}
 * @param idPda {string}
 * @param idNew {number}
 * @param idPdaNew {string}
 * @returns {Promise<number>}
 */
const setActivityAsSynced = async (id, idPda, idNew, idPdaNew) => {
  await db.transaction(
    'rw',
    db.activity,
    db.activitySpare,
    async () => {
      await db.activity.update(
        { id: Number(id), idPda },
        { id: Number(idNew), idPda: idPdaNew },
      );
      await db.activitySpare
        .where({ activityId: Number(id), activityIdPda: idPda })
        .modify({ activityId: Number(idNew), activityIdPda: idPdaNew });
      await db.activity.update(
        { id: Number(idNew), idPda: idPdaNew },
        { statusPda: 'S' },
      );
    },
  );
};

/**
 * Fetch all activity need to be synchronized with backend
 * @return {Promise<Array<TActivitySpare>>}
 */
const findAllActivitySpareForSync = async () => db.activitySpare
  .where('statusPda')
  .notEqual('S')
  .toArray();

/**
 * Mark given record as synchronized with backend
 * @param id {number}
 * @param idPda {string}
 * @param idNew {number}
 * @param idPdaNew {string}
 * @returns {Promise<number>}
 */
const setActivitySpareAsSynced = async (id, idPda, idNew, idPdaNew) => {
  await db.transaction(
    'rw',
    db.activitySpare,
    db.spareShift,
    async () => {
      await db.activitySpare.update(
        { id: Number(id), idPda },
        { id: Number(idNew), idPda: idPdaNew },
      );
      await db.spareShift
        .where({ activitySpareId: Number(id), activitySpareIdPda: idPda })
        .modify({ activitySpareId: Number(idNew), activitySpareIdPda: idPdaNew });
      await db.activitySpare.update(
        { id: Number(idNew), idPda: idPdaNew },
        { statusPda: 'S' },
      );
    },
  );
};

/**
 * Fetch all ticketReport need to be synchronized with backend
 * @return {Promise<Array<TTicketReport>>}
 */
const findAllTicketReportForSync = async () => db.ticketReport
  .where('statusPda')
  .notEqual('S')
  .toArray();

/**
 * Mark given record as synchronized with backend
 * @param id {number}
 * @param idPda {string}
 * @param idNew {number}
 * @param idPdaNew {string}
 * @returns {Promise<number>}
 */
const setTicketReportAsSynced = async (id, idPda, idNew, idPdaNew) => {
  await db.transaction(
    'rw',
    db.ticketReport,
    async () => {
      await db.ticketReport
        .where({ ticketId: Number(id), ticketIdPda: idPda, fieldId: Number(idNew) })
        .modify({ statusPda: 'S' });
    },
  );
};

/**
 * Fetch all spareOrder need to be synchronized with backend
 * @return {Promise<Array<TSpareOrder>>}
 */
const findAllSpareOrderForSync = async () => db.spareOrder
  .where('statusPda')
  .notEqual('S')
  .toArray();

/**
 * Mark given record as synchronized with backend
 * @param id {number}
 * @param idPda {string}
 * @param idNew {number}
 * @param idPdaNew {string}
 * @returns {Promise<number>}
 */
const setSpareOrderAsSynced = async (id, idPda, idNew, idPdaNew) => {
  await db.transaction(
    'rw',
    db.spareOrder,
    db.spareShift,
    async () => {
      await db.spareOrder.update(
        { id: Number(id), idPda },
        { id: Number(idNew), idPda: idPdaNew },
      );
      await db.spareShift
        .where({ spareOrderId: Number(id), spareOrderIdPda: idPda })
        .modify({ spareOrderId: Number(idNew), spareOrderIdPda: idPdaNew });
      await db.spareOrder.update(
        { id: Number(idNew), idPda: idPdaNew },
        { statusPda: 'S' },
      );
    },
  );
};

/**
 * Fetch all spareShift need to be synchronized with backend
 * @return {Promise<Array<TSpareShift>>}
 */
const findAllSpareShiftForSync = async () => db.spareShift
  .where('statusPda')
  .notEqual('S')
  .toArray();

/**
 * Mark given record as synchronized with backend
 * @param id {number}
 * @param idPda {string}
 * @param idNew {number}
 * @param idPdaNew {string}
 * @returns {Promise<number>}
 */
const setSpareShiftAsSynced = async (id, idPda, idNew, idPdaNew) => {
  await db.transaction(
    'rw',
    db.spareShift,
    async () => {
      await db.spareShift.update(
        { id: Number(id), idPda },
        { id: Number(idNew), idPda: idPdaNew, statusPda: 'S' },
      );
    },
  );
};

/**
 * Fetch all PosValve need to be synchronized with backend
 * @return {Promise<Array<TPosValve>>}
 */
const findAllPosValveForSync = async () => db.posValve
  .where('statusPda')
  .notEqual('S')
  .toArray();

/**
 * Mark given record as synchronized with backend
 * @param id {number}
 * @param idPda {string}
 * @param idNew {number}
 * @param idPdaNew {string}
 * @returns {Promise<number>}
 */
const setPosValveAsSynced = async (id, idPda, idNew, idPdaNew) => {
  await db.transaction(
    'rw',
    db.posValve,
    async () => {
      await db.posValve.update(
        { id: Number(id), idPda },
        { id: Number(idNew), idPda: idPdaNew, statusPda: 'S' },
      );
    },
  );
};

export const PrivateSynchroService = {
  findAllVisitDayForSync,
  setVisitDayAsSynced,
  findAllTicketForSync,
  setTicketAsSynced,
  findAllTicketLogForSync,
  setTicketLogAsSynced,
  findAllVisitForSync,
  setVisitAsSynced,
  findAllVisitTicketForSync,
  setVisitTicketAsSynced,
  findAllActivityForSync,
  setActivityAsSynced,
  findAllActivitySpareForSync,
  setActivitySpareAsSynced,
  findAllTicketReportForSync,
  setTicketReportAsSynced,
  findAllSpareOrderForSync,
  setSpareOrderAsSynced,
  findAllSpareShiftForSync,
  setSpareShiftAsSynced,
  findAllPosValveForSync,
  setPosValveAsSynced,
};

export const synchroService = {
  init,
  synchroUpload,
  synchroDownload,
};
