import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { DS } from '../_services';
import { Activity } from '../_services/activity.data.service';
import QuestionForm from '../Common/QuestionForm';

function ResolveSurveyForm(props) {
  const { visitId, visitIdPda } = useParams();
  const {
    /** @type {TTicket} */ ticket,
    /** @type {TResolveTicket} */ resolveTicket,
    /** @type {function(propertyName: string, newValue: any)} */ onChange,
  } = props;

  const [report, setReport] = useState([]);

  /**
   * Translate type of field to its data field name ANS_STRING -> ansString
   * @param {string} fieldType
   * @return {string}
   */
  const paramName = (fieldType) => fieldType
    .toLowerCase()
    .replace(/[-_][a-z0-9]/g, (group) => group.slice(-1).toUpperCase());

  /**
   * Update given field in report recordset and return updated list
   * @param {TTicketReport[]} reportToUpdate
   * @param {number} fieldId
   * @param {Object} data
   * @return {TTicketReport[]}
   */
  const updateField = (reportToUpdate, fieldId, data) => reportToUpdate
    .map((question) => {
      if (question.fieldId === fieldId) {
        return { ...question, ...data };
      }
      return question;
    });

  /**
   * Calculate carbonation ratio
   * @return {null|number}
   */
  const calcCO2 = useCallback((reportData) => {
    const carbonation = [
      [
        2.49, 2.60, 2.71, 2.82, 2.93, 3.04, 3.15, 3.26, 3.37,
        3.48, 3.59, 3.69, 3.80, 3.91, 4.02, 4.13, 4.24, 4.34, 4.45,
      ], // 32
      [
        2.45, 2.56, 2.66, 2.77, 2.88, 2.99, 3.09, 3.20, 3.31,
        3.41, 3.52, 3.63, 3.73, 3.84, 3.95, 4.05, 4.16, 4.26, 4.37,
      ], // 33
      [
        2.40, 2.51, 2.62, 2.72, 2.83, 2.93, 3.04, 3.14, 3.25,
        3.35, 3.46, 3.56, 3.67, 3.77, 3.87, 3.98, 4.08, 4.19, 4.29,
      ], // 34
      [
        2.36, 2.46, 2.57, 2.67, 2.77, 2.88, 2.98, 3.08, 3.19,
        3.29, 3.39, 3.50, 3.60, 3.70, 3.80, 3.91, 4.01, 4.11, 4.21,
      ], // 35
      [
        2.32, 2.42, 2.52, 2.62, 2.73, 2.83, 2.93, 3.03, 3.12,
        3.23, 3.33, 3.43, 3.53, 3.64, 3.74, 3.84, 3.94, 4.04, 4.14,
      ], // 36
      [
        2.28, 2.38, 2.48, 2.58, 2.68, 2.78, 2.88, 2.98, 3.08,
        3.17, 3.27, 3.37, 3.47, 3.57, 3.67, 3.77, 3.89, 3.97, 4.07,
      ], // 37
      [
        2.24, 2.34, 2.43, 2.53, 2.63, 2.73, 2.83, 2.92, 3.02,
        3.12, 3.22, 3.31, 3.41, 3.51, 3.61, 3.70, 3.80, 3.90, 3.99,
      ], // 38
      [
        2.20, 2.29, 2.39, 2.49, 2.58, 2.68, 2.78, 2.87, 2.97,
        3.06, 3.16, 3.26, 3.35, 3.45, 3.54, 3.64, 3.73, 3.83, 3.93,
      ], // 39
      [
        2.16, 2.25, 2.35, 2.44, 2.54, 2.63, 2.73, 2.82, 2.92,
        3.01, 3.11, 3.20, 3.29, 3.39, 3.48, 3.58, 3.67, 3.76, 3.86,
      ], // 40
      [
        2.12, 2.22, 2.31, 2.40, 2.50, 2.59, 2.68, 2.77, 2.87,
        2.96, 3.05, 3.15, 3.24, 3.33, 3.42, 3.52, 3.61, 3.70, 3.79,
      ], // 41
      [
        2.09, 2.18, 2.27, 2.36, 2.45, 2.54, 2.64, 2.73, 2.82,
        2.91, 3.00, 3.09, 3.18, 3.27, 3.36, 3.46, 3.55, 3.64, 3.73,
      ], // 42
      [
        2.05, 2.14, 2.23, 2.32, 2.41, 2.50, 2.59, 2.68, 2.77,
        2.86, 2.95, 3.04, 3.13, 3.22, 3.31, 3.40, 3.49, 3.58, 3.67,
      ], // 43
    ];

    const field2 = reportData.filter((question) => question.fieldId === 2)[0];
    const field22 = reportData.filter((question) => question.fieldId === 22)[0];

    if (!field2 || isNaN(parseInt(field2.ansDec, 10)) || parseInt(field2.ansDec, 10) === 0) {
      return null;
    }
    if (!field22 || isNaN(parseInt(field22.ansDec, 10)) || parseInt(field22.ansDec, 10) === 0) {
      return null;
    }

    return (carbonation[parseInt(field22.ansDec, 10) - 32][parseInt(field2.ansDec, 10) - 8]).toString(10);
  }, []);

  /**
   * Fill report answer according data from session
   * @param {TTicketReport[]} updatedReport
   */
  const saveSessionReport = (updatedReport) => {
    const sessionReport = {
      id: `${ticket.id}^${ticket.idPda}^${visitId}^${visitIdPda}`,
    };
    updatedReport.map((question) => {
      sessionReport[`field_${question.fieldId}`] = question[paramName(question.fieldDefinition.fieldType)];
    });
    sessionStorage.setItem('surveyReport', JSON.stringify(sessionReport));
  };

  const processEventAfterChangeAnswer = useCallback((reportData, fieldId) => {
    let updatedReport = [...reportData];

    if ([2, 22].includes(fieldId)) {
      updatedReport = updateField(updatedReport, 4, { ansDec: calcCO2(updatedReport) });
    }

    if ([33, 35].includes(fieldId)) {
      const hasBeenChecked = updatedReport
        .filter((question) => [33, 35].includes(question.fieldId))
        .reduce((acc, question) => acc || question.ansBool, false);

      updatedReport = updateField(updatedReport, 34, { ansInt: 0, disabled: hasBeenChecked });
    }

    if (fieldId === 35) {
      const hasBeenChecked = updatedReport
        .filter((question) => question.fieldId === 35)
        .reduce((acc, question) => acc || question.ansBool, false);

      updatedReport = updateField(updatedReport, 33, { ansBool: false, disabled: hasBeenChecked });
    }

    return updatedReport;
  }, [calcCO2]);

  const makeReportRecords = useCallback(
    () => {
      let reportFields = [];
      const fetchData = async () => {
        const { hasElectricityExamActivity, hasCoolerLiquidReplace } = await Activity.checkActivityForSurvey(
          ticket.id,
          ticket.idPda,
          visitId,
          visitIdPda,
        );

        /**
         * @param {number} fieldId
         * @param {?string} fieldLabel
         * @return {Promise<TTicketReport>}
         */
        const addReportField = async (fieldId, fieldLabel = null) => {
          /**
           * @type {TTicketField}
           */
          let fieldDefinition = {
            id: 0,
            name: fieldLabel,
            fieldType: 'ANS_LABEL',
          };
          if (fieldId !== 0) {
            fieldDefinition = await DS.Ticket.findTicketField(fieldId);
          }

          return {
            ticketId: Number(ticket.id),
            ticketIdPda: ticket.idPda,
            visitId: Number(visitId),
            visitIdPda,
            fieldId,
            ansDec: null,
            ansBool: null,
            ansInt: null,
            ansString: null,
            fieldDefinition,
          };
        };

        if (ticket.pos.posTypeGroupId === 3 && [1, 4].includes(ticket.ticketTypeId)) {
          reportFields.push(await addReportField(0, 'OCENA JAKOŚCIOWA'));
          await Promise.all(
            [1, 2, 22, 4, 5, 6, 7, 9, 10].map(async (id) => reportFields.push(await addReportField(id))),
          );
        }

        if ([3, 4, 8].includes(ticket.pos.posTypeGroupId)) {
          reportFields.push(await addReportField(0, 'OCENA STANU URZĄDZENIA'));
          await Promise.all(
            [12, 13, 14, 15, 16, 17, 18, 19, 20, 21].map(async (id) => reportFields.push(await addReportField(id))),
          );
        }

        if ([1, 3, 4, 8].includes(ticket.pos.posTypeGroupId) === false) {
          reportFields.push(await addReportField(0, 'OCENA STANU URZĄDZENIA'));
          await Promise.all(
            [12, 13, 14, 15, 16].map(async (id) => reportFields.push(await addReportField(id))),
          );
        }

        if (hasElectricityExamActivity) {
          reportFields.push(await addReportField(0, 'POMIAR BEZPIECZEŃSTWA ELEKTRYCZNEGO'));
          await Promise.all(
            [23, 24, 25, 26, 27, 28, 29, 30, 35, 33, 34].map(async (id) => reportFields.push(await addReportField(id))),
          );
        }

        if (hasCoolerLiquidReplace) {
          reportFields.push(await addReportField(0, 'NABICIE CZYNNIKA'));
          await Promise.all(
            [31, 32].map(async (id) => reportFields.push(await addReportField(id))),
          );
        }

        const previousAnswer = JSON.parse(sessionStorage.getItem('surveyReport') || '{}');
        if (previousAnswer && previousAnswer.id === `${ticket.id}^${ticket.idPda}^${visitId}^${visitIdPda}`) {
          reportFields.map((question) => {
            if (previousAnswer[`field_${question.fieldId}`]) {
              reportFields = updateField(
                reportFields,
                question.fieldId,
                { [paramName(question.fieldDefinition.fieldType)]: previousAnswer[`field_${question.fieldId}`] },
              );
              reportFields = processEventAfterChangeAnswer(reportFields, question.fieldId);
            }
          });
        }

        return reportFields;
      };
      return fetchData();
    },
    [
      ticket.id,
      ticket.idPda,
      ticket.pos.posTypeGroupId,
      ticket.ticketTypeId,
      visitId,
      visitIdPda,
      processEventAfterChangeAnswer,
    ],
  );

  useEffect(() => {
    const fetchData = async () => {
      setReport(await makeReportRecords());
    };
    fetchData();
  }, [makeReportRecords]);

  /**
   * @param {TTicketReport} reportField
   * @param {*} value
   */
  const handleInputChange = (reportField, value) => {
    let updatedReport = [...report];

    updatedReport = updateField(
      updatedReport,
      reportField.fieldId,
      { [paramName(reportField.fieldDefinition.fieldType)]: value },
    );

    updatedReport = processEventAfterChangeAnswer(updatedReport, reportField.fieldId);

    saveSessionReport(updatedReport);
    setReport(updatedReport);
    onChange(updatedReport);
  };

  return (
    <form
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
      noValidate
      name="form"
    >
      {report.map((question) => (
        <QuestionForm key={question.fieldDefinition.name} reportField={question} onChange={handleInputChange} />
      ))}
    </form>
  );
}

ResolveSurveyForm.propTypes = {
  ticket: PropTypes.shape(Object).isRequired,
  resolveTicket: PropTypes.shape(Object).isRequired,
  onChange: PropTypes.func.isRequired,
};

ResolveSurveyForm.defaultProps = {};

export default ResolveSurveyForm;
