// eslint-disable-next-line no-unused-vars
import { Dexie, Table } from 'dexie';
/**
 * VisitDay table
 * @typedef {Object} TVisitDay
 * @property {number} carOdometerEnd
 * @property {number} carOdometerStart
 * @property {number} id PK
 * @property {string} idPda PK
 * @property {string} ?notice
 * @property {string} statusPda IDX
 * @property {string} ?visitDateEnd
 * @property {string} visitDateStart IDX
 */

/**
 * Ticket table
 * @typedef {Object} TTicket
 * @property {string} category
 * @property {number} clientId IDX
 * @property {?string} contactPerson
 * @property {?string} contactPhone
 * @property {?string} dateAssigned
 * @property {?string} dateInsp
 * @property {string} dateNew
 * @property {?string} datePriority
 * @property {?string} dateResolved
 * @property {?string} dateSanit
 * @property {number} doNotRoutePlan
 * @property {number} doNotTouchAssignment
 * @property {number} id PK
 * @property {string} idPda PK
 * @property {number} improperTicket
 * @property {?number} improperTicketReasonId
 * @property {number} incorrectUsePercent
 * @property {string} notice
 * @property {string} ownerName
 * @property {?number} parentTicketId IDX
 * @property {?string} parentTicketIdPda IDX
 * @property {number} posId IDX
 * @property {string} posLocation
 * @property {string} priorityLevel
 * @property {?string} routeDate
 * @property {?string} routeOrder
 * @property {string} status IDX
 * @property {string} statusPda IDX
 * @property {string} ticketNo IDX
 * @property {number} ticketResolveReasonId
 * @property {string} ?ticketResolveReasonName
 * @property {status} ticketResolveType
 * @property {number} ticketTypeId
 * @property {string} ticketTypeName
 * @property {number} vip
 * @property {number} warranty
 * @property {TClient} client
 * @property {TPos} pos
 * @property {TTicketLog[]} ticketLogs
 */

/**
 * TicketHistory Table
 * @typedef {Object} TTicketHistory
 * @property {string} category
 * @property {string} dateNew
 * @property {number} id PK
 * @property {string} lastChange
 * @property {string} notice
 * @property {number} posId IDX
 * @property {string} servicemanName
 * @property {string} status
 * @property {string} ticketNo IDX
 * @property {string} ticketResolveType
 * @property {number} ticketTypeId
 * @property {string} ticketTypeName
 */

/**
 * TicketType Table
 * @typedef TTicketType
 * @property {number} id PK
 * @property {string} name
 */

/**
 * TicketLog Table
 * @typedef TTicketLog
 * @property {string} description
 * @property {number} id PK
 * @property {string} idPda PK
 * @property {string} logDate IDX
 * @property {string} logType
 * @property {?string} notice
 * @property {string} ownerName
 * @property {number} ticketId IDX
 * @property {string} ticketIdPda IDX
 * @property {string} statusPda IDX
 */

/**
 * TicketField table
 * @typedef {Object} TTicketField
 * @property {number} id PK
 * @property {string} name IDX
 * @property {string} fieldType
 */

/**
 * TicketReport table
 * @typedef {Object} TTicketReport
 * @property {number} ticketId PK
 * @property {string} ticketIdPda PK
 * @property {number} visitId PK
 * @property {string} visitIdPda PK
 * @property {number} fieldId
 * @property {number} ansInt
 * @property {number} ansDec
 * @property {boolean} ansBool
 * @property {string} ansString
 * @property {TTicketField} fieldDefinition
 */

/**
 * TicketResolveReason table
 * @typedef {Object} TTicketResolveReason
 * @property {number} id PK
 * @property {string} name IDX
 */

/**
 * ImproperTicketReason table
 * @typedef {Object} TImproperTicketReason
 * @property {number} id PK
 * @property {string} name IDX
 * @property {number} deleted IDX
 */

/**
 * Client table
 * @typedef {Object} TClient
 * @property {string} city
 * @property {?string} contactPerson
 * @property {?string} contactPhone
 * @property {string} cr
 * @property {string} crPhone
 * @property {number} id
 * @property {string} name
 * @property {string} nip
 * @property {string} openingHours
 * @property {string} postalCode
 * @property {string} routeId
 * @property {string} rsm
 * @property {string} rsmEmail
 * @property {string} rsmPhone
 * @property {?string} sap4Id
 * @property {?string} sapId
 * @property {string} seasonEnd
 * @property {string} seasonStart
 * @property {string} street
 * @property {number} vip
 */

/**
 * Pos table
 * @typedef {Object} TPos
 * @property {string} barcode IDX
 * @property {number} clientId IDX
 * @property {number} id PK
 * @property {number} incorrectUsePercent
 * @property {?string} instDate
 * @property {?string} lastInspDate
 * @property {?string} lastInventoryDate
 * @property {?string} lastSanitDate
 * @property {?string} modyfiDate
 * @property {?string} nextInspDate
 * @property {?string} nextSanitDate
 * @property {string} posLocation
 * @property {TPosType} posType
 * @property {number} posTypeGroupId IDX
 * @property {number} posTypeId IDX
 * @property {string} posTypeName
 * @property {string} posTypeName
 * @property {?string} reinstDate
 * @property {?number} sap4Id IDX
 * @property {?number} sapId IDX
 * @property {string} serialNo IDX
 * @property {string} statusDate
 * @property {string} statusId
 * @property {string} tagId
 * @property {string} warrantyExpDate
 * @property {TClient} client
 * @property {TTicketHistory[]} posHistories
 */

/**
 * PosType table
 * @typedef {Object} TPosType
 * @property {number} id PK
 * @property {string} name
 * @property {string} posTypeGroupName
 * @property {string} sap4Id IDX
 */

/**
 * PosValve table
 * @typedef {Object} TPosValve
 * @property {number} id
 * @property {string} idPda
 * @property {number} spareTypeId
 * @property {string} serialNo
 * @property {number} posId
 * @property {number} lastModifyByUserId
 * @property {string} lastModifyDate
 * @property {number} active
 * @property {string} statusPda
 * @property {TSpareType} spareType
 */

/**
 * Visit table
 * @typedef {Object} TVisit
 * @property {number} carOdometer
 * @property {number} clientId
 * @property {number} id
 * @property {string} idPda
 * @property {string} statusPda
 * @property {number} trackCost
 * @property {number} trackDistance
 * @property {number} trackDuration
 * @property {string} visitDate
 * @property {TVisitTicket[]} visitTickets
 */

/**
 * VisitTicket table
 * @typedef {Object} TVisitTicket
 * @property {number} visitId PK
 * @property {string} visitIdPda PK
 * @property {number} ticketId PK
 * @property {string} ticketIdPda PK
 * @property {number} barcodeValidated
 * @property {string} statusPda IDX
 */

/**
 * Activity table
 * @typedef {Object} TActivity
 * @property {number} activityCategoryId
 * @property {number} activityTypeId
 * @property {?string} comment
 * @property {number} id PK
 * @property {string} idPda: PK
 * @property {number} laborCost
 * @property {number} ticketId IDX
 * @property {string} ticketIdPda IDX
 * @property {number} timeDuration
 * @property {string} timeStart
 * @property {number} visitId IDX
 * @property {string} visitIdPda IDX
 * @property {string} statusPda IDX
 * @property {TActivitySpare[]} activitySpares
 */

/**
 * ActivitySpare table
 * @typedef {Object} TActivitySpare
 * @property {number} id PK
 * @property {string} idPda PK
 * @property {number} activityId PK
 * @property {string} activityIdPda PK
 * @property {number} spareTypeId
 * @property {number} cost
 * @property {?string} description
 * @property {number} quantity
 * @property {string} statusPda IDX
 */

/**
 * ActivityCategory table
 * @typedef {Object} TActivityCategory
 * @property {number} deleted
 * @property {number} id PK
 * @property {string} name IDX
 * @property {number} ticketTypeId IDX
 */

/**
 * ActivityType table
 * @typedef {Object} TActivityType
 * @property {number} activityCategoryId IDX
 * @property {number} deleted IDX
 * @property {number} id PK
 * @property {string} name IDX
 */

/**
 * SpareType table
 * @typedef {Object} TSpareType
 * @property {number} active IDX
 * @property {number} defaultMinimalQuantity
 * @property {number} defaultNominalQuantity
 * @property {number} deleted IDX
 * @property {number} id PK
 * @property {string} name IDX
 * @property {number} packQuantity
 * @property {number} priceAverage
 * @property {string} priceDate
 * @property {string} sap4Id IDX
 * @property {string} sapId IDX
 * @property {string} spareGroup IDX
 * @property {string} spareStatus IDX
 * @property {string} spareUnit
 * @property {?number} stockQuantity for special fetching with stock
 * @property {?number} orderQuantity for special fetching with order
 */

/**
 * SpareCategory table
 * @typedef {Object} TSpareCategory
 * @property {number} id PK
 * @property {number} level
 * @property {number} name IDX
 * @property {?number} parentCategoryId
 */

/**
 * SpareCategoryType table
 * @typedef {Object} TSpareCategoryType
 * @property {number} categoryId PK
 * @property {number} mainCategoryId PK
 * @property {number} spareTypeId PK
 */

/**
 * SpareOrder table
 * @typedef {Object} TSpareOrder
 * @property {?string} dateCanceled
 * @property {?string} dateDelivered
 * @property {string} dateOrder
 * @property {?string} datePrepared
 * @property {?string} dateRejected
 * @property {?string} dateSend
 * @property {number} destinationWarehouseId
 * @property {string} destinationWarehouseName
 * @property {number} id
 * @property {string} idPda
 * @property {string} noticeCanceled
 * @property {string} noticeDelivered
 * @property {string} noticeOrder
 * @property {?string} noticePrepared
 * @property {?string} noticeRejected
 * @property {number} ownerId
 * @property {string} ownerName
 * @property {number} quantityDelivered
 * @property {number} quantityOrder
 * @property {number} quantitySend
 * @property {?number} senderId
 * @property {?string} senderName
 * @property {?number} sourceWarehouseId
 * @property {?string} sourceWarehouseName
 * @property {?number} spareShipmentId
 * @property {number} spareTypeId IDX
 * @property {string} status IDX
 * @property {string} statusPda IDX
 * @property {TSpareType} spareType
 * @property {?TSpareShipment} spareShipment
 */

/**
 * SpareShift table
 * @typedef {Object} TSpareShift
 * @property {?number} activitySpareId IDX
 * @property {?string} activitySpareIdPda IDX
 * @property {?number} clientId
 * @property {string} dateDelivered
 * @property {string} dateSend
 * @property {number} destinationWarehouseId
 * @property {string} destinationWarehouseName
 * @property {number} id PK
 * @property {string} idPda PK
 * @property {number} ownerId
 * @property {string} ownerName
 * @property {?number} posId
 * @property {number} price
 * @property {number} quantity
 * @property {number} recipientId
 * @property {string} recipientName
 * @property {string} shiftType
 * @property {number} sourceWarehouseId
 * @property {string} sourceWarehouseName
 * @property {?number} spareOrderId
 * @property {?string} spareOrderIdPda
 * @property {number} spareTypeId IDX
 * @property {string} status IDX
 * @property {string} statusPda IDX
 */

/**
 * SpareShipment table
 * @typedef {Object} TSpareShipment
 * @property {?string} dateCanceled
 * @property {string} dateCreate
 * @property {?string} dateDelivered: null
 * @property {?string} dateSend: null
 * @property {number} destinationWarehouseId
 * @property {string} destinationWarehouseName: "Michałowski Andrzej"
 * @property {string} externalShipmentId
 * @property {number} id PK
 * @property {string} notice
 * @property {number} ownerId
 * @property {string} ownerName
 * @property {number} sourceWarehouseId
 * @property {string} sourceWarehouseName
 * @property {string} status IDX
 */

/**
 * OtherUser table
 * @typedef {Object} TOtherUser
 * @property {number} id PK
 * @property {string} userEmail
 * @property {string} userName IDX
 * @property {string} userPhone
 * @property {number} warehouseId
 */

/**
 * @typedef {Dexie} DexieTable
 * @property {Table|TVisitDay} visitDay
 * @property {Table|TTicket} ticket
 * @property {Table|TTicketHistory} ticketHistory
 * @property {Table|TTicketLog} ticketLog
 * @property {Table|TTicketType} ticketType
 * @property {Table|TTicketField} ticketField
 * @property {Table|TTicketReport} ticketReport
 * @property {Table|TTicketResolveReason} ticketResolveReason
 * @property {Table|TImproperTicketReason} improperTicketReason
 * @property {Table|TClient} client
 * @property {Table|TPos} pos
 * @property {Table|TPosType} posType
 * @property {Table|TPosValve} posValve
 * @property {Table|TVisit} visit
 * @property {Table|TVisitTicket} visitTicket
 * @property {Table|TActivity} activity
 * @property {Table|TActivitySpare} activitySpare
 * @property {Table|TActivityCategory} activityCategory
 * @property {Table|TActivityType} activityType
 * @property {Table|TSpareType} spareType
 * @property {Table|TSpareCategory} spareCategory
 * @property {Table|TSpareCategoryType} spareCategoryType
 * @property {Table|TSpareOrder} spareOrder
 * @property {Table|TSpareShift} spareShift
 * @property {Table|TSpareShipment} spareShipment
 * @property {Table|TOtherUser} otherUser
 */

/**
 * @type {DexieTable}
 */
export const db = new Dexie('ipcos20');

db.version(1).stores({
  activity: '[id+idPda], [ticketId+ticketIdPda], [visitId+visitIdPda],'
    + ' [ticketId+ticketIdPda+visitId+visitIdPda], statusPda',
  activitySpare: '[id+idPda], [activityId+activityIdPda], statusPda',
  activityCategory: 'id, ticketTypeId, name, deleted',
  activityType: 'id, activityCategoryId, name, deleted',
  client: 'id, sapId, sap4Id',
  improperTicketReason: 'id, name, deleted',
  otherActivityType: 'id',
  otherUser: 'id, userName',
  pos: 'id, clientId, sapId, sap4Id, serialNo, barcode, posTypeId, posTypeGroupId',
  posInventory: '[id+idPda], clientId, [ticketId+ticketIdPda], statusPda',
  posInventoryDetail: '[id+idPda], posInventoryId, posId, statusPda',
  posType: 'id, sap4Id',
  posValve: '[id+idPda], posId, spareTypeId, statusPda',
  refurbishment: '[id+idPda], posId, ticketId, statusPda',
  refurbishmentLog: 'id, refurbishmentId',
  refurbishmentSpare: '[id+idPda], refurbishmentId, spareTypeId, statusPda',
  refurbishmentType: 'id',
  spareCategory: 'id, name',
  spareCategoryType: '[mainCategoryId+categoryId+spareTypeId], mainCategoryId',
  spareOrder: '[id+idPda], spareTypeId, status, statusPda',
  spareShift: '[id+idPda], [activitySpareId+activitySpareIdPda], [spareOrderId+spareOrderIdPda],'
    + ' spareTypeId, status, statusPda',
  spareShipment: 'id, status',
  spareType: 'id, name, spareGroup, spareStatus, sapId, sap4Id, active, deleted',
  ticket: '[id+idPda], [parentTicketId+parentTicketIdPda], ticketNo, posId, clientId, status, statusPda',
  ticketField: 'id',
  ticketHistory: 'id, posId, ticketNo',
  ticketLog: '[id+idPda], [ticketId+ticketIdPda], logDate, statusPda',
  ticketReport: '[ticketId+ticketIdPda+visitId+visitIdPda+fieldId], [ticketId+ticketIdPda+visitId+visitIdPda],'
    + ' [ticketId+ticketIdPda], [visitId+visitIdPda], [ticketId+ticketIdPda+fieldId], statusPda',
  ticketResolveReason: 'id, name',
  ticketType: 'id',
  userDay: 'id',
  visit: '[id+idPda], clientId, visitDate, statusPda',
  visitTicket: '[visitId+visitIdPda+ticketId+ticketIdPda], [visitId+visitIdPda], [ticketId+ticketIdPda],'
    + ' [ticketId+visitId], statusPda',
  visitDay: '[id+idPda], visitDateStart, statusPda',
});
