// eslint-disable-next-line no-unused-vars
import { TKey } from 'dexie';
import moment from 'moment';
import { db } from './db';
import { uuid } from '../_helpers/uuid';

/**
 * @returns {Promise<TVisitDay>}
 */
const findTodayVisit = async () => db.visitDay
  .where('visitDateStart')
  .startsWith(moment().format('YYYY-MM-DD'))
  .first();

/**
 * @param carOdometerStart
 * @returns {Promise<TKey>}
 */
const startVisitDay = async (carOdometerStart) => db.visitDay.add({
  carOdometerStart,
  id: 0,
  idPda: uuid(),
  visitDateStart: moment().format('YYYY-MM-DD HH:mm:ss'),
  statusPda: 'N',
});

/**
 * @param carOdometerEnd {number}
 * @param notice {string}
 * @returns {Promise<number>}
 */
const stopVisitDay = async (carOdometerEnd, notice) => db.visitDay
  .where('visitDateStart')
  .startsWith(moment().format('YYYY-MM-DD'))
  .modify({
    carOdometerEnd,
    visitDateEnd: moment().format('YYYY-MM-DD HH:mm:ss'),
    notice,
    statusPda: 'U',
  });

/**
 * @returns {Promise<number>}
 */
const restartVisitDay = async () => db.visitDay
  .where('visitDateStart')
  .startsWith(moment().format('YYYY-MM-DD'))
  .modify({
    carOdometerEnd: 0,
    visitDateEnd: undefined,
    statusPda: 'U',
  });

/**
 * @returns {Promise<{dayIsClose: boolean, dayIsOpen: boolean}>}
 */
const getVisitDayStatus = async () => {
  const actualDay = await findTodayVisit();

  return {
    dayIsOpen: !!actualDay,
    dayIsClose: !!actualDay && !!actualDay.visitDateEnd,
  };
};

export const VisitDay = {
  findTodayVisit,
  startVisitDay,
  stopVisitDay,
  restartVisitDay,
  getVisitDayStatus,
};
