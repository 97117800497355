import React, { useEffect, useState } from 'react';
import {
  Tabs,
} from '@mui/material';
import PropTypes from 'prop-types';
import { ContentDialog, TabsTab, TabPanel, LabelValueList } from '../Common';
import { DS } from '../_services';

function TicketDetailDialog(props) {
  const {
    open,
    onAccept,
    maxWidth,
    ticketId,
    ticketIdPda,
  } = props;

  const [state, setState] = useState({
    tabIndex: 0,
    dialogTitle: '',
  });

  const [detailTicket, setDetailTicket] = useState([]);
  const [detailClient, setDetailClient] = useState([]);
  const [detailPos, setDetailPos] = useState([]);

  useEffect(() => {
    const fetchTicket = async () => {
      const ticket = await DS.Ticket.findTicket(ticketId, ticketIdPda);
      setState((s) => ({ ...s, dialogTitle: `${ticket.client.name} - ${ticket.pos.posTypeName}` }));

      setDetailTicket([
        { label: 'Numer zgłoszenia', value: ticket.ticketNo },
        { label: 'Status', value: DS.Ticket.filterTicketStatus(ticket.status) },
        { label: 'Status rozwiązania', value: DS.Ticket.filterTicketResolveType(ticket.ticketResolveType) },
        { label: 'Przyczyna rozwiązania', value: ticket.ticketResolveReasonName },
        { label: 'Typ zgłoszenia', value: ticket.ticketTypeName },
        { label: 'Priorytet', value: DS.Ticket.filterTicketPriority(ticket.priorityLevel) },
        { label: 'Data priorytetu', value: ticket.datePriority },
        { label: 'Telefon do osoby zgłaszającej', value: (ticket.contactPhone !== null ? ticket.contactPhone : '') },
        { label: 'Osoba zgłaszająca', value: (ticket.contactPerson !== null ? ticket.contactPerson : '') },
        { label: 'Wprowadził', value: ticket.ownerName },
        { label: 'Przyjęcie zgłoszenia', value: ticket.dateNew },
        { label: 'Przypisanie zgłoszenia', value: ticket.dateAssigned },
        { label: 'Rozwiązanie zgłoszenia', value: ticket.dateResolved },
        { label: 'Kategoria zgłoszenia', value: ticket.category },
        { label: 'Opis', value: ticket.notice },
      ]);
      setDetailClient([
        { label: 'Klient', value: ticket.client.name },
        { label: 'Ulica', value: ticket.client.street },
        { label: 'Miasto', value: ticket.client.city },
        { label: 'Kod pocztowy', value: ticket.client.postalCode },
        { label: 'Vip', value: (ticket.vip === 1 ? 'Tak' : 'Nie') },
        { label: 'SAP ID', value: ticket.client.sapId },
        { label: 'Osoba kontaktowa', value: ticket.client.contactPerson },
        { label: 'Telefon do osoby', value: ticket.client.contactPhone },
        { label: 'Godziny otwarcia', value: ticket.client.openingHours },
        { label: 'Trasa', value: ticket.client.routeId },
        { label: 'RSM', value: `${ticket.client.rsm} ${ticket.client.rsmPhone}` },
        { label: 'CR', value: `${ticket.client.cr} ${ticket.client.crPhone}` },
      ]);
      setDetailPos([
        { label: 'POS', value: ticket.pos.posTypeName },
        { label: 'SAP4 ID', value: ticket.pos.sap4Id },
        { label: 'SAP ID', value: ticket.pos.sapId },
        { label: 'TAG ID', value: ticket.pos.tagId },
        { label: 'Barcode', value: ticket.pos.barcode },
        { label: 'Numer seryjny', value: ticket.pos.serialNo },
        { label: 'Gwarancja', value: ticket.pos.warrantyExpDate },
        { label: 'Status', value: ticket.pos.statusId },
        { label: 'Data ostatniej inwentaryzacji', value: ticket.pos.lastInventoryDate },
        { label: 'Data ostatniej sanitacji', value: ticket.pos.lastSanitDate },
        { label: 'Data następnej sanitacji', value: ticket.pos.nextSanitDate },
        { label: 'Data ostatniego przeglądu', value: ticket.pos.lastInspDate },
        { label: 'Data następnego przeglądu', value: ticket.pos.nextInspDate },
      ]);
    };

    fetchTicket();
  }, [ticketId, ticketIdPda]);

  const handleAccept = (...prop) => onAccept(...prop);

  const handleTabChange = (event, newValue) => {
    setState((s) => ({ ...s, tabIndex: newValue }));
  };

  return (
    <div>
      <ContentDialog
        open={open}
        onAccept={handleAccept}
        onDecline={handleAccept}
        dialogTitle={state.dialogTitle}
        maxWidth={maxWidth}
        acceptLabel="OK"
        declineLabel=""
      >
        <div>
          <Tabs value={state.tabIndex} onChange={handleTabChange} sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabsTab label="Zgłoszenie" index={0} idPrefix="ticket-detail" />
            <TabsTab label="Klient" index={1} idPrefix="ticket-detail" />
            <TabsTab label="Urządzenie" index={2} idPrefix="ticket-detail" />
          </Tabs>
          <TabPanel value={state.tabIndex} index={0} idPrefix="ticket-detail">
            <LabelValueList list={detailTicket} idPrefix="detail-ticket" />
          </TabPanel>
          <TabPanel value={state.tabIndex} index={1} idPrefix="ticket-detail">
            <LabelValueList list={detailClient} idPrefix="detail-client" />
          </TabPanel>
          <TabPanel value={state.tabIndex} index={2} idPrefix="ticket-detail">
            <LabelValueList list={detailPos} idPrefix="datail-pos" />
          </TabPanel>
        </div>
      </ContentDialog>
    </div>
  );
}

TicketDetailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  ticketId: PropTypes.number.isRequired,
  ticketIdPda: PropTypes.string.isRequired,
};

TicketDetailDialog.defaultProps = {
  maxWidth: 'md',
};

export default TicketDetailDialog;
