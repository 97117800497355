import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  InputAdornment,
  TextField,
} from '@mui/material';

function QuestionDecForm(props) {
  const {
    /** @type {number} */ fieldId,
    /** @type {string} */ label,
    /** @type {string} */ value,
    /** @type {boolean} */ disabled,
    /** @type {function} */ onChange,
  } = props;

  const [state, setState] = useState({});

  useEffect(() => {
    const ip = {
      inputProps: {
        inputMode: 'numeric',
        pattern: '[0-9,.]*',
      },
      minValue: 0,
      maxValue: 10000,
      disabled,
    };

    switch (fieldId) {
      case 1:
      case 3:
        ip.inputProps.endAdornment = <InputAdornment position="end">°C</InputAdornment>;
        break;
      case 2:
        ip.inputProps.endAdornment = <InputAdornment position="end">PSI</InputAdornment>;
        ip.minValue = 8;
        ip.maxValue = 26;
        break;
      case 4:
        ip.disabled = true;
        break;
      case 22:
        ip.inputProps.endAdornment = <InputAdornment position="end">°F</InputAdornment>;
        ip.minValue = 32;
        ip.maxValue = 43;
        break;
      case 25:
        ip.inputProps.endAdornment = <InputAdornment position="end">MOm</InputAdornment>;
        break;
      case 27:
        ip.inputProps.endAdornment = <InputAdornment position="end">Om</InputAdornment>;
        break;
    }

    setState(ip);
  }, [fieldId, disabled]);

  const inputIntegerChangeHandler = (event) => {
    let { value: val } = event.target;

    if (Number(val) < state.minValue) {
      val = state.minValue;
    }
    if (Number(val) > state.maxValue) {
      val = state.maxValue;
    }

    if (onChange && typeof onChange === 'function') {
      onChange(val);
    }
  };

  return (
    <FormControl sx={{ my: 1 }} fullWidth>
      <TextField
        type="number"
        value={value}
        name={`field_${fieldId}`}
        label={label}
        variant="outlined"
        size="small"
        fullWidth
        InputProps={state.inputProps}
        onChange={inputIntegerChangeHandler}
        disabled={state.disabled}
      />
    </FormControl>
  );
}

QuestionDecForm.propTypes = {
  fieldId: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

QuestionDecForm.defaultProps = {
  disabled: false,
};

export default QuestionDecForm;
