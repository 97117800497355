import React from 'react';
import {
  Assignment as IconAssignment,
  CheckCircleOutlineOutlined as IconFinish,
  Downloading as IconDownload, Inventory as IconInventory,
  List as IconList,
  Start as IconStart, Sync as IconSync,
  Warehouse as IconWarehouse,
  Send as IconSend,
  MoveToInbox as IconMoveToInbox,
  AddShoppingCart as IconAddShoppingCart,
} from '@mui/icons-material';

export const menuTechnics = [
  {
    key: 'ticketList',
    href: '/ticketList',
    text: 'Lista zgłoszeń',
    icon: <IconList />,
    isTechnic: true,
  },
  {
    key: 'activityInProgress',
    href: '/activityInProgress',
    text: 'Czynność w trakcie wykonywania',
    icon: <IconDownload />,
    isTechnic: true,
    hasInProgressOtherActivity: true,
  },
  {
    key: 'dayOpen',
    href: '/dayOpen',
    text: 'Rozpocznij dzień',
    icon: <IconStart />,
    isTechnic: true,
    dayIsOpen: false,
    dayIsClose: false,
  },
  {
    key: 'dayClose',
    href: '/dayClose',
    text: 'Zakończ dzień',
    icon: <IconFinish />,
    isTechnic: true,
    dayIsOpen: true,
    dayIsClose: false,
  },
  {
    key: 'synchronize',
    href: '/synchronize',
    text: 'Synchronizacja',
    icon: <IconSync />,
  },
  {
    key: 'warehouseStock',
    href: '/warehouse/stock',
    text: 'Stan magazynu',
    icon: <IconWarehouse />,
    isTechnic: true,
  },
  {
    key: 'warehouseOrder',
    href: '/warehouse/orders',
    text: 'Części zamówione',
    icon: <IconAddShoppingCart />,
    isTechnic: true,
  },
  {
    key: 'warehouseShippedToMe',
    href: '/warehouse/shippedToMe',
    text: 'Części wysłane do mnie',
    icon: <IconMoveToInbox />,
    isTechnic: true,
  },
  {
    key: 'warehouseShippedByMe',
    href: '/warehouse/shippedByMe',
    text: 'Części wysłane przeze mnie',
    icon: <IconSend />,
    isTechnic: true,
  },
  {
    key: 'assignTicket',
    href: '/assignTicket',
    text: 'Zlecenia nieprzypisane',
    icon: <IconAssignment />,
    isTechnic: true,
  },
  {
    key: 'inventory',
    href: '/inventory',
    text: 'Inwentaryzacja części',
    icon: <IconInventory />,
    isTechnic: true,
  },
];
