import { db } from './db';

/**
 * @returns {Promise<TActivity[]>}
 */
const findAllActivity = async () => {
  const activities = await db.activity.toArray();

  await Promise.all(activities.map(async (activity) => {
    [activity.activityType, activity.activityCategory] = await Promise.all([
      db.activityType.get(activity.activityTypeId),
      db.activityCategory.get(activity.activityCategoryId),
    ]);
  }));

  return activities;
};

/**
 * Get all activities for given ticket and visit
 * @param {number} ticketId
 * @param {string} ticketIdPda
 * @param {number} visitId
 * @param {string} visitIdPda
 * @returns {Promise<TActivity[]>}
 */
const findAllActivityForVisitTicket = async (ticketId, ticketIdPda, visitId, visitIdPda) => {
  const activities = await db.activity
    .where({ ticketId: Number(ticketId), ticketIdPda, visitId: Number(visitId), visitIdPda })
    .toArray();

  await Promise.all(activities.map(async (activity) => {
    [activity.activityType, activity.activityCategory] = await Promise.all([
      db.activityType.get(activity.activityTypeId),
      db.activityCategory.get(activity.activityCategoryId),
    ]);
  }));

  return activities;
};

/**
 * Get all activities for given ticket
 * @param {number} ticketId
 * @param {string} ticketIdPda
 * @returns {Promise<TActivity[]>}
 */
const findAllActivityForTicket = async (ticketId, ticketIdPda) => {
  const activities = await db.activity
    .where({ ticketId: Number(ticketId), ticketIdPda })
    .toArray();

  await Promise.all(activities.map(async (activity) => {
    [activity.activityType, activity.activityCategory] = await Promise.all([
      db.activityType.get(activity.activityTypeId),
      db.activityCategory.get(activity.activityCategoryId),
    ]);
  }));

  return activities;
};

/**
 * @param {number} activityId
 * @param {string} activityIdPda
 * @returns {Promise<TActivity>}
 */
const findActivity = async (activityId, activityIdPda) => {
  const activity = await db.activity.get({ id: Number(activityId), idPda: activityIdPda });

  if (!activity) {
    return undefined;
  }

  [activity.activityType, activity.activityCategory] = await Promise.all([
    db.activityType.get(activity.activityTypeId),
    db.activityCategory.get(activity.activityCategoryId),
  ]);

  return activity;
};

/**
 * @returns {Promise<TActivityCategory[]>}
 */
const findAllActivityCategories = async () => db.activityCategory
  .where({ deleted: 0 })
  .toArray();

/**
 * @returns {Promise<TActivityCategory[]>}
 */
const findAllActivityCategoriesForTicketType = async (ticketTypeId) => db.activityCategory
  .where({ ticketTypeId: Number(ticketTypeId) })
  .filter((c) => c.deleted === 0)
  .sortBy('name');

/**
 * @returns {Promise<TActivityType[]>}
 */
const findAllActivityTypes = async () => db.activityType
  .where({ deleted: 0 })
  .toArray();

/**
 * @param {number} activityCategoryId
 * @returns {Promise<TActivityType[]>}
 */
const findAllActivityTypesForCategory = async (activityCategoryId) => db.activityType
  .where({ activityCategoryId: Number(activityCategoryId) })
  .filter((c) => c.deleted === 0)
  .sortBy('name');

/**
 * Create new activity and return fresh created object
 * @param {TActivity} newActivity
 * @returns {Promise<TActivity>}
 */
const createActivity = async (newActivity) => {
  const newId = await db.activity.add(newActivity);

  return findActivity(newId[0], newId[1]);
};

/**
 * find all activity spare for given activity
 * @param {number} activityId
 * @param {string} activityIdPda
 * @return {Promise<Array<TActivitySpare>>}
 */
const findAllActivitySpareForActivity = async (activityId, activityIdPda) => db.activitySpare
  .where({ activityId: Number(activityId), activityIdPda })
  .toArray();

/**
 * Add spare spending to given activity
 * @param {TActivitySpare} activitySpare
 * @return {Promise<TKey>}
 */
const addSpareToActivity = async (activitySpare) => db.activitySpare.add(activitySpare);

/**
 * Delete activity spare entry
 * @param {number} activitySpareId
 * @param {string} activitySpareIdPda
 * @return {Promise<boolean>}
 */
const deleteSpareFromActivity = async (activitySpareId, activitySpareIdPda) => db.activitySpare
  .delete([Number(activitySpareId), activitySpareIdPda]);

/**
 * Delete activity
 * @param {number} activityId
 * @param {string} activityIdPda
 * @return {Promise<void>}
 */
const deleteActivity = async (activityId, activityIdPda) => db.activity
  .delete([Number(activityId), activityIdPda]);

/**
 * Check activities for given ticket and visit regards needed survey
 * @param {number} ticketId
 * @param {string} ticketIdPda
 * @param {number} visitId
 * @param {string} visitIdPda
 * @return {Promise<{hasElectricityExamActivity: boolean, hasCoolerLiquidReplace: boolean}>}
 */
const checkActivityForSurvey = async (ticketId, ticketIdPda, visitId, visitIdPda) => {
  const activities = await findAllActivityForVisitTicket(ticketId, ticketIdPda, visitId, visitIdPda);

  return activities.reduce(
    (acc, activity) => {
      acc.hasElectricityExamActivity = acc.hasElectricityExamActivity
        || [15, 16, 17, 18, 19, 20].includes(Number(activity.activityTypeId));
      acc.hasCoolerLiquidReplace = acc.hasCoolerLiquidReplace
        || [11].includes(Number(activity.activityTypeId));

      return acc;
    },
    { hasElectricityExamActivity: false, hasCoolerLiquidReplace: false }
  );
};

export const Activity = {
  findAllActivity,
  findAllActivityForVisitTicket,
  findAllActivityForTicket,
  findActivity,
  findAllActivityCategories,
  findAllActivityTypes,
  findAllActivityCategoriesForTicketType,
  findAllActivityTypesForCategory,
  createActivity,
  findAllActivitySpareForActivity,
  addSpareToActivity,
  deleteSpareFromActivity,
  deleteActivity,
  checkActivityForSurvey,
};
