import { history } from './history';

export function authHeader(secured = false) {
  const token = sessionStorage.getItem('token') || '';

  if (secured && !token) {
    history.navigate('/login');
    return {};
  }

  if (secured && token) {
    return { Authorization: `Bearer ${token}` };
  }
  return {};
}
