import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@mui/material';
import { GridFooter } from '@mui/x-data-grid';

function CustomFooter(props) {
  const { onClick, buttonLabel } = props;
  return (
    <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} alignItems="baseline">
      <Grid item xs={3} sx={{ m: 0, p: 0 }}>
        <Button
          color="primary"
          type="submit"
          variant="contained"
          sx={{ minWidth: '12em', mx: 1 }}
          onClick={onClick}
        >
          {buttonLabel}
        </Button>
      </Grid>
      <Grid item sx={{ flex: 'fit-content' }}>
        <GridFooter />
      </Grid>
    </Grid>
  );
}

CustomFooter.propTypes = {
  onClick: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string.isRequired,
};

export default CustomFooter;
