import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  AppBar,
  Box, DialogContent,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
} from '@mui/material';
import {
  Check as IconCheck,
  HourglassTop as IconHourglass,
} from '@mui/icons-material';
import { synchroService } from '../_services';
import { syncType } from '../_constants';
import { history } from '../_helpers';
import { ContentDialog } from '../Common';

function Synchro(props) {
  const { synchroType } = props;

  const [synchroStatus, setSynchroStatus] = useState({ uploadComplete: false, downloadComplete: false, error: false });
  const [uploadStep, setUploadStep] = useState([]);
  const [downloadStep, setDownloadStep] = useState([]);

  synchroService.init(setUploadStep, setDownloadStep);

  useEffect(() => {
    const processSynchronize = async () => {
      try {
        if (await synchroService.synchroUpload()) {
          setSynchroStatus((s) => ({ ...s, uploadComplete: true }));
          await synchroService.synchroDownload();
          // setSynchroStatus((s) => ({ ...s, downloadComplete: true }));
          history.push('/');
        }
      } catch (e) {
        setSynchroStatus((s) => ({ ...s, error: true, errorMessage: e.message }));
      }
    };
    processSynchronize();
  }, []);

  const makeListItem = (item, index) => (
    <ListItem key={item.key}>
      {item.icon && (
        <ListItemIcon sx={{ minWidth: '30px' }}>{item.icon}</ListItemIcon>
      )}
      <ListItemText primary={item.text} secondary={item.description} />
    </ListItem>
  );

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-around"
      alignItems="flex-start"
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      rowSpacing={{ xs: 1 }}
    >
      <Grid item xs={12} sm={6} sx={{ textAlign: 'left' }}>
        <Paper sx={{ p: 0 }}>
          <AppBar sx={{ p: 1, flexDirection: 'row' }} position="static" elevation={4}>
            {synchroStatus.uploadComplete
              ? <IconCheck sx={{ mr: 1 }} />
              : <IconHourglass sx={{ mr: 1 }} />}
            Wysyłanie danych:
          </AppBar>
          <Box sx={{ p: 1 }}>
            <List component="div" dense>
              {uploadStep.map(makeListItem)}
            </List>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6} sx={{ textAlign: 'left' }}>
        <Paper sx={{ p: 0 }}>
          <AppBar sx={{ p: 1, flexDirection: 'row' }} position="static" elevation={4}>
            {synchroStatus.downloadComplete
              ? <IconCheck sx={{ mr: 1 }} />
              : <IconHourglass sx={{ mr: 1 }} />}
            Pobieranie danych:
          </AppBar>
          <Box sx={{ p: 1 }}>
            <List component="div" dense>
              {downloadStep.map(makeListItem)}
            </List>
          </Box>
        </Paper>
      </Grid>
      {synchroStatus.error && (
        <ContentDialog
          onAccept={() => setSynchroStatus((s) => ({ ...s, error: false, errorMessage: '' }))}
          open={synchroStatus.error}
          onDecline={() => true}
          dialogTitle="Błąd synchronizacji"
          acceptLabel="OK"
          declineLabel=""
          dialogType="error"
        >
          <Box sx={{ p: 1 }}>{synchroStatus.errorMessage}</Box>
        </ContentDialog>
      )}
    </Grid>
  );
}

Synchro.propTypes = {
  synchroType: PropTypes.oneOf([
    syncType.ST_NORMAL,
    syncType.ST_FORCE_READ,
    syncType.ST_FORCE_WRITE,
    syncType.ST_ONLY_WAREHOUSE,
  ]),
};

Synchro.defaultProps = {
  synchroType: syncType.ST_NORMAL,
};

export default Synchro;
