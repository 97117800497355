import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLiveQuery } from 'dexie-react-hooks';

import {
  Box, IconButton,
} from '@mui/material';
import { Delete as IconDelete } from '@mui/icons-material';
import { DataGrid, plPL, GridColDef } from '@mui/x-data-grid';

import { DS } from '../_services';
import { ConfirmDialog, CustomFooter } from '../Common';
import CreateActivityDialog from './CreateActivityDialog';
import CreateActivitySpareDialog from './CreateActivitySpareDialog';

/**
 * @param {Object} props
 * @param {TClient} props.client
 * @param {{
 * visitInProgress: boolean,
 * visitClientId: ?number,
 * visitCarOdometer: number,
 * visitStartDate: ?number
 * }} props.visitSession
 * @param {?Function} props.specAction
 * @returns {JSX.Element}
 * @constructor
 */
function ActivityTab(props) {
  const { visit, ticket, onSpareChange } = props;
  const [state, setState] = useState({
    selectedActivityId: 0,
    selectedActivityIdPda: '',
    selectedActivitySpareId: 0,
    selectedActivitySpareIdPda: '',
    openSpareList: false,
    openDeleteActivity: false,
    deleteActivityName: '',
    openDeleteSpare: false,
    deleteSpareName: '',
  });
  const [selectionModel, setSelectionModel] = useState([]);
  const [addActivity, setAddActivity] = useState({ open: false });
  const [addSpare, setAddSpare] = useState({ open: false });

  /**
   * @type {GridColDef[]}
   */
  const columnsActivity = [
    {
      field: 'id',
      headerName: '',
      sortable: false,
      filterable: false,
      minWidth: 50,
      flex: 0.2,
      renderCell: (params) => (
        <IconButton
          size="small"
          color="info"
          onClick={(event) => deleteActivityHandler(params.row.id, params.row.idPda, params.row.activityType.name)}
        >
          <IconDelete />
        </IconButton>
      ),
    },
    {
      field: 'activityCategory.name',
      headerName: 'Kategoria',
      minWidth: 200,
      flex: 2,
      valueGetter: (param) => param.row.activityCategory.name,
    },
    {
      field: 'activityType.name',
      headerName: 'Czynność',
      minWidth: 200,
      flex: 2,
      valueGetter: (param) => param.row.activityType.name,
    },
  ];

  /**
   * @type {GridColDef[]}
   */
  const columnsSpare = [
    {
      field: 'id',
      headerName: '',
      sortable: false,
      filterable: false,
      minWidth: 50,
      flex: 0.2,
      renderCell: (params) => (
        <IconButton
          size="small"
          color="info"
          onClick={(event) => deleteSpareHandler(params.row.id, params.row.idPda, params.row.spareType.name)}
        >
          <IconDelete />
        </IconButton>
      ),
    },
    {
      field: 'spareType.name',
      headerName: 'Część',
      minWidth: 200,
      flex: 2,
      valueGetter: (param) => param.row.spareType.name,
    },
    {
      field: 'spareType.sap4Id',
      headerName: 'ID',
      minWidth: 50,
      flex: 0.5,
      valueGetter: (param) => param.row.spareType.sap4Id || param.row.spareType.sapId || '',
    },
    {
      field: 'quantity',
      headerName: 'Ilość',
      minWidth: 50,
      flex: 0.5,
      valueGetter: (param) => param.row.quantity,
    },
  ];

  /**
   * @type {TActivity[]}
   */
  const rowsActivity = useLiveQuery(async () => DS.Activity.findAllActivityForVisitTicket(
    ticket.id || 0,
    ticket.idPda || '',
    visit.id || 0,
    visit.idPda || '',
  ), [ticket, visit]);

  /**
   * @type {TActivitySpare[]}
   */
  const rowsSpare = useLiveQuery(async () => {
    if (!state) {
      return [];
    }
    return DS.Warehouse.findActivitySpares(state.selectedActivityId, state.selectedActivityIdPda);
  }, [state.selectedActivityId, state.selectedActivityIdPda]);

  /**
   * @param {[]} newSelectionModel
   * @param details
   */
  const selectActivityHandler = (newSelectionModel, details) => {
    let activityId = 0;
    let activityIdPda = '';

    if (newSelectionModel.length > 0) {
      [activityId, activityIdPda] = newSelectionModel[0].split('^');
    }

    setState((s) => ({
      ...s,
      openSpareList: newSelectionModel.length > 0,
      selectedActivityId: Number(activityId),
      selectedActivityIdPda: activityIdPda,
    }));
    setSelectionModel(newSelectionModel);
  };

  /**
   * @param {number} activityId
   * @param {string} activityIdPda
   * @param {string} activityName
   */
  const deleteActivityHandler = (activityId, activityIdPda, activityName) => {
    setState((s) => ({ ...s, openDeleteActivity: true, deleteActivityName: activityName }));
  };

  const deleteActivityCloseHandler = async (isConfirmed) => {
    if (isConfirmed) {
      await DS.Warehouse.deleteActivity(
        state.selectedActivityId,
        state.selectedActivityIdPda,
      );
    }
    setState((s) => ({ ...s, openDeleteActivity: false }));
  };

  /**
   * @param {number} activitySpareId
   * @param {string} activitySpareIdPda
   * @param {string} spareName
   */
  const deleteSpareHandler = (activitySpareId, activitySpareIdPda, spareName) => {
    setState((s) => ({
      ...s,
      openDeleteSpare: true,
      deleteSpareName: spareName,
      selectedActivitySpareId: Number(activitySpareId),
      selectedActivitySpareIdPda: activitySpareIdPda,
    }));
  };

  const deleteSpareCloseHandler = async (isConfirmed) => {
    if (isConfirmed) {
      await DS.Warehouse.deleteActivitySpare(
        state.selectedActivitySpareId,
        state.selectedActivitySpareIdPda,
      );

      if (typeof onSpareChange === 'function') {
        onSpareChange();
      }
    }
    setState((s) => ({ ...s, openDeleteSpare: false }));
  };

  const addActivityHandler = () => {
    setAddActivity((s) => ({ ...s, open: true }));
  };

  const addActivityCloseHandler = (newActivity) => {
    setSelectionModel([`${newActivity.id}^${newActivity.idPda}`]);
    selectActivityHandler([`${newActivity.id}^${newActivity.idPda}`]);
    dialogActivityCloseHandler();
  };

  const dialogActivityCloseHandler = () => {
    setAddActivity((s) => ({ ...s, open: false }));
  };

  const addSpareHandler = () => {
    setAddSpare((s) => ({ ...s, open: true }));
  };

  const addSpareCloseHandler = () => {
    if (typeof onSpareChange === 'function') {
      onSpareChange();
    }
    dialogSpareCloseHandler();
  };

  const dialogSpareCloseHandler = () => {
    setAddSpare((s) => ({ ...s, open: false }));
  };

  return (
    <Box component="div" sx={{}}>
      <Box component="div" sx={{ display: 'flex', height: 'calc((100vh - 160px) / 2)' }}>
        <Box component="div" sx={{ my: 1, flexGrow: 1 }}>
          <DataGrid
            rows={rowsActivity || []}
            columns={columnsActivity}
            getRowId={(row) => `${row.id}^${row.idPda}`}
            sx={{ mt: 1 }}
            autoPageSize
            pageSize={25}
            rowsPerPageOptions={[5, 10, 25]}
            density="compact"
            localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
            selectionModel={selectionModel}
            onSelectionModelChange={selectActivityHandler}
            hideFooterSelectedRowCount
            components={{
              Footer: CustomFooter,
            }}
            componentsProps={{
              footer: {
                buttonLabel: 'Dodaj czynność',
                onClick: addActivityHandler,
              },
            }}
          />
        </Box>
      </Box>
      {state.openSpareList && (
        <Box component="div" sx={{ my: 1, display: 'flex', height: 'calc((100vh - 160px) / 2)' }}>
          <Box component="div" sx={{ flexGrow: 1 }}>
            <DataGrid
              rows={rowsSpare || []}
              columns={columnsSpare}
              getRowId={(row) => `${row.id}^${row.idPda}`}
              sx={{ mt: 1 }}
              autoPageSize
              pageSize={25}
              rowsPerPageOptions={[5, 10, 25]}
              density="compact"
              localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
              hideFooterSelectedRowCount
              hideFooterRowCount
              components={{
                Footer: CustomFooter,
              }}
              componentsProps={{
                footer: {
                  buttonLabel: 'Dodaj część',
                  onClick: addSpareHandler,
                },
              }}
            />
          </Box>
        </Box>
      )}
      {addActivity.open && (
        <CreateActivityDialog
          open={addActivity.open}
          onAccept={addActivityCloseHandler}
          onDecline={dialogActivityCloseHandler}
          maxWidth="sm"
          ticketTypeId={ticket.ticketTypeId || 0}
        />
      )}
      {addSpare.open && (
        <CreateActivitySpareDialog
          open={addSpare.open}
          onAccept={addSpareCloseHandler}
          onDecline={dialogSpareCloseHandler}
          maxWidth="md"
          activityId={state.selectedActivityId || 0}
          activityIdPda={state.selectedActivityIdPda || ''}
        />
      )}
      {state.openDeleteActivity && (
        <ConfirmDialog
          open={state.openDeleteActivity}
          onClose={deleteActivityCloseHandler}
          dialogTitle="Usunięcie czynności"
          maxWidth="sm"
          dialogType="alert"
        >
          {`Czy na pewno chcesz usunąć czynność wraz z wydanymi częściami: ${state.deleteActivityName}`}
        </ConfirmDialog>
      )}
      {state.openDeleteSpare && (
        <ConfirmDialog
          open={state.openDeleteSpare}
          onClose={deleteSpareCloseHandler}
          dialogTitle="Usunięcie części"
          maxWidth="sm"
          dialogType="alert"
        >
          {`Czy na pewno chcesz usunąć część: ${state.deleteSpareName}`}
        </ConfirmDialog>
      )}
    </Box>
  );
}

ActivityTab.propTypes = {
  visit: PropTypes.shape(Object).isRequired,
  ticket: PropTypes.shape(Object).isRequired,
  onSpareChange: PropTypes.func.isRequired,
};

ActivityTab.defaultProps = {};

export default ActivityTab;
