import React from 'react';
import PropTypes from 'prop-types';
import QuestionBoolForm from './QuestionBoolForm';
import QuestionLabelForm from './QuestionLabelForm';
import QuestionIntForm from './QuestionIntForm';
import QuestionDecForm from './QuestionDecForm';
import QuestionStringForm from './QuestionStringForm';

function QuestionForm(props) {
  const {
    /** @type {TTicketReport} */ reportField,
    /** @type {function} */ onChange,
  } = props;

  /**
   * @param {*} value
   */
  const changeQuestionHandler = (value) => {
    if (onChange && typeof onChange === 'function') {
      onChange(reportField, value);
    }
  };

  switch (reportField.fieldDefinition.fieldType) {
    case 'ANS_LABEL':
      return (
        <QuestionLabelForm label={reportField.fieldDefinition.name} />
      );
    case 'ANS_INT':
      return (
        <QuestionIntForm
          fieldId={reportField.fieldId}
          label={reportField.fieldDefinition.name}
          value={reportField.ansInt || ''}
          disabled={reportField.disabled || reportField.readOnly || false}
          onChange={changeQuestionHandler}
        />
      );
    case 'ANS_BOOL':
      return (
        <QuestionBoolForm
          fieldId={reportField.fieldId}
          label={reportField.fieldDefinition.name}
          value={reportField.ansBool || false}
          disabled={reportField.disabled || reportField.readOnly || false}
          onChange={changeQuestionHandler}
        />
      );
    case 'ANS_DEC':
      return (
        <QuestionDecForm
          fieldId={reportField.fieldId}
          label={reportField.fieldDefinition.name}
          value={reportField.ansDec || ''}
          disabled={reportField.disabled || reportField.readOnly || false}
          onChange={changeQuestionHandler}
        />
      );
    case 'ANS_STRING':
      return (
        <QuestionStringForm
          fieldId={reportField.fieldId}
          label={reportField.fieldDefinition.name}
          value={reportField.ansString || reportField.readOnly || ''}
          disabled={reportField.disabled || false}
          onChange={changeQuestionHandler}
        />
      );

    default:
      return null;
  }
}

QuestionForm.propTypes = {
  reportField: PropTypes.shape(Object).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default QuestionForm;
