import moment from 'moment';
import { db } from './db';
import {
  posLocation,
  ticketPriority,
  ticketResolveType,
  ticketResolveTypeName,
  ticketStatus,
  ticketStatusName,
  SPARE_VALVE_ID,
} from '../_constants';
import { uuid } from '../_helpers/uuid';
import { Activity } from './activity.data.service';

/**
 * @returns {Promise<TTicket[]>}
 */
const findAllTicket = async () => {
  const tickets = await db.ticket.toArray();

  await Promise.all(tickets.map(async (ticket) => {
    [ticket.client, ticket.pos, ticket.ticketLogs] = await Promise.all([
      db.client.get(ticket.clientId),
      db.pos.get(ticket.posId),
      db.ticketLog
        .where({ ticketId: ticket.id, ticketIdPda: ticket.idPda })
        .sortBy('logDate'),
    ]);
  }));

  return tickets;
};

/**
 * Get ticket list for given ids
 * @param {[]} ids
 * @returns {Promise<Array<TTicket>>}
 */
const findAllTicketByIds = async (ids) => {
  const tickets = await db.ticket
    .where(['id', 'idPda'])
    .anyOf(ids)
    .toArray();

  await Promise.all(tickets.map(async (ticket) => {
    [ticket.client, ticket.pos, ticket.ticketLogs] = await Promise.all([
      db.client.get(ticket.clientId),
      db.pos.get(ticket.posId),
      db.ticketLog
        .where({ ticketId: ticket.id, ticketIdPda: ticket.idPda })
        .sortBy('logDate'),
    ]);
  }));

  return tickets;
};

/**
 * @param {number} clientId
 * @returns {Promise<TTicket[]>}
 */
const findAllTicketForClient = async (clientId) => {
  const tickets = await db.ticket
    .where({ clientId: Number(clientId) })
    .toArray();

  await Promise.all(tickets.map(async (ticket) => {
    [ticket.client, ticket.pos, ticket.ticketLogs] = await Promise.all([
      db.client.get(ticket.clientId),
      db.pos.get(ticket.posId),
      db.ticketLog
        .where({ ticketId: ticket.id, ticketIdPda: ticket.idPda })
        .sortBy('logDate'),
    ]);
  }));

  return tickets;
};

/**
 * @param ticketId
 * @param ticketIdPda
 * @returns {Promise<TTicket>}
 */
const findTicket = async (ticketId, ticketIdPda) => {
  const ticket = await db.ticket.get({ id: Number(ticketId), idPda: ticketIdPda });

  [ticket.client, ticket.pos, ticket.ticketLogs] = await Promise.all([
    db.client.get(ticket.clientId),
    db.pos.get(ticket.posId),
    db.ticketLog
      .where({ ticketId: ticket.id, ticketIdPda: ticket.idPda })
      .sortBy('logDate'),
  ]);

  return ticket;
};

/**
 * @param {TTicket} ticket
 * @return {Promise<TTicket>}
 */
const createTicket = async (ticket) => {
  const newPk = await db.ticket.add(ticket);
  return findTicket(newPk[0], newPk[1]);
};

/**
 * @param status {string}
 * @returns {string}
 */
const filterTicketStatus = (status) => (ticketStatusName[status] ? ticketStatusName[status] : status);

/**
 * @param resolveType {string}
 * @returns {string}
 */
const filterTicketResolveType = (resolveType) => (ticketResolveTypeName[resolveType]
  ? ticketResolveTypeName[resolveType]
  : resolveType);

/**
 * @param priority {string}
 * @returns {string}
 */
const filterTicketPriority = (priority) => (ticketPriority[priority] ? ticketPriority[priority] : priority);

/**
 * @param location {string}
 * @returns {string}
 */
const filterPosLocation = (location) => (posLocation[location] ? posLocation[location] : location);

/**
 * Generate new free ticket number by adding suffix with following number
 * @param {string} baseTicketNo
 * @return Promise(<string>)
 */
const generateTicketNo = async (baseTicketNo) => {
  const makeTicketNo = (sfx) => `${baseTicketNo.substring(0, 12)}ZD${String(sfx).padStart(2, '0')}`;

  const tickets = await db.ticket
    .where('ticketNo')
    .startsWithIgnoreCase(baseTicketNo.substring(0, 12))
    .toArray();

  let suffix = 0;
  let isOccupied = false;
  do {
    suffix += 1;

    const testedTicketNumber = makeTicketNo(suffix);
    isOccupied = tickets.filter((ticket) => ticket.ticketNo === testedTicketNumber).length > 0;
  } while (isOccupied && suffix < 99);

  return makeTicketNo(suffix);
};

/**
 * Get last (not synchronized yet) ticket log for given ticket
 * @param {number} ticketId
 * @param {string} ticketIdPda
 * @returns {Promise<TTicketLog | undefined>}
 */
const findLastUserLogForTicket = async (ticketId, ticketIdPda) => db.ticketLog
  .where({ ticketId: Number(ticketId), ticketIdPda })
  .filter((t) => t.id === 0)
  .first();

/**
 * Add or update last ticket Log for given ticket
 * When flag forceNew is set, will always be created as new
 * @param {number} ticketId
 * @param {string} ticketIdPda
 * @param {string} notice
 * @param {boolean} forceNew
 * @returns {Promise<TTicketLog>}
 */
const createTicketLog = async (ticketId, ticketIdPda, notice, forceNew = false) => {
  let lastTicketLog;
  if (!forceNew) {
    lastTicketLog = await findLastUserLogForTicket(ticketId, ticketIdPda);
  }

  if (!lastTicketLog) {
    const newId = await db.ticketLog.add({
      id: 0,
      idPda: uuid(),
      ticketId: Number(ticketId),
      ticketIdPda,
      ownerName: localStorage.getItem('userName') || '',
      logType: 'COMMENT',
      description: 'Dodany komentarz',
      notice,
      logDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      statusPda: 'N',
    });
    lastTicketLog = { id: newId[0], idPda: newId[1] };
  }
  await db.ticketLog
    .where({ id: lastTicketLog.id, idPda: lastTicketLog.idPda })
    .modify({ notice });

  return db.ticketLog.where({ id: lastTicketLog.id, idPda: lastTicketLog.idPda }).first();
};

/**
 * Cancel ticket and add to log
 * @param {number} ticketId
 * @param {string} ticketIdPda
 * @param {number} ticketResolveReasonId
 * @param {string} notice
 * @returns {Promise<TTicket>}
 */
const cancelTicket = async (ticketId, ticketIdPda, ticketResolveReasonId, notice) => {
  let ticket;
  await db.transaction('rw', db.ticket, db.ticketLog, db.client, db.pos, db.ticketResolveReason, async () => {
    const ticketResolveReason = await db.ticketResolveReason.get(Number(ticketResolveReasonId));
    await db.ticket
      .where({ id: Number(ticketId), idPda: ticketIdPda })
      .modify({
        status: ticketStatus.RESOLVED,
        dateResolved: moment().format('YYYY-MM-DD HH:mm:ss'),
        ticketResolveType: ticketResolveType.CANCELED,
        ticketResolveReasonId: Number(ticketResolveReasonId),
        ticketResolveReasonName: ticketResolveReason?.name,
        statusPda: 'M',
      });
    await Ticket.createTicketLog(ticketId, ticketIdPda, notice, true);
    ticket = await findTicket(ticketId, ticketIdPda);
  });
  return ticket;
};

/**
 * Get information about using or not spare part in given ticket
 * @param {number} ticketId
 * @param {string} ticketIdPda
 * @return {Promise<boolean>}
 */
const ticketHasSpare = async (ticketId, ticketIdPda) => {
  let ticketHasSpareResult = false;
  const ticketActivities = await Activity.findAllActivityForTicket(ticketId, ticketIdPda);

  if (ticketActivities.length > 0) {
    await Promise.all(
      ticketActivities.map(async (ticketActivity) => {
        if ((await Activity.findAllActivitySpareForActivity(
          ticketActivity.id,
          ticketActivity.idPda,
        )).length > 0) {
          ticketHasSpareResult = true;
        }
      }),
    );
  }

  return ticketHasSpareResult;
};

/**
 * Get all improper ticket reasons
 * @return {Promise<TImproperTicketReason[]>}
 */
const findAllImproperTicketReason = async () => db.improperTicketReason
  .where({ deleted: 0 })
  .sortBy('name');

/**
 * Get improper ticket reason
 * @param {number} improperTicketReasonId
 * @return {TImproperTicketReason}
 */
const findImproperTicketReason = (improperTicketReasonId) => db.improperTicketReason
  .get(Number(improperTicketReasonId));

/**
 * @param {number} ticketId
 * @param {string} ticketIdPda
 * @param {TResolveTicket} resolveTicketData
 * @return {Promise<void>}
 */
const resolveTicket = async (ticketId, ticketIdPda, resolveTicketData) => {
  await db.transaction('rw', db.ticket, db.ticketReport, db.ticketField, async () => {
    const updatedData = {
      status: ticketStatus.RESOLVED,
      dateResolved: moment().format('YYYY-MM-DD HH:mm:ss'),
      ticketResolveType: ticketResolveType.RESOLVED,
      incorrectUsePercent: Number(resolveTicketData.incorrectUsePercent),
      posLocation: resolveTicketData.posLocation,
      improperTicket: resolveTicketData.improperTicket ? 1 : 0,
      improperTicketReasonId: resolveTicketData.improperTicketReasonId,
      statusPda: 'U',
    };
    if (resolveTicketData.sanit) {
      updatedData.dateSanit = moment().format('YYYY-MM-DD HH:mm:ss');
    }
    if (resolveTicketData.insp) {
      updatedData.dateInsp = moment().format('YYYY-MM-DD HH:mm:ss');
    }

    await db.ticket.update([Number(ticketId), ticketIdPda], updatedData);
    await saveTicketReport(ticketId, ticketIdPda, resolveTicketData);
  });

  return true;
};

/**
 * Save ticker resolve survey
 * @param {number} ticketId
 * @param {string} ticketIdPda
 * @param {TResolveTicket} resolveTicketData
 * @return {Promise<void>}
 */
const saveTicketReport = async (ticketId, ticketIdPda, resolveTicketData) => {
  const { visitId, visitIdPda, ticketReports } = resolveTicketData;

  if (!ticketReports || ticketReports.length === 0) {
    return;
  }

  await Promise.all(
    ticketReports
      .filter((question) => question.fieldId !== 0)
      .map(async (question) => {
        await db.ticketReport.add({
          ticketId: Number(ticketId),
          ticketIdPda,
          visitId,
          visitIdPda,
          fieldId: question.fieldId,
          ansInt: !question.disabled ? question.ansInt : null,
          ansDec: !question.disabled ? question.ansDec : null,
          ansBool: !question.disabled ? question.ansBool : null,
          ansString: !question.disabled ? question.ansString : null,
          statusPda: 'N',
        });
      }),
  );
};

/**
 * Get all ticket field
 * @return {Promise<TTicketField[]>}
 */
const findAllTicketField = async () => db.ticketField.toArray();

/**
 * Get given ticket field
 * @param {number} ticketFieldId
 * @return {Promise<TTicketField>}
 */
const findTicketField = async (ticketFieldId) => db.ticketField.get(Number(ticketFieldId));

/**
 * Get all ticket report
 * @return {Promise<TTicketReport[]>}
 */
const findAllTicketReport = async () => {
  const ticketReports = await db.ticketReport.toArray();

  await Promise.all(ticketReports.map(async (report) => {
    [report.fieldDefinition] = await Promise.all([
      Ticket.findTicketField(report.fieldId),
    ]);
  }));

  return ticketReports;
};

/**
 * Get report answer for given ticket, visit and field
 * @param {number} ticketId
 * @param {string} ticketIdPda
 * @param {number} visitId
 * @param {string} visitIdPda
 * @param {number} fieldId
 * @return {Promise<TTicketReport|undefined>}
 */
const findTicketReport = async (ticketId, ticketIdPda, visitId, visitIdPda, fieldId) => {
  const ticketReport = await db.ticketReport
    .get([
      Number(ticketId),
      ticketIdPda,
      Number(visitId),
      visitIdPda,
      Number(fieldId),
    ]);

  if (!ticketReport) {
    return undefined;
  }

  ticketReport.fieldDefinition = await Ticket.findTicketField(ticketReport.fieldId);

  return ticketReport;
};

/**
 * Check that given ticket has valve spare
 * @param {number} ticketId
 * @param {string} ticketIdPda
 * @return {Promise<boolean>}
 */
const ticketHasValveSpare = async (ticketId, ticketIdPda) => {
  let ticketHasValveResult = false;
  const ticketActivities = await Activity.findAllActivityForTicket(ticketId, ticketIdPda);

  if (ticketActivities.length > 0) {
    await Promise.all(
      ticketActivities.map(async (ticketActivity) => {
        const activitySpares = await Activity.findAllActivitySpareForActivity(ticketActivity.id, ticketActivity.idPda);

        ticketHasValveResult = ticketHasValveResult || activitySpares.reduce(
          (acc, spare) => acc || spare.spareTypeId === SPARE_VALVE_ID,
          false,
        );
      }),
    );
  }

  return ticketHasValveResult;
};

/**
 * Check that given pos has valve spare assigned
 * @param {number} posId
 * @return {Promise<boolean>}
 */
const posHasValveSpare = async (posId) => {
  const posValves = await db.posValve
    .where({ posId: Number(posId) })
    .filter((pv) => pv.active === 1)
    .toArray();

  return posValves.length > 0;
};

/**
 * Find all posSpare for given pos
 * @param {number} posId
 * @return {Promise<TPosValve[]>}
 */
const findAllPosValve = async (posId) => {
  const posValves = await db.posValve
    .where({ posId: Number(posId) })
    .filter((pv) => pv.active === 1)
    .toArray();

  await Promise.all(posValves.map(async (posValve) => {
    posValve.spareType = await db.spareType.get(Number(posValve.spareTypeId));
  }));

  return posValves;
};

/**
 * Find posSpare for given pos
 * @param {number} posValveId
 * @param {string} posValveIdPda
 * @return {Promise<TPosValve|undefined>}
 */
const findPosValve = async (posValveId, posValveIdPda) => {
  const posValve = await db.posValve.get({ id: Number(posValveId), idPda: posValveIdPda });

  if (!posValve) {
    return undefined;
  }

  posValve.spareType = await db.spareType.get(Number(posValve.spareTypeId));

  return posValve;
};

/**
 * Find posValve by serialNp
 * @param {string} serialNo
 * @param {number} posId
 * @return {Promise<TPosValve|undefined>}
 */
const findPosValveBySerialNo = async (serialNo, posId) => {
  const posValves = await db.posValve
    .where({ posId: Number(posId) })
    .filter((pv) => pv.serialNo === serialNo)
    .toArray();

  return posValves.length > 0 ? posValves[0] : undefined;
};

/**
 * Insert or update posValve
 * @param {TPosValve} posValveData
 * @return {Promise<TPosValve>}
 */
const upsertPosValve = async (posValveData) => {
  const updatedPosValveData = {
    ...posValveData,
    lastModifyByUserId: Number(localStorage.getItem('userId') || 0),
    lastModifyDate: moment().format('YYYY-MM-DD HH:mm:ss'),
  };

  const existingPosValveForSerialNo = await findPosValveBySerialNo(
    updatedPosValveData.serialNo,
    updatedPosValveData.posId,
  );

  if (existingPosValveForSerialNo && existingPosValveForSerialNo.active === 1) {
    throw new Error('Istnieje już zawór o podanym numerze seryjnym');
  }

  if (existingPosValveForSerialNo && existingPosValveForSerialNo.active === 0) {
    updatedPosValveData.id = existingPosValveForSerialNo.id;
    updatedPosValveData.idPda = existingPosValveForSerialNo.idPda;
    updatedPosValveData.active = 1;
    updatedPosValveData.statusPda = 'M';
  }

  const posValve = await db.posValve.get({ id: updatedPosValveData.id, idPda: updatedPosValveData.idPda });

  if (!posValve) {
    updatedPosValveData.statusPda = 'N';
    await db.posValve.add(updatedPosValveData);
  } else {
    updatedPosValveData.statusPda = 'M';
    await db.posValve.update([Number(updatedPosValveData.id), updatedPosValveData.idPda], updatedPosValveData);
  }

  return findPosValve(updatedPosValveData.id, updatedPosValveData.idPda);
};

/**
 * Find posSpare for given pos
 * @param {number} posValveId
 * @param {string} posValveIdPda
 * @return {Promise<void|number>}
 */
const deletePosValve = async (posValveId, posValveIdPda) => {
  if (posValveId === 0) {
    return db.posValve.delete([Number(posValveId), posValveIdPda]);
  }

  return db.posValve.update(
    [Number(posValveId), posValveIdPda],
    {
      active: 0,
      lastModifyDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      lastModifyByUserId: Number(localStorage.getItem('userId') || '0'),
      statusPda: 'M',
    },
  );
};

/**
 * List additional module private methods, exposed only for unit test
 */
export const TicketPrivate = {
  saveTicketReport,
  findPosValveBySerialNo,
};

export const Ticket = {
  findAllTicket,
  findAllTicketForClient,
  findAllTicketByIds,
  findTicket,
  createTicket,
  filterTicketStatus,
  filterTicketResolveType,
  filterTicketPriority,
  filterPosLocation,
  generateTicketNo,
  findLastUserLogForTicket,
  createTicketLog,
  cancelTicket,
  ticketHasSpare,
  findAllImproperTicketReason,
  findImproperTicketReason,
  resolveTicket,
  findAllTicketField,
  findTicketField,
  findAllTicketReport,
  findTicketReport,
  ticketHasValveSpare,
  posHasValveSpare,
  findAllPosValve,
  findPosValve,
  upsertPosValve,
  deletePosValve,
};
