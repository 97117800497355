import React from 'react';

/**
 * Call callback just after component mounts
 * @param {function} cb
 * @param {Array<*>} dependencies
 */
export const useEffectAfterMount = (cb, dependencies) => {
  const justMounted = React.useRef(true);
  React.useEffect(() => {
    if (!justMounted.current) {
      return cb();
    }
    justMounted.current = false;
    return null;
  }, dependencies);
};
