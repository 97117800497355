import React, { useState } from 'react';
import { DataGrid, plPL, GridColDef } from '@mui/x-data-grid';
import { useLiveQuery } from 'dexie-react-hooks';

import { IconButton } from '@mui/material';
import { Search as IconSearch } from '@mui/icons-material';
import { history } from '../_helpers';
import { DS } from '../_services';
import TicketDetailDialog from './TicketDetailDialog';
import TicketStatusChip from './TicketStatusChip';
import { ConfirmDialog } from '../Common';

function TicketList() {
  let isClosing = false;
  const [dayStatus, setDayStatus] = useState({ dayIsOpen: false, dayIsClose: false });
  const [detailDialog, setDetailDialog] = useState({ open: false });
  const [reopenDayDialog, setReopenDayDialog] = useState({ open: false });

  /**
   *
   * @type {GridColDef[]}
   */
  const columns = [
    {
      field: 'ticketNo',
      headerName: '',
      sortable: false,
      filterable: false,
      width: 50,
      renderCell: (params) => (
        <IconButton
          size="small"
          color="info"
          onClick={(event) => viewDetailHandler(event, params.row.id, params.row.idPda)}
        >
          <IconSearch />
        </IconButton>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      valueFormatter: (params) => DS.Ticket.filterTicketStatus(params.value),
      renderCell: (params) => (
        <TicketStatusChip
          ticketStatus={params.value}
          ticketId={params.row.id}
          ticketIdPda={params.row.idPda}
          clientId={params.row.clientId}
          enabled={dayStatus.dayIsOpen}
          onClick={openVisitHandler}
        />
      ),
    },
    { field: 'ticketTypeName', headerName: 'Typ', width: 110 },
    {
      field: 'priority',
      headerName: 'Priorytet',
      width: 100,
      valueGetter: (param) => DS.Ticket.filterTicketPriority(param.row.priorityLevel),
    },
    { field: 'pos.posTypeName', headerName: 'Pos', width: 300, valueGetter: (param) => param.row.pos.posTypeName },
    {
      field: 'clientName',
      headerName: 'Klient',
      width: 300,
      valueGetter: (param) => param.row.client.name,
    },
    { field: 'barcode', headerName: 'Barcode', width: 100, valueGetter: (param) => param.row.pos.barcode },
    { field: 'street', headerName: 'Ulica', width: 200, valueGetter: (param) => param.row.client.street },
    { field: 'serialNo', headerName: 'Numer seryjny', width: 150, valueGetter: (param) => param.row.pos.serialNo },
    { field: 'city', headerName: 'Miasto', width: 200, valueGetter: (param) => param.row.client.city },
    { field: 'routeDate', headerName: 'Plan', width: 130 },
  ];

  /**
   * @type {TTicket[]}
   */
  const rows = useLiveQuery(async () => {
    if (isClosing) {
      return Promise.resolve([]);
    }
    setDayStatus(await DS.VisitDay.getVisitDayStatus());
    return DS.Ticket.findAllTicket();
  });

  /**
   * @param {Event} event
   * @param {number} clientId
   * @param {number} ticketId
   * @param {string} ticketIdPda
   */
  const openVisitHandler = (event, ticketId, ticketIdPda, clientId) => {
    event.defaultMuiPrevented = true;
    if (dayStatus.dayIsOpen && dayStatus.dayIsClose) {
      setReopenDayDialog((s) => ({
        ...s,
        open: true,
        clientId,
        ticketId,
        ticketIdPda,
      }));
      return;
    }

    if (dayStatus.dayIsOpen) {
      goToVisit(clientId, ticketId, ticketIdPda);
    }
  };

  /**
   * @param {boolean} isConfirmed
   */
  const reopenDayAndVisitHandler = async (isConfirmed) => {
    if (!isConfirmed) {
      setReopenDayDialog({ open: false });
      return;
    }

    await DS.VisitDay.restartVisitDay();
    goToVisit(reopenDayDialog.clientId, reopenDayDialog.ticketId, reopenDayDialog.ticketIdPda);
  };

  /**
   * @param {number} clientId
   * @param {number} ticketId
   * @param {string} ticketIdPda
   */
  const goToVisit = (clientId, ticketId, ticketIdPda) => {
    isClosing = true;
    history.navigate(`/visit/${ticketId}/${ticketIdPda}/${clientId}`);
  };

  /**
   * @param {Event} event
   * @param {number} ticketId
   * @param {string} ticketIdPda
   */
  const viewDetailHandler = (event, ticketId, ticketIdPda) => {
    event.defaultMuiPrevented = true;
    setDetailDialog((s) => ({
      ...s,
      open: true,
      ticketId,
      ticketIdPda,
    }));
  };

  const dialogCloseHandler = () => {
    setDetailDialog((s) => ({ open: false }));
  };

  return (
    <div style={{ display: 'flex', height: 'calc(100vh - 80px)' }}>
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          rows={rows || []}
          columns={columns}
          getRowId={(row) => `${row.id}-${row.idPda}`}
          autoPageSize
          rowsPerPageOptions={[5, 10, 25]}
          density="compact"
          localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
        />
        {detailDialog.open && (
          <TicketDetailDialog
            onAccept={dialogCloseHandler}
            open={detailDialog.open}
            ticketId={detailDialog.ticketId}
            ticketIdPda={detailDialog.ticketIdPda}
          />
        )}
        {reopenDayDialog.open && (
          <ConfirmDialog
            onClose={reopenDayAndVisitHandler}
            open={reopenDayDialog.open}
            dialogTitle="Potwierdź ponowne otwarcie dnia"
          >
            <>
              Dzień został zamknięty, aby przejść do rozwiązania zgłoszenia musisz go ponownie otworzyć.
              <br />
              Czy potwierdzasz?
            </>
          </ConfirmDialog>
        )}
      </div>
    </div>
  );
}

export default TicketList;
