export const warehouseIds = {
  WH_CLIENT_ID: 5,
  WH_MC_ID: 1,
};

export const warehouseStatuses = {
  WH_DELIVERED: 'DELIVERED',
};

export const whOrderStatuses = {
  WH_NEW: 'NEW',
  WH_ORDER: 'ORDER',
  WH_SEND: 'SEND',
  WH_PREPARED: 'PREPARED',
  WH_DELIVERED: 'DELIVERED',
  WH_CANCELED: 'CANCELED',
  WH_REJECTED: 'REJECTED',
};

export const whOrderStatusName = {
  WH_NEW: 'Nowe',
  WH_ORDER: 'Zamówione',
  WH_SEND: 'Wysłane',
  WH_PREPARED: 'Przygotowane',
  WH_DELIVERED: 'Dostarczone',
  WH_CANCELED: 'Anulowane',
  WH_REJECTED: 'Odrzucone',
};

export const warehouseShiftTypes = {
  WH_SAP: 'SAP',
  WH_INTERNAL: 'INTERNAL',
};
