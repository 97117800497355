import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { DataGrid, plPL, GridColDef } from '@mui/x-data-grid';

import { DS } from '../_services';
import { ContentDialog } from '../Common';

function PosHistoryDialog(props) {
  const {
    open,
    onAccept,
    maxWidth,
    posId,
  } = props;

  const [state, setState] = useState({
    tabIndex: 0,
    dialogTitle: '',
  });
  const [posHistory, setPosHistory] = useState([]);

  useEffect(() => {
    const fetchPosHistory = async () => {
      const pos = await DS.Pos.findPos(posId);
      setState((s) => ({ ...s, dialogTitle: `${pos.posType.name} - ${pos.barcode}` }));

      setPosHistory(pos.posHistories);
    };

    fetchPosHistory();
  }, [posId]);

  /**
   * @type {GridColDef[]}
   */
  const columns = [
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      valueGetter: (param) => DS.Ticket.filterTicketStatus(param.row.status),
    },
    { field: 'ticketTypeId', headerName: 'Typ', width: 100, valueGetter: (param) => param.row.ticketTypeName },
    {
      field: 'dateNew',
      headerName: 'Data',
      width: 100,
      valueGetter: (param) => (param.row.dateNew
        ? moment(String(param.row.dateNew)).format('YYYY-MM-DD')
        : ''),
    },
    { field: 'category', headerName: 'Kategoria', width: 180, valueGetter: (param) => param.row.category },
    { field: 'servicemanName', headerName: 'Serwisant', width: 150, valueGetter: (param) => param.row.servicemanName },
    { field: 'notice', headerName: 'Opis', width: 400, valueGetter: (param) => param.row.notice },
  ];

  const handleAccept = (...prop) => onAccept(...prop);

  return (
    <div>
      <ContentDialog
        open={open}
        onAccept={handleAccept}
        onDecline={handleAccept}
        dialogTitle={state.dialogTitle}
        maxWidth={maxWidth}
        acceptLabel="OK"
        declineLabel=""
      >
        <div style={{ display: 'flex', height: '400px' }}>
          <DataGrid
            rows={posHistory || []}
            columns={columns}
            sx={{ m: 0 }}
            autoPageSize
            pageSize={25}
            rowsPerPageOptions={[5, 10, 25]}
            density="compact"
            hideFooterSelectedRowCount
            localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
          />
        </div>
      </ContentDialog>
    </div>
  );
}

PosHistoryDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  posId: PropTypes.number.isRequired,
};

PosHistoryDialog.defaultProps = {
  maxWidth: 'md',
};

export default PosHistoryDialog;
