import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLiveQuery } from 'dexie-react-hooks';

import {
  Box, IconButton,
} from '@mui/material';
import {
  Delete as IconDelete,
  Edit as IconEdit,
} from '@mui/icons-material';
import { DataGrid, plPL, GridColDef } from '@mui/x-data-grid';

import { DS } from '../_services';
import { ConfirmDialog, CustomFooter } from '../Common';
import EditPosValveDialog from './EditPosValveDialog';

/**
 * @param {Object} props
 * @param {TTicket} props.ticket
 * @returns {JSX.Element}
 * @constructor
 */
function ValveTab(props) {
  const { ticket } = props;
  const [state, setState] = useState({
    selectedPosValveId: 0,
    selectedPosValveIdPda: '',
    openDeletePosValve: false,
    deletePosValveName: '',
  });
  const [addPosValve, setAddPosValve] = useState({ open: false });

  /**
   * @type {GridColDef[]}
   */
  const columnsPosValve = [
    {
      field: 'id',
      headerName: '',
      sortable: false,
      filterable: false,
      minWidth: 60,
      flex: 0.3,
      renderCell: (params) => (
        <>
          <IconButton
            size="small"
            color="info"
            onClick={(event) => editPosValveHandler(params.row.id, params.row.idPda, params.row.spareType?.name)}
          >
            <IconEdit />
          </IconButton>
          <IconButton
            size="small"
            color="info"
            onClick={(event) => deletePosValveHandler(
              params.row.id,
              params.row.idPda,
              `${params.row.spareType?.name} - ${params.row.serialNo}`,
            )}
          >
            <IconDelete />
          </IconButton>
        </>
      ),
    },
    {
      field: 'spareType.name',
      headerName: 'Typ',
      minWidth: 100,
      flex: 1,
      valueGetter: (param) => param.row.spareType?.name,
    },
    {
      field: 'serialNo',
      headerName: 'Numer seryjny',
      minWidth: 100,
      flex: 1,
      valueGetter: (param) => param.row.serialNo,
    },
    {
      field: 'lastModifyDate',
      headerName: 'Data ostatniej zmiany',
      minWidth: 100,
      flex: 1,
      valueGetter: (param) => param.row.lastModifyDate,
    },
  ];

  /**
   * @type {TPosValve[]}
   */
  const rowsPosValve = useLiveQuery(async () => DS.Ticket.findAllPosValve(ticket.posId || 0), [ticket.posId]);

  /**
   * @param {number} posValveId
   * @param {string} posValveIdPda
   * @param {string} posValveName
   */
  const editPosValveHandler = (posValveId, posValveIdPda, posValveName) => {
    setAddPosValve((s) => ({ ...s, open: true, posValveId, posValveIdPda, posValveName }));
  };

  /**
   * @param {number} posValveId
   * @param {string} posValveIdPda
   * @param {string} posValveName
   */
  const deletePosValveHandler = (posValveId, posValveIdPda, posValveName) => {
    setState((s) => ({ ...s, openDeletePosValve: true, posValveId, posValveIdPda, deletePosValveName: posValveName }));
  };

  const deleteActivityCloseHandler = async (isConfirmed) => {
    if (isConfirmed) {
      await DS.Ticket.deletePosValve(
        state.posValveId,
        state.posValveIdPda,
      );
    }
    setState((s) => ({ ...s, openDeletePosValve: false }));
  };

  const addPosValveHandler = () => {
    setAddPosValve((s) => ({ ...s, open: true, posValveId: 0, posValveIdPda: '', posValveName: '' }));
  };

  const addPosValveCloseHandler = () => {
    dialogPosValveCloseHandler();
  };

  const dialogPosValveCloseHandler = () => {
    setAddPosValve((s) => ({ ...s, open: false }));
  };

  return (
    <Box component="div" sx={{}}>
      <Box component="div" sx={{ display: 'flex', height: 'calc(100vh - 130px)' }}>
        <Box component="div" sx={{ my: 1, flexGrow: 1 }}>
          <DataGrid
            rows={rowsPosValve || []}
            columns={columnsPosValve}
            getRowId={(row) => `${row.id}^${row.idPda}`}
            sx={{ mt: 1 }}
            autoPageSize
            pageSize={25}
            rowsPerPageOptions={[5, 10, 25]}
            density="compact"
            localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
            hideFooterSelectedRowCount
            components={{
              Footer: CustomFooter,
            }}
            componentsProps={{
              footer: {
                buttonLabel: 'Dodaj zawór',
                onClick: addPosValveHandler,
              },
            }}
          />
        </Box>
      </Box>
      {addPosValve.open && (
        <EditPosValveDialog
          open={addPosValve.open}
          onAccept={addPosValveCloseHandler}
          onDecline={dialogPosValveCloseHandler}
          maxWidth="xs"
          posId={ticket.posId}
          posValveId={addPosValve.posValveId}
          posValveIdPda={addPosValve.posValveIdPda}
        />
      )}
      {state.openDeletePosValve && (
        <ConfirmDialog
          open={state.openDeletePosValve}
          onClose={deleteActivityCloseHandler}
          dialogTitle="Usunięcie zaworu"
          maxWidth="xs"
          dialogType="alert"
        >
          {`Czy na pewno chcesz usunąć zawór: ${state.deletePosValveName}`}
        </ConfirmDialog>
      )}
    </Box>
  );
}

ValveTab.propTypes = {
  ticket: PropTypes.shape(Object).isRequired,
};

ValveTab.defaultProps = {};

export default ValveTab;
