import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';

function QuestionStringForm(props) {
  const {
    /** @type {number} */ fieldId,
    /** @type {string} */ label,
    /** @type {string} */ value,
    /** @type {boolean} */ disabled,
    /** @type {function} */ onChange,
  } = props;

  const dictionary = () => {
    switch (fieldId) {
      case 24:
        return [
          { id: '250V', name: '250V' },
          { id: '500V', name: '500V' },
        ];
      case 26:
        return [
          { id: 'obudowa', name: 'obudowa' },
          { id: 'drzwi', name: 'drzwi' },
          { id: 'bok', name: 'bok' },
          { id: 'rączka', name: 'rączka' },
          { id: 'inne poł. elem. galw.', name: 'inne poł. elem. galw.' },
        ];
      case 28:
        return [
          { id: 'gniazdo zamocowane trwale', name: 'gniazdo zamocowane trwale' },
          { id: 'przedłużacz', name: 'przedłużacz' },
          { id: 'rozgałęźnik', name: 'rozgałęźnik' },
          { id: 'uszkodzone', name: 'uszkodzone' },
          { id: 'inne', name: 'inne' },
        ];
      case 31:
        return [
          { id: 'R134a', name: 'R134a' },
          { id: 'R290', name: 'R290' },
          { id: 'R404', name: 'R404' },
          { id: 'R600', name: 'R600' },
        ];
      default:
        return [];
    }
  };

  const inputChangeHandler = (event) => {
    if (onChange && typeof onChange === 'function') {
      onChange(event.target.value);
    }
  };

  return (
    <FormControl sx={{ my: 1 }} fullWidth size="small">
      <InputLabel
        id={`field_${fieldId}_label`}
        htmlFor={`field_${fieldId}`}
      >
        {label}
      </InputLabel>
      <Select
        labelId={`field_${fieldId}_label`}
        id={`field_${fieldId}`}
        label={label}
        name={`field_${fieldId}`}
        value={value}
        onChange={inputChangeHandler}
        disabled={disabled}
      >
        {dictionary().map((sg) => (
          <MenuItem key={sg.id} value={sg.id}>{sg.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

QuestionStringForm.propTypes = {
  fieldId: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

QuestionStringForm.defaultProps = {
  disabled: false,
};

export default QuestionStringForm;
