import moment from 'moment';
import { db } from './db';
import { Activity } from './activity.data.service';
import { uuid } from '../_helpers/uuid';
import { warehouseIds, warehouseShiftTypes, warehouseStatuses, whOrderStatuses } from '../_constants';

/**
 * @returns {Promise<TSpareType[]>}
 */
const findAllSpareType = () => db.spareType
  .where({ deleted: 0 })
  .toArray();

/**
 * @param {number} spareTypeId
 * @returns {Promise<TSpareType>}
 */
const findSpareType = (spareTypeId) => db.spareType.get(Number(spareTypeId));

/**
 * @returns {Promise<TSpareShift[]>}
 */
const findAllSpareShift = () => db.spareShift
  .toArray();

/**
 * Return ID of logged user, otherwise -1
 * @return {number}
 */
const ownerId = () => Number(localStorage.getItem('userId') || -1);

/**
 * Return Name of logged user, otherwise ''
 * @return {string}
 */
const ownerName = () => localStorage.getItem('userName') || '';

/**
 * Return warehouseId of logged user, otherwise -1
 * @return {number}
 */
const ownerWarehouseId = () => Number(localStorage.getItem('warehouseId') || -1);

/**
 * Calculate sign of quantity depends on source or destination given warehouse movement
 * If current user is not source and destination result will be 0
 * @param {number} sourceWarehouseId
 * @param {number} destinationWarehouseId
 * @param {number} quantity
 * @return {number}
 */
const calcQuantity = (sourceWarehouseId, destinationWarehouseId, quantity) => {
  if (Number(destinationWarehouseId) === ownerWarehouseId()) {
    return quantity;
  }

  if (Number(sourceWarehouseId) === ownerWarehouseId()) {
    return quantity * -1;
  }

  return 0;
};

/**
 * Get quantity of spare stock base on spareTypeId
 * @param spareTypeId
 * @return {Promise<number>}
 */
const getStockQuantityBySpareId = async (spareTypeId) => {
  const spareShifts = await db.spareShift
    .where({ spareTypeId: Number(spareTypeId) })
    .toArray();
  const spareOrders = await db.spareOrder
    .where({ spareTypeId: Number(spareTypeId) })
    .filter((order) => order.status === whOrderStatuses.WH_SEND
      && order.sourceWarehouseId === ownerWarehouseId()
      && order.statusPda !== 'S')
    .toArray();

  const shiftQuantity = spareShifts.reduce(
    (quantity, shift) => quantity + calcQuantity(
      shift.sourceWarehouseId,
      shift.destinationWarehouseId,
      shift.quantity,
    ),
    0,
  );
  const orderQuantity = spareOrders.reduce(
    (quantity, order) => quantity + calcQuantity(
      order.sourceWarehouseId,
      order.destinationWarehouseId,
      order.quantitySend,
    ),
    0,
  );

  return shiftQuantity + orderQuantity;
};

/**
 * calculate spare order quantity for given spareTypeId
 * @param {number} spareTypeId
 * @return {Promise<number>}
 */
const getOrderQuantityBySpareId = async (spareTypeId) => {
  const statusesInScope = [whOrderStatuses.WH_ORDER, whOrderStatuses.WH_PREPARED, whOrderStatuses.WH_SEND];
  const spareOrders = await db.spareOrder
    .where({ spareTypeId: Number(spareTypeId) })
    .filter((order) => statusesInScope.includes(order.status) && order.destinationWarehouseId === ownerWarehouseId())
    .toArray();

  return spareOrders.reduce(
    (quantity, order) => quantity + calcQuantity(
      order.sourceWarehouseId,
      order.destinationWarehouseId,
      order.status === whOrderStatuses.WH_ORDER ? order.quantityOrder : order.quantitySend,
    ),
    0,
  );
};

/**
 * Get all spares with its stock quantity
 * @return {Promise<TSpareType[]>}
 */
const findAllSpareWithStock = async () => {
  const spares = await db.spareType
    .where({ deleted: 0 })
    .sortBy('name');

  await Promise.all(spares.map(async (spare) => {
    [spare.stockQuantity, spare.orderQuantity] = await Promise.all([
      getStockQuantityBySpareId(spare.id),
      getOrderQuantityBySpareId(spare.id),
    ]);
  }));

  return spares;
};

/**
 * Get spares with its stock quantity
 * @param {number} spareTypeId
 * @return {Promise<TSpareType|undefined>}
 */
const findSpareWithStock = async (spareTypeId) => {
  const spare = await db.spareType.get(Number(spareTypeId));

  if (!spare) {
    return undefined;
  }

  spare.stockQuantity = await getStockQuantityBySpareId(spare.id);
  spare.orderQuantity = await getOrderQuantityBySpareId(spare.id);

  return spare;
};

/**
 * Get all spares with its stock quantity base on given filter
 * @param {Object} whFilter
 * @param {number} whFilter.spareGroupId
 * @param {number} whFilter.spareCategory1Id
 * @param {number} whFilter.spareCategory2Id
 * @param {string} whFilter.spareName
 * @return {Promise<TSpareType[]>}
 */
const findAllSpareWithStockByFilter = async (whFilter = {}) => {
  let spares = await db.spareType
    .where({ deleted: 0 })
    .sortBy('name');

  if (whFilter.spareGroupId && whFilter.spareGroupId !== 'ALL') {
    spares = spares.filter((spare) => spare.spareGroup === whFilter.spareGroupId);
  }
  if (whFilter.spareName && whFilter.spareName.trim() !== '') {
    spares = spares.filter((spare) => spare.name.toUpperCase().includes(whFilter.spareName.trim().toUpperCase()));
  }
  if (whFilter.spareCategory1Id || whFilter.spareCategory2Id) {
    const categoryId = whFilter.spareCategory2Id || whFilter.spareCategory1Id;
    let spareCategoryType = await db.spareCategoryType.where({ mainCategoryId: Number(categoryId) }).toArray();
    spareCategoryType = spareCategoryType.map((spareType) => spareType.spareTypeId);
    spares = spares.filter((spare) => spareCategoryType.includes(spare.id));
  }

  await Promise.all(spares.map(async (spare) => {
    [spare.stockQuantity, spare.orderQuantity] = await Promise.all([
      getStockQuantityBySpareId(spare.id),
      getOrderQuantityBySpareId(spare.id),
    ]);
  }));

  return spares;
};

/**
 * @return {Promise<TSpareCategory[]>}
 */
const findAllSpareCategory = () => db.spareCategory.orderBy('name').toArray();

/**
 * Add spare spending to activity, if needed also add spare shift
 * @param {Object} activitySpare
 * @param {number} activitySpare.activityId
 * @param {string} activitySpare.activityIdPda
 * @param {string} activitySpare.description
 * @param {number} activitySpare.id
 * @param {string} activitySpare.idPda
 * @param {number} activitySpare.quantity
 * @param {number} activitySpare.spareTypeId
 * @return {Promise<boolean>}
 */
const addActivitySpare = async (activitySpare) => db.transaction(
  'rw',
  db.spareType,
  db.spareShift,
  db.activity,
  db.activitySpare,
  db.ticket,
  async () => {
    const spareType = await findSpareType(activitySpare.spareTypeId);
    if (!spareType) {
      return false;
    }

    const spareSpending = {
      id: Number(activitySpare.id),
      idPda: activitySpare.idPda,
      activityId: activitySpare.activityId,
      activityIdPda: activitySpare.activityIdPda,
      cost: activitySpare.quantity * spareType.priceAverage,
      description: activitySpare.description,
      quantity: activitySpare.quantity,
      spareTypeId: Number(activitySpare.spareTypeId),
      statusPda: 'N',
    };

    const addSpareToActivityResult = await Activity.addSpareToActivity(spareSpending);
    if (!addSpareToActivityResult) {
      return false;
    }

    if (spareType.spareStatus === 'FULL') {
      return addSpareShiftFromSpareActivity(spareSpending);
    }

    return true;
  },
);

/**
 * Add spare spending shift
 * @param {TActivitySpare} activitySpare
 * @return {Promise<boolean>}
 */
const addSpareShiftFromSpareActivity = async (activitySpare) => {
  const activity = await db.activity.get({ id: Number(activitySpare.activityId), idPda: activitySpare.activityIdPda });
  if (!activity) {
    return false;
  }

  const ticket = await db.ticket.get({ id: activity.ticketId, idPda: activity.ticketIdPda });
  if (!ticket) {
    return false;
  }

  const spareShift = {
    id: 0,
    idPda: uuid(),
    ownerId: ownerId(),
    ownerName: '',
    recipientId: ownerId(),
    recipientName: '',
    sourceWarehouseId: ownerWarehouseId(),
    sourceWarehouseName: '',
    destinationWarehouseId: warehouseIds.WH_CLIENT_ID,
    destinationWarehouseName: '',
    status: warehouseStatuses.WH_DELIVERED,
    shiftType: warehouseShiftTypes.WH_SAP,
    dateSend: moment().format('YYYY-MM-DD HH:mm:ss'),
    dateDelivered: moment().format('YYYY-MM-DD HH:mm:ss'),
    spareTypeId: Number(activitySpare.spareTypeId),
    quantity: Number(activitySpare.quantity),
    price: Number(activitySpare.cost),
    spareOrderId: null,
    spareOrderIdPda: null,
    activitySpareId: Number(activitySpare.id),
    activitySpareIdPda: activitySpare.idPda,
    clientId: Number(ticket.clientId),
    posId: Number(ticket.posId),
    statusPda: 'N',
  };

  const result = await db.spareShift.add(spareShift);

  return Boolean(result);
};

/**
 * Get activity spare list for given activity
 * @param {number} activityId
 * @param {string} activityIdPda
 * @return {Promise<TActivitySpare[]>}
 */
const findActivitySpares = async (activityId, activityIdPda) => {
  const activitySpares = await db.activitySpare
    .where({ activityId: Number(activityId), activityIdPda })
    .toArray();

  await Promise.all(activitySpares.map(async (activitySpare) => {
    activitySpare.spareType = await findSpareType(activitySpare.spareTypeId);
  }));

  return activitySpares;
};

/**
 * Delete activity spare entry and spare shift if exists
 * @param {number} activitySpareId
 * @param {string} activitySpareIdPda
 * @return {Promise<boolean>}
 */
const deleteActivitySpare = async (activitySpareId, activitySpareIdPda) => db.transaction(
  'rw',
  db.spareShift,
  db.activitySpare,
  async () => {
    await db.spareShift
      .where({ activitySpareId: Number(activitySpareId), activitySpareIdPda })
      .delete();

    await Activity.deleteSpareFromActivity(
      activitySpareId,
      activitySpareIdPda,
    );
  },
);

/**
 * Delete activity spare entry and spare shift if exists
 * @param {number} activityId
 * @param {string} activityIdPda
 * @return {Promise<void>}
 */
const deleteActivity = async (activityId, activityIdPda) => db.transaction(
  'rw',
  db.spareShift,
  db.activity,
  db.activitySpare,
  db.spareType,
  async () => {
    const activitySpares = await findActivitySpares(activityId, activityIdPda);

    activitySpares.map(async (activitySpare) => {
      await deleteActivitySpare(
        activitySpare.id,
        activitySpare.idPda,
      );
    });

    await Activity.deleteActivity(
      activityId,
      activityIdPda,
    );
  },
);

/**
 * Creating new spare order
 * @param {{spareTypeId: number, quantity: number, description: string}} newSpareOrder
 * @return {Promise<boolean>}
 */
const addSparePartOrder = async (newSpareOrder) => {
  const spareOrder = {
    id: 0,
    idPda: uuid(),
    dateCanceled: null,
    dateDelivered: null,
    dateOrder: moment().format('YYYY-MM-DD HH:mm:ss'),
    datePrepared: null,
    dateRejected: null,
    dateSend: null,
    destinationWarehouseId: ownerWarehouseId(),
    destinationWarehouseName: '',
    noticeCanceled: null,
    noticeDelivered: null,
    noticeOrder: newSpareOrder.description,
    noticePrepared: null,
    noticeRejected: null,
    ownerId: ownerId(),
    ownerName: ownerName(),
    quantityDelivered: 0,
    quantityOrder: Number(newSpareOrder.quantity),
    quantitySend: 0,
    senderId: null,
    senderName: null,
    sourceWarehouseId: null,
    sourceWarehouseName: null,
    spareShipmentId: null,
    spareTypeId: Number(newSpareOrder.spareTypeId),
    status: whOrderStatuses.WH_ORDER,
    statusPda: 'N',
  };

  const result = await db.spareOrder.add(spareOrder);
  return Boolean(result);
};

/**
 * Get list of other user
 * @return {Promise<Array<TOtherUser>>}
 */
const findAllOtherUser = async () => db.otherUser.orderBy('userName').toArray();

/**
 * Get other user data for given ID
 * @param {number} otherUserId
 * @return {Promise<TOtherUser>}
 */
const findOtherUser = async (otherUserId) => db.otherUser.get(Number(otherUserId));

/**
 * Creating new spare sending to other user
 * @param {{spareTypeId: number, recipientId: number, quantity: number, description: string}} newSpareSendToOther
 * @return {Promise<boolean>}
 */
const addSparePartSendToOtherUser = async (newSpareSendToOther) => {
  const recipient = await findOtherUser(newSpareSendToOther.recipientId);

  const spareOrder = {
    id: 0,
    idPda: uuid(),
    dateCanceled: null,
    dateDelivered: null,
    dateOrder: moment().format('YYYY-MM-DD HH:mm:ss'),
    datePrepared: moment().format('YYYY-MM-DD HH:mm:ss'),
    dateRejected: null,
    dateSend: moment().format('YYYY-MM-DD HH:mm:ss'),
    destinationWarehouseId: recipient.warehouseId,
    destinationWarehouseName: recipient.userName,
    noticeCanceled: null,
    noticeDelivered: null,
    noticeOrder: newSpareSendToOther.description,
    noticePrepared: null,
    noticeRejected: null,
    ownerId: ownerId(),
    ownerName: ownerName(),
    quantityDelivered: 0,
    quantityOrder: Number(newSpareSendToOther.quantity),
    quantitySend: Number(newSpareSendToOther.quantity),
    senderId: ownerId(),
    senderName: ownerName(),
    sourceWarehouseId: ownerWarehouseId(),
    sourceWarehouseName: ownerName(),
    spareShipmentId: null,
    spareTypeId: Number(newSpareSendToOther.spareTypeId),
    status: whOrderStatuses.WH_SEND,
    statusPda: 'N',
  };

  const result = await db.spareOrder.add(spareOrder);
  return Boolean(result);
};

/**
 * Find particular spareShipment
 * @param {number} spareShipmentId
 * @return {Promise<TSpareShipment|undefined>}
 */
const findSpareShipment = async (spareShipmentId) => db.spareShipment.get(Number(spareShipmentId));

/**
 * Add populate additional data and apply filter
 * @param {TSpareOrder[]} orders
 * @param {Object} whFilter
 * @param {number} whFilter.spareGroupId
 * @param {number} whFilter.spareCategory1Id
 * @param {number} whFilter.spareCategory2Id
 * @param {string} whFilter.spareName
 * @return {Promise<TSpareOrder[]>}
 */
const populateDataAndFilterSpareOrders = async (orders, whFilter = {}) => {
  let filteredOrders = [...orders];

  await Promise.all(filteredOrders.map(async (order) => {
    [order.spareType, order.spareShipment] = await Promise.all([
      findSpareType(order.spareTypeId),
      findSpareShipment(order.spareShipmentId),
    ]);
  }));

  if (whFilter.spareGroupId && whFilter.spareGroupId !== 'ALL') {
    filteredOrders = filteredOrders.filter((order) => order.spareType.spareGroup === whFilter.spareGroupId);
  }
  if (whFilter.spareName && whFilter.spareName.trim() !== '') {
    filteredOrders = filteredOrders.filter(
      (order) => order.spareType.name.toUpperCase().includes(whFilter.spareName.trim().toUpperCase()),
    );
  }
  if (whFilter.spareCategory1Id || whFilter.spareCategory2Id) {
    const categoryId = whFilter.spareCategory2Id || whFilter.spareCategory1Id;
    let spareCategoryType = await db.spareCategoryType.where({ mainCategoryId: Number(categoryId) }).toArray();
    spareCategoryType = spareCategoryType.map((spareType) => spareType.spareTypeId);
    filteredOrders = filteredOrders.filter((order) => spareCategoryType.includes(order.spareTypeId));
  }

  return filteredOrders;
};

/**
 * Get all spares orders base on given filter
 * @param {Object} whFilter
 * @param {number} whFilter.spareGroupId
 * @param {number} whFilter.spareCategory1Id
 * @param {number} whFilter.spareCategory2Id
 * @param {string} whFilter.spareName
 * @return {Promise<TSpareOrder[]>}
 */
const findAllSpareOrderByFilter = async (whFilter = {}) => {
  const orders = await db.spareOrder
    .where({ status: whOrderStatuses.WH_ORDER })
    .filter((order) => order.quantityOrder !== 0 && order.destinationWarehouseId === ownerWarehouseId())
    .toArray();

  return populateDataAndFilterSpareOrders(orders, whFilter);
};

/**
 * Get all spare shipped to owners base on given filter
 * @param {Object} whFilter
 * @param {number} whFilter.spareGroupId
 * @param {number} whFilter.spareCategory1Id
 * @param {number} whFilter.spareCategory2Id
 * @param {string} whFilter.spareName
 * @return {Promise<TSpareOrder[]>}
 */
const findAllSpareShippedToMeByFilter = async (whFilter = {}) => {
  const orders = await db.spareOrder
    .where({ status: whOrderStatuses.WH_SEND })
    .filter((order) => order.destinationWarehouseId === ownerWarehouseId())
    .toArray();

  return populateDataAndFilterSpareOrders(orders, whFilter);
};

/**
 * Get all spare shipped from owners base on given filter
 * @param {Object} whFilter
 * @param {number} whFilter.spareGroupId
 * @param {number} whFilter.spareCategory1Id
 * @param {number} whFilter.spareCategory2Id
 * @param {string} whFilter.spareName
 * @return {Promise<TSpareOrder[]>}
 */
const findAllSpareShippedByMeByFilter = async (whFilter = {}) => {
  const orders = await db.spareOrder
    .where({ status: whOrderStatuses.WH_SEND })
    .filter((order) => order.quantityOrder !== 0 && order.sourceWarehouseId === ownerWarehouseId())
    .toArray();

  return populateDataAndFilterSpareOrders(orders, whFilter);
};

/**
 * Get spareOrder for given ID
 * @param {number} spareOrderId
 * @param {string} spareOrderIdPda
 * @return {Promise<TSpareOrder|undefined>}
 */
const findSpareOrder = async (spareOrderId, spareOrderIdPda) => {
  const spareOrder = await db.spareOrder.get([Number(spareOrderId), spareOrderIdPda]);

  if (!spareOrder) {
    return undefined;
  }

  spareOrder.spareType = await findSpareType(spareOrder.spareTypeId);
  spareOrder.spareShipment = await findSpareShipment(spareOrder.spareShipmentId);

  return spareOrder;
};

/**
 * Delete not yet synchronized spareOrder for given ID
 * @param {number} spareOrderId
 * @param {string} spareOrderIdPda
 * @return {Promise<boolean>}
 * @throws {Error}
 */
const deleteSpareOrder = async (spareOrderId, spareOrderIdPda) => {
  const spareOrder = await findSpareOrder(spareOrderId, spareOrderIdPda);
  if (!spareOrder) {
    return true;
  }

  if (spareOrder.statusPda !== 'N') {
    throw new Error('An order has been synchronized cannot be deleted.');
  }

  await db.spareOrder.delete([Number(spareOrderId), spareOrderIdPda]);

  return true;
};

/**
 * Delete not yet synchronized spareOrder for given ID
 * @param {number} spareOrderId
 * @param {string} spareOrderIdPda
 * @return {Promise<boolean>}
 * @throws {Error}
 */
const cancelSpareOrder = async (spareOrderId, spareOrderIdPda) => {
  const spareOrder = await findSpareOrder(spareOrderId, spareOrderIdPda);
  if (!spareOrder) {
    return false;
  }

  if (spareOrder.status !== 'ORDER') {
    throw new Error('An order has been processed cannot be canceled.');
  }

  await db.spareOrder.update(
    [Number(spareOrderId), spareOrderIdPda],
    {
      status: whOrderStatuses.WH_CANCELED,
      dateCanceled: moment().format('YYYY-MM-DD HH:mm:ss'),
      statusPda: 'M',
    },
  );

  return true;
};

/**
 * Recept spare, update spare order, make shift
 * @param {Object} receptionData
 * @param {number} receptionData.spareOrderId
 * @param {string} receptionData.spareOrderIdPda
 * @param {number} receptionData.quantityDelivered
 * @param {string} receptionData.noticeDelivered
 * @return {Promise<boolean>}
 */
const receptSpareOrder = async (receptionData) => db.transaction(
  'rw',
  db.spareOrder,
  db.spareType,
  db.spareShift,
  db.spareShipment,
  async () => {
    const { spareOrderId, spareOrderIdPda, quantityDelivered, noticeDelivered } = receptionData;
    const spareOrder = await findSpareOrder(spareOrderId, spareOrderIdPda);

    if (!spareOrder) {
      return false;
    }

    if (spareOrder.status !== whOrderStatuses.WH_SEND) {
      throw new Error('An order has wrong status cannot be recept.');
    }

    await db.spareOrder.update(
      [Number(spareOrderId), spareOrderIdPda],
      {
        status: whOrderStatuses.WH_DELIVERED,
        quantityDelivered: Number(quantityDelivered),
        noticeDelivered,
        dateDelivered: moment().format('YYYY-MM-DD HH:mm:ss'),
        statusPda: 'M',
      },
    );

    const shiftType = spareOrder.sourceWarehouseId !== warehouseIds.WH_MC_ID
    && spareOrder.destinationWarehouseId === ownerWarehouseId()
      ? warehouseShiftTypes.WH_INTERNAL
      : warehouseShiftTypes.WH_SAP;

    const spareShift = {
      id: 0,
      idPda: uuid(),
      ownerId: spareOrder.senderId,
      ownerName: spareOrder.senderName,
      recipientId: ownerId(),
      recipientName: ownerName(),
      sourceWarehouseId: spareOrder.sourceWarehouseId,
      sourceWarehouseName: spareOrder.sourceWarehouseName,
      destinationWarehouseId: spareOrder.destinationWarehouseId,
      destinationWarehouseName: spareOrder.destinationWarehouseName,
      status: whOrderStatuses.WH_DELIVERED,
      shiftType,
      dateSend: spareOrder.dateSend,
      dateDelivered: moment().format('YYYY-MM-DD HH:mm:ss'),
      spareTypeId: spareOrder.spareTypeId,
      quantity: Number(quantityDelivered),
      price: spareOrder.spareType.priceAverage,
      spareOrderId: spareOrder.id,
      spareOrderIdPda: spareOrder.idPda,
      activitySpareId: 0,
      activitySpareIdPda: null,
      clientId: 0,
      posId: 0,
      statusPda: 'N',
    };

    const result = await db.spareShift.add(spareShift);

    return true;
  },
);

/**
 * List additional module private methods, exposed only for unit test
 */
export const WarehousePrivate = {
  getStockQuantityBySpareId,
  getOrderQuantityBySpareId,
  ownerId,
  ownerName,
  ownerWarehouseId,
  calcQuantity,
  addSpareShiftFromSpareActivity,
  findSpareShipment,
};

export const Warehouse = {
  findAllSpareType,
  findSpareType,
  findAllSpareShift,
  findAllSpareWithStock,
  findSpareWithStock,
  findAllSpareWithStockByFilter,
  findAllSpareOrderByFilter,
  findAllSpareCategory,
  addActivitySpare,
  findActivitySpares,
  deleteActivitySpare,
  deleteActivity,
  addSparePartOrder,
  findAllOtherUser,
  findOtherUser,
  addSparePartSendToOtherUser,
  findAllSpareShippedToMeByFilter,
  findAllSpareShippedByMeByFilter,
  findSpareOrder,
  deleteSpareOrder,
  cancelSpareOrder,
  receptSpareOrder,
};
