import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import {
  Search as IconSearch,
} from '@mui/icons-material';
import {
  Box,
  IconButton,
} from '@mui/material';
import { DataGrid, plPL, GridColDef } from '@mui/x-data-grid';

import { useLiveQuery } from 'dexie-react-hooks';
import { DS } from '../_services';
import { history } from '../_helpers';
import TicketStatusChip from '../TicketList/TicketStatusChip';
import TicketDetailDialog from '../TicketList/TicketDetailDialog';

function TicketTab(props) {
  const { visitSession } = props;
  const { clientId } = useParams();
  const [detailDialog, setDetailDialog] = useState({ open: false });

  /**
   * @type {GridColDef[]}
   */
  const columns = [
    {
      field: 'ticketNo',
      headerName: '',
      sortable: false,
      filterable: false,
      width: 50,
      renderCell: (params) => (
        <IconButton
          size="small"
          color="info"
          onClick={(event) => viewDetailHandler(event, params.row.id, params.row.idPda)}
        >
          <IconSearch />
        </IconButton>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      valueFormatter: (params) => DS.Ticket.filterTicketStatus(params.value),
      renderCell: (params) => (
        <TicketStatusChip
          ticketStatus={params.value}
          ticketId={params.row.id}
          ticketIdPda={params.row.idPda}
          clientId={parseInt(clientId, 10)}
          enabled={visitSession.visitInProgress}
          onClick={openTicketHandler}
        />
      ),
    },
    { field: 'ticketTypeName', headerName: 'Typ', width: 110 },
    {
      field: 'priority',
      headerName: 'Priorytet',
      width: 100,
      valueGetter: (param) => DS.Ticket.filterTicketPriority(param.row.priorityLevel),
    },
    { field: 'pos.posType', headerName: 'Pos', width: 300, valueGetter: (param) => param.row.pos.posTypeName },
    { field: 'pos.barcode', headerName: 'Barcode', width: 120, valueGetter: (param) => param.row.pos.barcode },
  ];

  /**
   * @type {TTicket[]}
   */
  const rows = useLiveQuery(async () => DS.Ticket.findAllTicketForClient(parseInt(clientId, 10)));

  /**
   * @param {Event} event
   * @param {number} ticketId
   * @param {string} ticketIdPda
   */
  const viewDetailHandler = (event, ticketId, ticketIdPda) => {
    event.defaultMuiPrevented = true;
    setDetailDialog((s) => ({
      ...s,
      open: true,
      ticketId,
      ticketIdPda,
    }));
  };

  const dialogCloseHandler = () => {
    setDetailDialog((s) => ({ open: false }));
  };

  const openTicketHandler = async (event, ticketId, ticketIdPda, clId) => {
    const activeVisit = await DS.Visit.getOrCreateVisitForTicket(
      clientId,
      ticketId,
      ticketIdPda,
      visitSession.visitCarOdometer,
    );
    history.navigate(`/ticketResolve/${ticketId}/${ticketIdPda}/${activeVisit.id}/${activeVisit.idPda}`);
  };

  return (
    <Box component="div" sx={{ display: 'flex', height: 'calc(100vh - 140px)' }}>
      <Box component="div" sx={{ flexGrow: 1 }}>
        <DataGrid
          rows={rows || []}
          columns={columns}
          getRowId={(row) => `${row.id}-${row.idPda}`}
          sx={{ mt: 1 }}
          autoPageSize
          hideFooterSelectedRowCount
          pageSize={25}
          rowsPerPageOptions={[5, 10, 25]}
          density="compact"
          localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
        />
        {detailDialog.open && (
          <TicketDetailDialog
            onAccept={dialogCloseHandler}
            open={detailDialog.open}
            ticketId={detailDialog.ticketId}
            ticketIdPda={detailDialog.ticketIdPda}
          />
        )}
      </Box>
    </Box>
  );
}

TicketTab.propTypes = {
  visitSession: PropTypes.shape(Object).isRequired,
};

TicketTab.defaultProps = {};

export default TicketTab;
