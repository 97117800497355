import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  TextField,
} from '@mui/material';

import { DS } from '../_services';
import { ContentDialog } from '../Common';

/**
 * @param {Object} props
 * @param {TTicket} props.ticket
 * @returns {JSX.Element}
 * @constructor
 */
const NoticeTab = forwardRef((props, ref) => {
  const { ticket } = props;
  const [notice, setNotice] = useState({
    saving: false,
    submitted: false,
    comment: '',
    originalComment: '',
    needToBeSave: false,
  });

  useImperativeHandle(ref, () => ({
    hasNotSavedData: () => {
      if (notice.comment !== notice.originalComment) {
        setNotice((s) => ({ ...s, needToBeSave: true }));
        return true;
      }
      return false;
    },
  }));

  useEffect(() => {
    const fetchLastTicketLog = async () => {
      const tl = await DS.Ticket.findLastUserLogForTicket(ticket.id, ticket.idPda);

      if (tl) {
        setNotice((s) => ({ ...s, comment: tl.notice, originalComment: tl.notice }));
      }
    };
    fetchLastTicketLog();
  }, [ticket.id, ticket.idPda]);

  const handleInputChange = (field, value) => {
    setNotice((s) => ({ ...s, [field]: value }));
  };

  /**
   * @param {Event} e
   */
  const handleAddNotice = async (e) => {
    e.preventDefault();
    setNotice((s) => ({ ...s, saving: true, submitted: true }));
    if (!notice.comment) {
      setNotice((s) => ({ ...s, saving: false }));
      return;
    }

    await DS.Ticket.createTicketLog(ticket.id, ticket.idPda, notice.comment);
    setNotice((s) => ({ ...s, saving: false, error: false, originalComment: s.comment }));
  };

  return (
    <Grid item xs={10} sm={8} md={6} lg={5} xl={4}>
      <form
        noValidate
        name="form"
        onSubmit={handleAddNotice}
      >
        <FormControl margin="normal" required fullWidth sx={{ mt: 0 }}>
          <TextField
            rows={4}
            value={notice.comment}
            name="comment"
            label="Dodatkowe uwagi do zgłoszenia:"
            margin="normal"
            variant="outlined"
            type="text"
            error={notice.submitted && !notice.comment}
            multiline
            onChange={(event) => handleInputChange(event.target.name, event.target.value)}
            data-testid="input-comment"
          />
          {notice.submitted && !notice.comment && (
            <FormHelperText error data-testid="label-error-empty-comment">
              Podanie treści jest wymagane
            </FormHelperText>
          )}
        </FormControl>
        {notice.error && (
          <FormLabel color="error" error data-testid="label-error-submit">
            Wystąpił problem z zapisem informacji
          </FormLabel>
        )}
        <div style={{ textAlign: 'center' }}>
          <Button
            color="secondary"
            type="button"
            variant="contained"
            sx={{ minWidth: '14em', mx: 2 }}
            disabled={notice.comment === notice.originalComment}
            data-testid="button-reset"
            onClick={() => setNotice((s) => ({ ...s, comment: s.originalComment }))}
          >
            Przywróć
          </Button>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            sx={{ minWidth: '14em', mx: 2 }}
            disabled={notice.saving}
            data-testid="button-submit"
          >
            {notice.originalComment === '' ? 'Dodaj' : 'Aktualizuj'}
          </Button>
        </div>
      </form>
      {notice.needToBeSave && (
        <ContentDialog
          onAccept={() => setNotice((s) => ({ ...s, needToBeSave: false }))}
          open={notice.needToBeSave}
          onDecline={() => true}
          dialogTitle="Nie zapisano danych"
          acceptLabel="OK"
          declineLabel=""
          maxWidth="xs"
          dialogType="alert"
        >
          <Box sx={{ p: 1 }}>
            Uwagi do zgłoszenia nie zostały zapisane, proszę zapisać lub przywrócić poprzednią wersję.
          </Box>
        </ContentDialog>
      )}
    </Grid>
  );
});

NoticeTab.propTypes = {
  ticket: PropTypes.shape(Object).isRequired,
};

NoticeTab.defaultProps = {};

export default NoticeTab;
