import { Ticket } from './ticket.data.service';
import { VisitDay } from './visitDay.data.service';
import { Visit } from './visit.data.service';
import { Pos } from './pos.data.service';
import { Client } from './client.data.service';
import { TicketType } from './ticketType.data.service';
import { TicketResolveReason } from './ticketResolveReason.data.service';
import { Activity } from './activity.data.service';
import { Warehouse } from './warehouse.data.service';

export const DS = {
  Ticket,
  VisitDay,
  Visit,
  Pos,
  Client,
  TicketType,
  TicketResolveReason,
  Activity,
  Warehouse,
};
