/**
 * @type {{
 * NOTICE: number,
 * REFURBISH: number,
 * INVENTORY: number,
 * CANCEL: number,
 * RESOLVE: number,
 * VALVE: number,
 * ACTIVITY: number
 * }}
 */
export const trTab = {
  ACTIVITY: 0,
  NOTICE: 1,
  RESOLVE: 2,
  CANCEL: 3,
  VALVE: 4,
  INVENTORY: 5,
  REFURBISH: 6,
};
