import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  Grid,
  Paper,
} from '@mui/material';

import { DS } from '../_services';
import { history } from '../_helpers';
import { ContentDialog } from '../Common';
import ResolveStandardForm from './ResolveStandardForm';
import ResolveSurveyForm from './ResolveSurveyForm';

/**
 * @typedef {Object} TResolveTicket
 * @property {number} visitId
 * @property {string} visitIdPda
 * @property {boolean} saving
 * @property {string?} error
 * @property {boolean} submitted
 * @property {boolean} disabled
 * @property {boolean} sanit
 * @property {boolean} insp
 * @property {number} incorrectUsePercent
 * @property {string} posLocation
 * @property {boolean} improperTicket
 * @property {number} improperTicketReasonId
 * @property {TTicketReport[]} ticketReports
 */

/**
 * @param {Object} props
 * @param {Object} ref
 * @param {TTicket} props.ticket
 * @returns {JSX.Element}
 * @constructor
 */
function ResolveTab(props) {
  const { ticket } = props;
  const { visitId, visitIdPda } = useParams();
  const [state, setState] = useState({
    ticketNotHaveSpare: false,
    ticketHaveValveSpare: false,
  });
  const [dictionaries, setDictionaries] = useState({
    /** @type TImproperTicketReason[] */ improperTicketReasons: [],
    /** @type {id: string, name: string}[] */ posLocations: [],
  });

  const [resolveTicket, setResolveTicket] = useState(/** @type {TResolveTicket} */ {
    visitId: Number(visitId),
    visitIdPda,
    saving: false,
    error: null,
    submitted: false,
    sanit: false,
    insp: false,
    incorrectUsePercent: 100,
    posLocation: 'ROOM',
    improperTicket: false,
    improperTicketReasonId: '',
    ticketReports: [],
  });

  /**
   * Fetch previous answer if exists and fill actual form
   */
  const fetchSessionResolveReport = useCallback(() => {
    const previousAnswer = JSON.parse(sessionStorage.getItem('resolveReport') || '{}');
    if (previousAnswer && previousAnswer.id === `${ticket.id}^${ticket.idPda}^${visitId}^${visitIdPda}`) {
      delete (previousAnswer.id);
      setResolveTicket((s) => ({ ...s, ...previousAnswer }));
    }
  }, [ticket.id, ticket.idPda, visitId, visitIdPda]);

  useEffect(() => {
    const fetchData = async () => {
      const ticketHasSpare = await DS.Ticket.ticketHasSpare(ticket.id, ticket.idPda);
      const ticketHaveValveSpare = await DS.Ticket.ticketHasValveSpare(ticket.id, ticket.idPda);
      setState((s) => ({ ...s, ticketNotHaveSpare: !ticketHasSpare, ticketHaveValveSpare }));

      const improperTicketReasons = await DS.Ticket.findAllImproperTicketReason();
      const posLocations = [
        { id: 'ROOM', name: 'Sala' },
        { id: 'KITCHEN', name: 'Kuchnia' },
      ];

      setDictionaries((s) => ({ ...s, improperTicketReasons, posLocations }));
      fetchSessionResolveReport();
    };
    fetchData();
  }, [fetchSessionResolveReport, ticket]);

  /**
   * @param {Object} updatedResolveReport
   * Save actual resolve ticker report answer
   */
  const saveSessionResolveReport = (updatedResolveReport) => {
    const sessionResolveReport = {
      id: `${ticket.id}^${ticket.idPda}^${visitId}^${visitIdPda}`,
      sanit: updatedResolveReport.sanit,
      insp: updatedResolveReport.insp,
      incorrectUsePercent: updatedResolveReport.incorrectUsePercent,
      posLocation: updatedResolveReport.posLocation,
      improperTicket: updatedResolveReport.improperTicket,
      improperTicketReasonId: updatedResolveReport.improperTicketReasonId,
    };

    sessionStorage.setItem('resolveReport', JSON.stringify(sessionResolveReport));
  };

  const resolveFormChangeHandler = (propertyName, newValue) => {
    const updatedResolveReport = { ...resolveTicket, [propertyName]: newValue };

    saveSessionResolveReport(updatedResolveReport);
    setResolveTicket(updatedResolveReport);
  };

  /**
   * Validate resolve form
   * @return {boolean}
   */
  const validateStandardForm = () => {
    if (resolveTicket.improperTicket && !resolveTicket.improperTicketReasonId) {
      setResolveTicket((s) => ({ ...s, error: 'Nie została wskazana przyczyna niepoprawności zgłoszenia.' }));
      return false;
    }

    return true;
  };

  const resolveTicketHandler = async () => {
    if (!validateStandardForm()) {
      return;
    }

    await DS.Ticket.resolveTicket(ticket.id, ticket.idPda, resolveTicket);
    sessionStorage.removeItem('resolveReport');
    sessionStorage.removeItem('surveyReport');
    history.navigate(`/visit/${ticket.id}/${ticket.idPda}/${ticket.clientId}`);
  };

  const surveyFormChangeHandler = (surveyReport) => {
    setResolveTicket((s) => ({ ...s, ticketReports: surveyReport }));
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-around"
      alignItems="flex-start"
      columnSpacing={{ xs: 1, lg: 2 }}
      rowSpacing={{ xs: 1, md: 2 }}
      sx={{ py: 1 }}
    >
      <Grid item xs={12} sm={12} md={6}>
        <Paper sx={{ p: 1, pb: 2, mt: 1 }}>
          <ResolveStandardForm
            ticket={ticket}
            resolveTicket={resolveTicket}
            onChange={resolveFormChangeHandler}
            dictionary={dictionaries}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Paper sx={{ p: 1, pt: 0, pb: 2, mt: 1 }}>
          <ResolveSurveyForm
            ticket={ticket}
            resolveTicket={resolveTicket}
            onChange={surveyFormChangeHandler}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} sx={{ mt: 1 }}>
        {resolveTicket.error && (
          <Paper sx={{ p: 1, mb: 1, bgcolor: 'error.main', color: 'error.contrastText' }}>
            {resolveTicket.error}
          </Paper>
        )}
        <div style={{ textAlign: 'center' }}>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            sx={{ minWidth: '14rem', mx: 2 }}
            disabled={resolveTicket.saving}
            data-testid="button-submit"
            onClick={resolveTicketHandler}
          >
            Rozwiąż zgłoszenie
          </Button>
        </div>
      </Grid>
      {state.ticketNotHaveSpare && (
        <ContentDialog
          onAccept={() => setState((s) => ({ ...s, ticketNotHaveSpare: false }))}
          open={state.ticketNotHaveSpare}
          onDecline={() => true}
          dialogTitle="Brak użytych części"
          acceptLabel="OK"
          declineLabel=""
          maxWidth="xs"
          dialogType="alert"
        >
          <Box sx={{ p: 1 }}>
            Czy na pewno chcesz rozwiązać zgłoszenie, nie zostały zaraportowane żadne części.
          </Box>
        </ContentDialog>
      )}
      {state.ticketHaveValveSpare && (
        <ContentDialog
          onAccept={() => setState((s) => ({ ...s, ticketHaveValveSpare: false }))}
          open={state.ticketHaveValveSpare}
          onDecline={() => true}
          dialogTitle="Zawory specjalne"
          acceptLabel="OK"
          declineLabel=""
          maxWidth="xs"
          dialogType="alert"
        >
          <Box sx={{ p: 1 }}>
            Czy na pewno chcesz rozwiązać zgłoszenie, użyte zostały zawory,
            zweryfikuj ich obecność w zakładce &bdquo;Zawory&rdquo;.
          </Box>
        </ContentDialog>
      )}
    </Grid>
  );
}

ResolveTab.propTypes = {
  ticket: PropTypes.shape(Object).isRequired,
};

ResolveTab.defaultProps = {};

export default ResolveTab;
