import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useLiveQuery } from 'dexie-react-hooks';

import {
  Box, IconButton,
  Typography,
} from '@mui/material';
import { Search as IconSearch } from '@mui/icons-material';
import { DataGrid, plPL, GridColDef } from '@mui/x-data-grid';

import { DS } from '../_services';
import { history } from '../_helpers';
import TicketStatusChip from '../TicketList/TicketStatusChip';
import VisitForm from './VisitForm';
import TicketDetailDialog from '../TicketList/TicketDetailDialog';

/**
 * @param {Object} props
 * @param {TClient} props.client
 * @param {{
 * visitInProgress: boolean,
 * visitClientId: ?number,
 * visitCarOdometer: number,
 * visitStartDate: ?number
 * }} props.visitSession
 * @param {?Function} props.specAction
 * @returns {JSX.Element}
 * @constructor
 */
function VisitTab(props) {
  const { client, visitSession, specAction } = props;
  const { clientId } = useParams();
  const [detailDialog, setDetailDialog] = useState({ open: false });

  /**
   *
   * @type {GridColDef[]}
   */
  const columns = [
    {
      field: 'id',
      headerName: '',
      sortable: false,
      filterable: false,
      width: 50,
      renderCell: (params) => (
        <IconButton
          size="small"
          color="info"
          onClick={(event) => viewDetailHandler(event, params.row.id, params.row.idPda)}
        >
          <IconSearch />
        </IconButton>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      valueFormatter: (params) => DS.Ticket.filterTicketStatus(params.value),
      renderCell: (params) => (
        <TicketStatusChip
          ticketStatus={params.value}
          ticketId={params.row.id}
          ticketIdPda={params.row.idPda}
          clientId={parseInt(clientId, 10)}
          enabled={visitSession.visitInProgress}
          onClick={openTicketHandler}
        />
      ),
    },
    {
      field: 'ticketNo',
      headerName: 'Numer zgłoszenia',
      width: 140,
      valueGetter: (param) => DS.Ticket.filterTicketPriority(param.row.ticketNo),
    },
    { field: 'ticketTypeName', headerName: 'Typ', width: 110 },
    { field: 'pos.posTypeName', headerName: 'Pos', width: 300, valueGetter: (param) => param.row.pos.posTypeName },
  ];

  /**
   * @type {TTicket[]}
   */
  const rows = useLiveQuery(async () => DS.Visit.findAllTicketForActiveVisit(clientId));

  /**
   * @param {Event} event
   * @param {number} ticketId
   * @param {string} ticketIdPda
   */
  const viewDetailHandler = (event, ticketId, ticketIdPda) => {
    event.defaultMuiPrevented = true;
    setDetailDialog((s) => ({
      ...s,
      open: true,
      ticketId,
      ticketIdPda,
    }));
  };

  const dialogCloseHandler = () => {
    setDetailDialog((s) => ({ open: false }));
  };

  /**
   * @param {Event} event
   * @param {number} ticketId
   * @param {string} ticketIdPda
   * @param {number} clId
   * @returns {Promise<void>}
   */
  const openTicketHandler = async (event, ticketId, ticketIdPda, clId) => {
    const activeVisit = await DS.Visit.getOrCreateVisitForTicket(
      clientId,
      ticketId,
      ticketIdPda,
      visitSession.visitCarOdometer,
    );
    history.navigate(`/ticketResolve/${ticketId}/${ticketIdPda}/${activeVisit.id}/${activeVisit.idPda}`);
  };

  return (
    <Box component="div" sx={{ mt: 1 }}>
      <Typography
        color="primary"
        fontSize="large"
        align="right"
      >
        {client.name}
        <br />
        {`${client.city}, ${client.street}`}
      </Typography>
      <VisitForm
        sessionVisit={DS.Visit.getVisitFromSession(clientId)}
        onChange={() => {
          specAction(0);
        }}
      />
      <Box component="div" sx={{ display: 'flex', height: 'calc(100vh - 300px)' }}>
        <Box component="div" sx={{ flexGrow: 1 }}>
          <DataGrid
            rows={rows || []}
            columns={columns}
            getRowId={(row) => `${row.id}-${row.idPda}`}
            sx={{ mt: 1 }}
            autoPageSize
            hideFooterSelectedRowCount
            pageSize={25}
            rowsPerPageOptions={[5, 10, 25]}
            density="compact"
            localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
          />
        </Box>
      </Box>
      {detailDialog.open && (
        <TicketDetailDialog
          onAccept={dialogCloseHandler}
          open={detailDialog.open}
          ticketId={detailDialog.ticketId}
          ticketIdPda={detailDialog.ticketIdPda}
        />
      )}
    </Box>
  );
}

VisitTab.propTypes = {
  client: PropTypes.shape(Object).isRequired,
  visitSession: PropTypes.shape(Object).isRequired,
  specAction: PropTypes.func,
};

VisitTab.defaultProps = {
  specAction: null,
};

export default VisitTab;
