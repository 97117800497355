import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  Grid,
  Tabs,
} from '@mui/material';
import { DS } from '../_services';
import { TabPanel, TabsTab } from '../Common';
import VisitTab from './VisitTab';
import TicketTab from './TicketTab';
import DeviceTab from './DeviceTab';

function Visit(props) {
  const { clientId } = useParams();
  const [state, setState] = useState({
    tabIndex: 0,
  });
  const [client, setClient] = useState({});
  const visitSession = DS.Visit.getVisitFromSession(clientId);

  useEffect(
    () => {
      const fetchClient = async () => {
        const c = await DS.Client.findClient(parseInt(clientId, 10));
        setClient(c);
      };

      fetchClient();
    },
    [clientId],
  );

  const handleTabChange = (event, newValue) => {
    setState((s) => ({ ...s, tabIndex: newValue }));
  };

  const changeTabAction = (destinationTabIndex) => {
    setState((s) => ({ ...s, tabIndex: destinationTabIndex }));
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="normal"
      alignItems="flex-start"
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      rowSpacing={{ xs: 1 }}
    >
      <Grid item sx={{ textAlign: 'left' }} xs={12}>
        <Tabs value={state.tabIndex} onChange={handleTabChange} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabsTab index={0} label="Wizyta" idPrefix="visit" />
          <TabsTab index={1} label="Zgłoszenia" idPrefix="visit" />
          <TabsTab index={2} label="Urządzenia" idPrefix="visit" />
        </Tabs>
        <TabPanel value={state.tabIndex} index={0} idPrefix="ticket-detail">
          <VisitTab visitSession={visitSession} client={client} specAction={changeTabAction} />
        </TabPanel>
        <TabPanel value={state.tabIndex} index={1} idPrefix="ticket-detail">
          <TicketTab visitSession={visitSession} />
        </TabPanel>
        <TabPanel value={state.tabIndex} index={2} idPrefix="ticket-detail">
          <DeviceTab visitSession={visitSession} specAction={changeTabAction} />
        </TabPanel>
      </Grid>
    </Grid>
  );
}

Visit.propTypes = {};

Visit.defaultProps = {};

export default Visit;
