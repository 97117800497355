import { history, authHeader } from '../_helpers';

/**
 *
 * @param response {{}}
 * @returns {*}
 */
function handleResponse(response) {
  return response.text()
    .then((text) => {
      if (!response.ok) {
        if (response.status === 401) {
          // auto logout if 401 response returned from api
          history.push('/login');
        }

        return Promise.reject(Error(`${response.status}: ${response.statusText}`));
      }

      try {
        return Promise.resolve(text && JSON.parse(text));
      } catch (e) {
        return Promise.reject(e);
      }
    })
    .catch((err) => Promise.reject(err));
}

export const apiService = {
  /**
   * @param apiMethod {string}
   * @param secured {boolean}
   * @returns {Promise<*>}
   */
  get: (apiMethod, secured = true) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        ...authHeader(secured),
      },
    };

    return fetch(`${process.env.REACT_APP_API_URL}${apiMethod}`, requestOptions)
      .then(handleResponse)
      .catch((err) => Promise.reject(err));
  },

  /**
   * @param apiMethod {string}
   * @param body {*|null}
   * @param secured {boolean}
   * @returns {Promise<*>}
   */
  post: (apiMethod, body = null, secured = true) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        ...authHeader(secured),
        'Content-Type': 'application/json',
        credentials: 'include',
      },
      body: JSON.stringify(body),
    };

    return fetch(`${process.env.REACT_APP_API_URL}${apiMethod}`, requestOptions)
      .then(handleResponse)
      .catch((err) => Promise.reject(err));
  },

  /**
   * @param apiMethod {string}
   * @param body {*|null}
   * @param secured {boolean}
   * @returns {Promise<*>}
   */
  put: (apiMethod, body = null, secured = true) => {
    const requestOptions = {
      method: 'PUT',
      headers: {
        ...authHeader(secured),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    };

    return fetch(`${process.env.REACT_APP_API_URL}${apiMethod}`, requestOptions)
      .then(handleResponse)
      .catch((err) => Promise.reject(err));
  },

  /**
   * @param apiMethod {string}
   * @param secured {boolean}
   * @returns {Promise<*>}
   */
  delete: (apiMethod, secured = true) => {
    const requestOptions = {
      method: 'DELETE',
      headers: {
        ...authHeader(secured),
      },
    };

    return fetch(`${process.env.REACT_APP_API_URL}${apiMethod}`, requestOptions)
      .then(handleResponse)
      .catch((err) => Promise.reject(err));
  },
};
