import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid, InputLabel, MenuItem, Select,
  TextField,
} from '@mui/material';

import { DS } from '../_services';
import { history } from '../_helpers';
import { ContentDialog } from '../Common';

/**
 * @param {Object} props
 * @param {Object} ref
 * @param {TTicket} props.ticket
 * @returns {JSX.Element}
 * @constructor
 */
const CancelTab = forwardRef((props, ref) => {
  const { ticket } = props;
  const [cancelTicket, setCancelTicket] = useState({
    saving: false,
    submitted: false,
    comment: '',
    ticketResolveReasonId: '',
    originalComment: '',
    needToBeSave: false,
  });
  const [availableTicketResolveReason, setAvailableTicketResolveReason] = useState([]);

  useImperativeHandle(ref, () => ({
    hasNotSavedData: () => {
      if (cancelTicket.comment !== cancelTicket.originalComment || cancelTicket.ticketResolveReasonId !== '') {
        setCancelTicket((s) => ({ ...s, needToBeSave: true }));
        return true;
      }
      return false;
    },
  }));

  useEffect(() => {
    const fetchData = async () => {
      const resolveReasonList = await DS.TicketResolveReason.findAllTicketResolveReason();

      if (resolveReasonList) {
        setAvailableTicketResolveReason(resolveReasonList);
      }
    };
    fetchData();
  }, []);

  const handleInputChange = (field, value) => {
    setCancelTicket((s) => ({ ...s, [field]: value }));
  };

  /**
   * @param {Event} e
   */
  const handleCancelTicket = async (e) => {
    e.preventDefault();
    setCancelTicket((s) => ({ ...s, saving: true, submitted: true }));
    if (!cancelTicket.comment || !cancelTicket.ticketResolveReasonId) {
      setCancelTicket((s) => ({ ...s, saving: false }));
      return;
    }

    await DS.Ticket.cancelTicket(ticket.id, ticket.idPda, cancelTicket.ticketResolveReasonId, cancelTicket.comment);
    history.navigate(`/visit/${ticket.id}/${ticket.idPda}/${ticket.clientId}`);
  };

  return (
    <Grid item xs={10} sm={8} md={6} lg={5} xl={4}>
      <form
        noValidate
        name="form"
        onSubmit={handleCancelTicket}
      >
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel
            id="ticket-resolve-reason-label"
            htmlFor="ticket-resolve-reason"
          >
            Wskaż przyczynę anulowania:
          </InputLabel>
          <Select
            labelId="ticket-resolve-reason-label"
            label="Wskaż przyczynę anulowania:"
            id="ticket-resolve-reason"
            name="ticketResolveReasonId"
            value={cancelTicket.ticketResolveReasonId}
            error={cancelTicket.submitted && !cancelTicket.ticketResolveReasonId}
            onChange={(event) => handleInputChange(event.target.name, event.target.value)}
          >
            {availableTicketResolveReason.map((tt) => (
              <MenuItem key={tt.id} value={tt.id}>{tt.name}</MenuItem>
            ))}
          </Select>
          {cancelTicket.submitted && !cancelTicket.ticketResolveReasonId && (
            <FormHelperText error data-testid="label-error-empty-ticketResolveTypeId">
              Podanie przyczynę jest wymagane
            </FormHelperText>
          )}
        </FormControl>
        <FormControl margin="normal" required fullWidth sx={{ mt: 0 }}>
          <TextField
            rows={4}
            value={cancelTicket.comment}
            name="comment"
            label="Opisz przyczynę anulowania:"
            margin="normal"
            variant="outlined"
            type="text"
            error={cancelTicket.submitted && !cancelTicket.comment}
            multiline
            onChange={(event) => handleInputChange(event.target.name, event.target.value)}
            data-testid="input-comment"
          />
          {cancelTicket.submitted && !cancelTicket.comment && (
            <FormHelperText error data-testid="label-error-empty-comment">
              Podanie treści jest wymagane
            </FormHelperText>
          )}
        </FormControl>
        {cancelTicket.error && (
          <FormLabel color="error" error data-testid="label-error-submit">
            Wystąpił problem z zapisem informacji
          </FormLabel>
        )}
        <div style={{ textAlign: 'center' }}>
          <Button
            color="secondary"
            type="button"
            variant="contained"
            sx={{ minWidth: '14em', mx: 2 }}
            disabled={
              cancelTicket.comment === cancelTicket.originalComment && cancelTicket.ticketResolveReasonId === ''
            }
            data-testid="button-reset"
            onClick={() => setCancelTicket((s) => ({ ...s, comment: s.originalComment, ticketResolveReasonId: '' }))}
          >
            Przywróć
          </Button>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            sx={{ minWidth: '14em', mx: 2 }}
            disabled={cancelTicket.saving}
            data-testid="button-submit"
          >
            Anuluj zgłoszenie
          </Button>
        </div>
      </form>
      {cancelTicket.needToBeSave && (
        <ContentDialog
          onAccept={() => setCancelTicket((s) => ({ ...s, needToBeSave: false }))}
          open={cancelTicket.needToBeSave}
          onDecline={() => true}
          dialogTitle="Nie zapisano danych"
          acceptLabel="OK"
          declineLabel=""
          maxWidth="xs"
          dialogType="alert"
        >
          <Box sx={{ p: 1 }}>
            Anulowanie zgłoszenia nie zostało zapisane, proszę zapisać lub przywrócić poprzednią wersję.
          </Box>
        </ContentDialog>
      )}
    </Grid>
  );
});

CancelTab.propTypes = {
  ticket: PropTypes.shape(Object).isRequired,
};

CancelTab.defaultProps = {};

export default CancelTab;
