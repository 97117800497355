// eslint-disable-next-line no-unused-vars
import { TKey } from 'dexie';
import moment from 'moment';
import { db } from './db';
import { uuid } from '../_helpers/uuid';
import { Ticket } from './ticket.data.service';

/**
 * Try to find active visit for given client and ticket, if not find then create it
 * @param clientId
 * @param ticketId
 * @param ticketIdPda
 * @param carOdometer
 * @returns {Promise<TVisit>}
 */
const getOrCreateVisitForTicket = async (clientId, ticketId, ticketIdPda, carOdometer) => {
  let activeVisit;
  await db.transaction('rw', db.visit, db.visitTicket, async () => {
    activeVisit = await findActiveVisitForClient(clientId);

    if (activeVisit === undefined) {
      activeVisit = await createVisit(clientId, carOdometer);
    }

    await db.visit
      .where({ id: activeVisit.id, idPda: activeVisit.idPda })
      .modify({
        carOdometer: Number(carOdometer),
      });

    const visitTicket = await db.visitTicket.get({
      visitId: activeVisit.id,
      visitIdPda: activeVisit.idPda,
      ticketId: Number(ticketId),
      ticketIdPda,
    });

    if (visitTicket === undefined) {
      await db.visitTicket.add({
        visitId: activeVisit.id,
        visitIdPda: activeVisit.idPda,
        ticketId: Number(ticketId),
        ticketIdPda,
        barcodeValidated: 0,
        statusPda: 'N',
      });
    }

    activeVisit = await findActiveVisitForClient(clientId);
  });
  return activeVisit;
};

/**
 * Find all ticket assigned to given visit
 * @param {number} visitId
 * @param {string} visitIdPda
 * @return {Promise<Array<TVisitTicket>>}
 */
const findAllTicketForVisit = async (visitId, visitIdPda) => db.visitTicket
  .where({ visitId: Number(visitId), visitIdPda })
  .toArray();

/**
 * @param clientId
 * @returns {Promise<TVisit|undefined>}
 */
const findActiveVisitForClient = async (clientId) => db.visit
  .where('clientId')
  .equals(Number(clientId))
  .filter((v) => v.visitDate && v.visitDate.startsWith(moment().format('YYYY-MM-DD')))
  .first();

/**
 * @param {number} clientId
 * @param {number} carOdometer
 * @returns {Promise<TVisit>}
 */
const createVisit = async (clientId, carOdometer) => {
  const [id, idPda] = await db.visit.add({
    id: 0,
    idPda: uuid(),
    clientId: Number(clientId),
    statusPda: 'N',
    carOdometer: Number(carOdometer),
    visitDate: moment().format('YYYY-MM-DD HH:mm:ss'),
    trackCost: 0,
    trackDistance: 0,
    trackDuration: 0,
  });
  return db.visit.get({ id, idPda });
};

const findAllTicketForActiveVisit = async (clientId) => {
  const activeVisit = await findActiveVisitForClient(Number(clientId));

  if (!activeVisit) {
    return [];
  }

  const visitTickets = await db.visitTicket
    .where({ visitId: activeVisit.id, visitIdPda: activeVisit.idPda })
    .toArray();

  const keys = visitTickets.map((t) => ([t.ticketId, t.ticketIdPda]));

  return Ticket.findAllTicketByIds(keys);
};

/**
 * @param {number} visitId
 * @param {string} visitIdPda
 * @returns {Promise<TVisit>}
 */
const findVisit = async (visitId, visitIdPda) => db.visit.get({ id: Number(visitId), idPda: visitIdPda });

/**
 * @returns {{
 * visitInProgress: boolean,
 * visitClientId: ?number,
 * visitCarOdometer: number,
 * visitStartDate: ?number
 * }}
 */
const getVisitFromSession = (clientId) => {
  const visitInProgress = parseInt(sessionStorage.getItem('visitInProgress') || '0', 10) !== 0;
  const visitClientId = parseInt(sessionStorage.getItem('visitClientId') || '0', 10);
  const visitCarOdometer = parseInt(sessionStorage.getItem('visitCarOdometer') || '0', 10);
  const visitStartDate = parseInt(sessionStorage.getItem('visitStartDate') || '0', 10);

  if (
    parseInt(clientId, 10) !== visitClientId
    || moment().format('YYYY-MM-DD') !== moment.unix(visitStartDate).format('YYYY-MM-DD')
  ) {
    return {
      visitInProgress: false,
      visitClientId: null,
      visitCarOdometer,
      visitStartDate: null,
    };
  }

  return {
    visitInProgress,
    visitClientId,
    visitCarOdometer,
    visitStartDate,
  };
};

/**
 * @param {number} clientId
 * @param {number} carOdometer
 * @returns {void}
 */
const startVisitSession = (clientId, carOdometer) => {
  sessionStorage.setItem('visitInProgress', 1);
  sessionStorage.setItem('visitClientId', clientId.toString(10));
  sessionStorage.setItem('visitCarOdometer', carOdometer.toString(10));
  sessionStorage.setItem('visitStartDate', moment().format('X'));
};

/**
 * @returns {void}
 */
const stopVisitSession = () => {
  sessionStorage.setItem('visitInProgress', 0);
  sessionStorage.removeItem('visitClientId');
  sessionStorage.removeItem('visitStartDate');
};

export const Visit = {
  getVisitFromSession,
  findAllTicketForVisit,
  startVisitSession,
  stopVisitSession,
  createVisit,
  findActiveVisitForClient,
  getOrCreateVisitForTicket,
  findAllTicketForActiveVisit,
  findVisit,
};
