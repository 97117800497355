import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Collapse } from '@mui/material';
import { ExpandLess as IconExpandLess, ExpandMore as IconExpandMore } from '@mui/icons-material';
import { DS, userService } from '../_services';
import { menuTechnics } from '../_constants';

function SubItems(props) {
  const { item, isListItemSelected, open } = props;

  return (
    <Collapse in={open[`subItem_${item.key}`]} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {item.subItems.map(({ ...subItem }, subIndex) => (
          <ListItem
            sx={{ pl: 4 }}
            button
            key={subItem.key}
            component="a"
            href={subItem.href}
            selected={isListItemSelected(subItem.href)}
          >
            {subItem.icon && (
              <ListItemIcon sx={{ minWidth: '30px' }}>{subItem.icon}</ListItemIcon>
            )}
            <ListItemText primary={subItem.text} />
          </ListItem>
        ))}
      </List>
    </Collapse>
  );
}

SubItems.propTypes = {
  item: PropTypes.shape(Object).isRequired,
  isListItemSelected: PropTypes.func.isRequired,
  open: PropTypes.arrayOf(Object).isRequired,
};

function MainMenu(props) {
  const { openMenu } = props;
  const [open, setOpen] = useState([]);
  const [menu, setMenu] = useState([]);
  const isListItemSelected = ((href) => window.location.pathname === href);

  const prepareMenu = useCallback(() => {
    const buildMenu = async () => {
      const visitDayStatus = await DS.VisitDay.getVisitDayStatus();
      const userType = userService.getUserType();
      const appState = { ...visitDayStatus, ...userType };

      setMenu(menuTechnics.filter((item) => {
        let show = true;
        show = show && (item.isTechnic === undefined || (item.isTechnic === appState.isTechnic));
        show = show && (item.isController === undefined || (item.isController === appState.isController));
        show = show && (item.dayIsOpen === undefined || (item.dayIsOpen === appState.dayIsOpen));
        show = show && (item.dayIsClose === undefined || (item.dayIsClose === appState.dayIsClose));

        return show;
      }));
    };
    buildMenu();
  }, [openMenu]);

  useEffect(() => {
    prepareMenu();
  }, [prepareMenu]);

  const handleSubItem = (item) => {
    if (!item.subItems) {
      return;
    }
    setOpen({ ...open, [`subItem_${item.key}`]: !open[`subItem_${item.key}`] });
  };

  return (
    <div>
      <Toolbar />
      <Divider />
      <List component="div">
        {menu.map(({ ...item }, index) => (
          <ListItem
            button
            key={item.key}
            component="a"
            href={item.href}
            selected={isListItemSelected(item.href)}
            onClick={() => handleSubItem(item)}
          >
            {item.icon && (
              <ListItemIcon sx={{ minWidth: '30px' }}>{item.icon}</ListItemIcon>
            )}
            <ListItemText primary={item.text} />
            {!!item.subItems && (open[`subItem_${item.key}`] ? <IconExpandLess /> : <IconExpandMore />)}
          </ListItem>
        ))}
      </List>
    </div>
  );
}

MainMenu.propTypes = {
  openMenu: PropTypes.bool,
};

MainMenu.defaultProps = {
  openMenu: false,
};

export default MainMenu;
