import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  Grid,
  Tabs,
} from '@mui/material';
import { DS, userService } from '../_services';
import { TabPanel, TabsTab } from '../Common';
import { trTab } from '../_constants';
import NoticeTab from './NoticeTab';
import CancelTab from './CancelTab';
import ActivityTab from './ActivityTab';
import ResolveTab from './ResolveTab';
import ValveTab from './ValveTab';

/**
 * @type {{isTechnic: boolean, isController: boolean}}
 */
const userType = userService.getUserType();

/**
 * @param {Object} props
 * @param {number} props.ticketId
 * @param {string} props.ticketIdPda
 * @param {number} props.visitId
 * @param {string} props.visitIdPda
 * @returns {JSX.Element}
 * @constructor
 */
function TicketResolve(props) {
  const { ticketId, ticketIdPda, visitId, visitIdPda } = useParams();
  const [state, setState] = useState({
    tabIndex: trTab.ACTIVITY,
    editPosValveEnabled: false,
    refurbishmentEnabled: false,
  });
  const [ticket, setTicket] = useState(/** @type {TTicket} */{});
  const [visit, setVisit] = useState(/** @type {TVisit} */{});
  const noticeTabRef = useRef();
  const cancelTabRef = useRef();

  const checkSpareValve = useCallback(() => {
    const checkValve = async () => {
      const ticketHasValveSpare = await DS.Ticket.ticketHasValveSpare(ticketId || 0, ticketIdPda || '');
      const posHasValveSpare = await DS.Ticket.posHasValveSpare(ticket?.posId || 0);

      setState((s) => ({ ...s, editPosValveEnabled: (ticketHasValveSpare || posHasValveSpare) }));
    };
    checkValve();
  }, [ticket.posId, ticketId, ticketIdPda]);

  useEffect(() => {
    const fetchData = async () => {
      setVisit(await DS.Visit.findVisit(visitId, visitIdPda));
      setTicket(await DS.Ticket.findTicket(ticketId, ticketIdPda));
    };
    checkSpareValve();
    fetchData();
  }, [checkSpareValve, ticketId, ticketIdPda, visitId, visitIdPda]);

  const handleTabChange = (event, newValue) => {
    if (noticeTabRef?.current && noticeTabRef.current.hasNotSavedData()) {
      return;
    }
    if (cancelTabRef?.current && cancelTabRef.current.hasNotSavedData()) {
      return;
    }
    setState((s) => ({ ...s, tabIndex: Number(newValue) }));
  };

  const spareChangeHandler = async () => {
    checkSpareValve();
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="normal"
      alignItems="flex-start"
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      rowSpacing={{ xs: 1 }}
    >
      <Grid item sx={{ textAlign: 'left' }} xs={12}>
        <Tabs
          value={state.tabIndex}
          onChange={handleTabChange}
          sx={{ borderBottom: 1, borderColor: 'divider' }}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          <TabsTab index={trTab.ACTIVITY} label="Czynności" idPrefix="ticketResolve" />
          <TabsTab index={trTab.NOTICE} label="Uwagi" idPrefix="ticketResolve" />
          <TabsTab
            index={trTab.RESOLVE}
            label="Rozwiązanie"
            idPrefix="ticketResolve"
            disabled={ticket.ticketTypeId === 7}
          />
          <TabsTab index={trTab.CANCEL} label="Anulowanie" idPrefix="ticketResolve" />
          <TabsTab
            index={trTab.VALVE}
            label="Zawory"
            idPrefix="ticketResolve"
            disabled={!state.editPosValveEnabled}
          />
          <TabsTab
            index={trTab.INVENTORY}
            label="Inwentaryzacja POS"
            idPrefix="ticketResolve"
            disabled={ticket.ticketTypeId !== 7}
          />
          <TabsTab
            index={trTab.REFURBISH}
            label="Remont"
            idPrefix="ticketResolve"
            disabled={!userType.isController || !state.refurbishmentEnabled}
          />
        </Tabs>
        <TabPanel value={state.tabIndex} index={trTab.ACTIVITY} idPrefix="ticket-resolve">
          <ActivityTab ticket={ticket} visit={visit} onSpareChange={spareChangeHandler} />
        </TabPanel>
        <TabPanel value={state.tabIndex} index={trTab.NOTICE} idPrefix="ticket-resolve">
          <NoticeTab ticket={ticket} ref={noticeTabRef} />
        </TabPanel>
        <TabPanel value={state.tabIndex} index={trTab.RESOLVE} idPrefix="ticket-resolve">
          <ResolveTab ticket={ticket} />
        </TabPanel>
        <TabPanel value={state.tabIndex} index={trTab.CANCEL} idPrefix="ticket-resolve">
          <CancelTab ticket={ticket} ref={cancelTabRef} />
        </TabPanel>
        <TabPanel value={state.tabIndex} index={trTab.INVENTORY} idPrefix="ticket-resolve">
          Inwentaryzacja
        </TabPanel>
        <TabPanel value={state.tabIndex} index={trTab.VALVE} idPrefix="ticket-resolve">
          <ValveTab ticket={ticket} />
        </TabPanel>
        <TabPanel value={state.tabIndex} index={trTab.REFURBISH} idPrefix="ticket-resolve">
          Refurbishment
        </TabPanel>
      </Grid>
    </Grid>
  );
}

TicketResolve.propTypes = {};

TicketResolve.defaultProps = {};

export default TicketResolve;
