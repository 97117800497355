import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

/**
 * @param {{}} props
 * @returns {*}
 * @constructor
 */
function TabPanel(props) {
  const { children, value, index, visible, idPrefix, ...other } = props;

  if (!visible) {
    return null;
  }

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${idPrefix}-tabpanel-${index}`}
      aria-labelledby={`${idPrefix}-tab-${index}`}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0, lg: { p: 2 } }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  idPrefix: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  visible: PropTypes.bool,
};

TabPanel.defaultProps = {
  children: (<div />),
  visible: true,
};

export { TabPanel };
