import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Box, IconButton } from '@mui/material';
import {
  Download as IconDownload,
  Send as IconSend,
  Search as IconSearch,
  Delete as IconDelete,
  Cancel as IconCancel,
} from '@mui/icons-material';
import { DataGrid, plPL, GridColDef } from '@mui/x-data-grid';
import { DS } from '../_services';
import { ConfirmDialog } from '../Common';
import SpareFilter from '../Common/SpareFilter';
import CreateSpareOrderDialog from './CreateSpareOrderDialog';
import CreateSpareSendToOtherDialog from './CreateSpareSendToOtherDialog';
import SpareOrderDetailDialog from './SpareOrderDetailDialog';
import ReceptionSpareOrderDialog from './ReceptionSpareOrderDialog';

function WarehouseList() {
  const { listType } = useParams();
  const [state, setState] = useState({
    gridPage: 0,
    gridPageSize: 25,
  });
  const [columnDef, setColumnDef] = useState([]);
  const [spareList, setSpareList] = useState([]);
  const [whFilter, setWhFilter] = useState({});
  const [spareOrder, setSpareOrder] = useState({
    open: false,
    spareTypeId: 0,
  });
  const [spareOrderReception, setSpareOrderReception] = useState({
    open: false,
    spareOrderId: 0,
    spareOrderIdPda: '',
    spareName: '',
  });
  const [spareSendToOther, setSpareSendToOther] = useState({
    open: false,
    spareTypeId: 0,
  });
  const [spareOrderDetail, setSpareOrderDetail] = useState({
    openDetail: false,
    openDeleteOrder: false,
    openCancelOrder: false,
    spareOrderId: 0,
    spareOrderIdPda: '',
    spareName: '',
  });

  const makeColumnDef = useCallback(() => {
    /**
     * @type {GridColDef[]}
     */
    const columnsOwnStock = [
      {
        field: 'id',
        headerName: '',
        sortable: false,
        filterable: false,
        width: 80,
        renderCell: (params) => (
          <>
            <IconButton
              size="small"
              color="info"
              onClick={(event) => makeOrderHandler(event, params.row.id)}
            >
              <IconDownload />
            </IconButton>
            {params.row.stockQuantity > 0 && (
              <IconButton
                size="small"
                color="info"
                onClick={(event) => sendToOtherHandler(event, params.row.id)}
              >
                <IconSend />
              </IconButton>
            )}
          </>
        ),
      },
      {
        field: 'name',
        headerName: 'Materiał',
        minWidth: 200,
        flex: 2,
        valueGetter: (param) => param.row.name,
      },
      {
        field: 'sap4Id',
        headerName: 'ID',
        flex: 0.3,
        minWidth: 50,
        valueGetter: (param) => param.row.sap4Id || param.row.sapId || '',
      },
      {
        field: 'orderQuantity',
        headerName: 'Ilość na stanie',
        flex: 0.3,
        minWidth: 50,
        valueGetter: (param) => param.row.stockQuantity,
      },
      {
        field: 'sendQuantity',
        headerName: 'Ilość zamów.',
        flex: 0.3,
        minWidth: 50,
        valueGetter: (param) => param.row.orderQuantity,
      },
    ];

    const columnsOrders = [
      {
        field: 'id',
        headerName: '',
        sortable: false,
        filterable: false,
        width: 80,
        renderCell: (params) => (
          <>
            <IconButton
              size="small"
              color="info"
              onClick={(event) => orderDetailHandler(event, params.row.id, params.row.idPda)}
            >
              <IconSearch />
            </IconButton>
            {params.row.quantityOrder > 0 && params.row.statusPda === 'N' && (
              <IconButton
                size="small"
                color="info"
                onClick={(event) => deleteOrderHandler(
                  event,
                  params.row.id,
                  params.row.idPda,
                  params.row.spareType.name,
                )}
              >
                <IconDelete />
              </IconButton>
            )}
            {params.row.quantityOrder > 0 && params.row.statusPda !== 'N' && (
              <IconButton
                size="small"
                color="info"
                onClick={(event) => cancelOrderHandler(
                  event,
                  params.row.id,
                  params.row.idPda,
                  params.row.spareType.name,
                )}
              >
                <IconCancel />
              </IconButton>
            )}
          </>
        ),
      },
      {
        field: 'name',
        headerName: 'Materiał',
        minWidth: 200,
        flex: 2,
        valueGetter: (param) => param.row.spareType.name,
      },
      {
        field: 'sap4Id',
        headerName: 'ID',
        flex: 0.5,
        minWidth: 50,
        valueGetter: (param) => param.row.spareType.sap4Id || param.row.spareType.sapId || '',
      },
      {
        field: 'quantity',
        headerName: 'Zamówiona ilość',
        flex: 0.5,
        minWidth: 50,
        valueGetter: (param) => param.row.quantityOrder,
      },
    ];

    const columnsShippedToMe = [
      {
        field: 'id',
        headerName: '',
        sortable: false,
        filterable: false,
        width: 80,
        renderCell: (params) => (
          <>
            <IconButton
              size="small"
              color="info"
              onClick={(event) => orderDetailHandler(event, params.row.id, params.row.idPda)}
            >
              <IconSearch />
            </IconButton>
            {params.row.quantityOrder > 0 && params.row.statusPda === 'S' && (
              <IconButton
                size="small"
                color="info"
                onClick={(event) => receptOrderHandler(
                  event,
                  params.row.id,
                  params.row.idPda,
                )}
              >
                <IconDownload />
              </IconButton>
            )}
          </>
        ),
      },
      {
        field: 'name',
        headerName: 'Materiał',
        minWidth: 200,
        flex: 2,
        valueGetter: (param) => param.row.spareType.name,
      },
      {
        field: 'sap4Id',
        headerName: 'ID',
        flex: 0.3,
        minWidth: 50,
        valueGetter: (param) => param.row.spareType.sap4Id || param.row.spareType.sapId || '',
      },
      {
        field: 'quantityOrder',
        headerName: 'Ilość zamów.',
        flex: 0.3,
        minWidth: 70,
        valueGetter: (param) => param.row.quantityOrder,
      },
      {
        field: 'quantitySend',
        headerName: 'Ilość wysłana',
        flex: 0.3,
        minWidth: 70,
        valueGetter: (param) => param.row.quantitySend,
      },
    ];

    const columnsShippedByMe = [
      {
        field: 'id',
        headerName: '',
        sortable: false,
        filterable: false,
        width: 80,
        renderCell: (params) => (
          <>
            <IconButton
              size="small"
              color="info"
              onClick={(event) => orderDetailHandler(event, params.row.id, params.row.idPda)}
            >
              <IconSearch />
            </IconButton>
            {params.row.quantityOrder > 0 && params.row.statusPda === 'N' && (
              <IconButton
                size="small"
                color="info"
                onClick={(event) => deleteOrderHandler(
                  event,
                  params.row.id,
                  params.row.idPda,
                  params.row.spareType.name,
                )}
              >
                <IconDelete />
              </IconButton>
            )}
          </>
        ),
      },
      {
        field: 'name',
        headerName: 'Materiał',
        minWidth: 200,
        flex: 2,
        valueGetter: (param) => param.row.spareType.name,
      },
      {
        field: 'sap4Id',
        headerName: 'ID',
        flex: 0.3,
        minWidth: 50,
        valueGetter: (param) => param.row.spareType.sap4Id || param.row.spareType.sapId || '',
      },
      {
        field: 'quantitySend',
        headerName: 'Ilość wysłana',
        flex: 0.3,
        minWidth: 70,
        valueGetter: (param) => param.row.quantitySend,
      },
    ];

    let colsDef = [];
    switch (listType) {
      case 'stock':
        colsDef = columnsOwnStock;
        break;
      case 'orders':
        colsDef = columnsOrders;
        break;
      case 'shippedByMe':
        colsDef = columnsShippedByMe;
        break;
      case 'shippedToMe':
        colsDef = columnsShippedToMe;
        break;
      default:
        colsDef = columnsOwnStock;
    }
    return colsDef;
  }, [listType]);

  const loadData = useCallback(() => {
    const fetchNecessaryData = async () => {
      switch (listType) {
        case 'stock':
          setSpareList(await DS.Warehouse.findAllSpareWithStockByFilter(whFilter));
          break;
        case 'orders':
          setSpareList(await DS.Warehouse.findAllSpareOrderByFilter(whFilter));
          break;
        case 'shippedByMe':
          setSpareList(await DS.Warehouse.findAllSpareShippedByMeByFilter(whFilter));
          break;
        case 'shippedToMe':
          setSpareList(await DS.Warehouse.findAllSpareShippedToMeByFilter(whFilter));
          break;
        default:
          setSpareList([]);
      }
    };

    fetchNecessaryData();
  }, [listType, whFilter]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    setColumnDef(makeColumnDef());
  }, [makeColumnDef]);

  /**
   * @param {Object} newWhFilter
   * @param {number} newWhFilter.spareGroupId
   * @param {number} newWhFilter.spareCategory1Id
   * @param {number} newWhFilter.spareCategory2Id
   * @param {string} newWhFilter.spareName   * @return {Promise<void>}
   */
  const spareFilterChangeHandler = async (newWhFilter) => {
    setWhFilter(newWhFilter);
    setState((s) => ({ ...s, gridPage: 0, selectedSpareType: null, gridSelection: [] }));
  };

  /**
   * Open dialog for creating new spare part order
   * @param {Event} event
   * @param {number} spareTypeId
   */
  const makeOrderHandler = (event, spareTypeId) => {
    event.defaultMuiPrevented = true;
    setSpareOrder((s) => ({
      ...s,
      open: true,
      spareTypeId: Number(spareTypeId),
    }));
  };

  /**
   * Close create spare order dialog, if needed grid refresh is triggered
   * @param {boolean} isOrderCreated
   */
  const makeOrderCloseHandler = (isOrderCreated) => {
    if (isOrderCreated) {
      loadData();
    }
    setSpareOrder((s) => ({ open: false }));
  };

  /**
   * Open dialog for creating new spare part send to other user
   * @param {Event} event
   * @param {number} spareTypeId
   */
  const sendToOtherHandler = (event, spareTypeId) => {
    event.defaultMuiPrevented = true;
    setSpareSendToOther((s) => ({
      ...s,
      open: true,
      spareTypeId: Number(spareTypeId),
    }));
  };

  /**
   * Close spare sending dialog, if needed grid refresh is triggered
   * @param {boolean} isShiftCreated
   */
  const sendToOtherCloseHandler = (isShiftCreated) => {
    if (isShiftCreated) {
      loadData();
    }
    setSpareSendToOther((s) => ({ open: false }));
  };

  /**
   * Open order detal dialog
   * @param {Event} event
   * @param {number} spareOrderId
   * @param {string} spareOrderIdPda
   */
  const orderDetailHandler = (event, spareOrderId, spareOrderIdPda) => {
    setSpareOrderDetail((s) => ({ ...s, openDetail: true, spareOrderId, spareOrderIdPda }));
  };

  /**
   * Open order detal dialog
   * @param {Event} event
   * @param {number} spareOrderId
   * @param {string} spareOrderIdPda
   * @param {string} spareName
   */
  const deleteOrderHandler = (event, spareOrderId, spareOrderIdPda, spareName) => {
    setSpareOrderDetail((s) => ({ ...s, openDeleteOrder: true, spareOrderId, spareOrderIdPda, spareName }));
  };

  /**
   * Open order detal dialog
   * @param {Event} event
   * @param {number} spareOrderId
   * @param {string} spareOrderIdPda
   * @param {string} spareName
   */
  const cancelOrderHandler = (event, spareOrderId, spareOrderIdPda, spareName) => {
    setSpareOrderDetail((s) => ({ ...s, openCancelOrder: true, spareOrderId, spareOrderIdPda, spareName }));
  };

  /**
   * Delete spare order and refresh list
   * @param {boolean} isConfirmed
   */
  const orderDeleteCloseHandler = async (isConfirmed) => {
    if (isConfirmed) {
      await DS.Warehouse.deleteSpareOrder(spareOrderDetail.spareOrderId, spareOrderDetail.spareOrderIdPda);
      loadData();
    }
    orderDetailCloseHandler();
  };

  /**
   * Cancel spare order and refresh list
   * @param {boolean} isConfirmed
   */
  const orderCancelCloseHandler = async (isConfirmed) => {
    if (isConfirmed) {
      await DS.Warehouse.cancelSpareOrder(spareOrderDetail.spareOrderId, spareOrderDetail.spareOrderIdPda);
      loadData();
    }
    orderDetailCloseHandler();
  };

  /**
   * Close order detail dialog
   */
  const orderDetailCloseHandler = () => {
    setSpareOrderDetail((s) => ({
      ...s,
      openDetail: false,
      openDeleteOrder: false,
      openCancelOrder: false,
      spareOrderId: 0,
      spareOrderIdPda: '',
    }));
  };

  /**
   * Open order reception dialog
   * @param {Event} event
   * @param {number} spareOrderId
   * @param {string} spareOrderIdPda
   */
  const receptOrderHandler = (event, spareOrderId, spareOrderIdPda) => {
    setSpareOrderReception((s) => ({ ...s, open: true, spareOrderId, spareOrderIdPda }));
  };

  /**
   * Close reception dialog and refresh grid
   * @param {boolean} isReceipted
   */
  const receptOrderCloseHandler = (isReceipted) => {
    if (isReceipted) {
      loadData();
    }
    setSpareOrderReception((s) => ({ open: false }));
  };

  return (
    <div style={{ display: 'flex', height: 'calc(100vh - 150px)' }}>
      <div style={{ flexGrow: 1 }}>
        <SpareFilter
          onChange={spareFilterChangeHandler}
        />
        <DataGrid
          rows={spareList || []}
          columns={columnDef}
          getRowId={(row) => (listType === 'stock' ? row.id : `${row.id}^${row.idPda}`)}
          sx={{ mt: 1 }}
          rowsPerPageOptions={[5, 10, 25, 50]}
          pageSize={state.gridPageSize}
          page={state.gridPage}
          onPageChange={(page) => setState((s) => ({ ...s, gridPage: page }))}
          onPageSizeChange={(pageSize) => setState((s) => ({ ...s, gridPageSize: pageSize }))}
          selectionModel={state.gridSelection}
          density="compact"
          localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
          hideFooterSelectedRowCount
          hideFooterRowCount
        />
        {spareOrder.open && (
          <CreateSpareOrderDialog
            onAccept={makeOrderCloseHandler}
            onDecline={makeOrderCloseHandler}
            maxWidth="sm"
            open={spareOrder.open}
            spareTypeId={spareOrder.spareTypeId}
          />
        )}
        {spareOrderReception.open && (
          <ReceptionSpareOrderDialog
            onAccept={receptOrderCloseHandler}
            onDecline={receptOrderCloseHandler}
            maxWidth="sm"
            open={spareOrderReception.open}
            spareOrderId={spareOrderReception.spareOrderId}
            spareOrderIdPda={spareOrderReception.spareOrderIdPda}
          />
        )}
        {spareSendToOther.open && (
          <CreateSpareSendToOtherDialog
            onAccept={sendToOtherCloseHandler}
            onDecline={sendToOtherCloseHandler}
            maxWidth="sm"
            open={spareSendToOther.open}
            spareTypeId={spareSendToOther.spareTypeId}
          />
        )}
        {spareOrderDetail.openDetail && (
          <SpareOrderDetailDialog
            onAccept={orderDetailCloseHandler}
            onDecline={orderDetailCloseHandler}
            maxWidth="sm"
            open={spareOrderDetail.openDetail}
            spareOrderId={spareOrderDetail.spareOrderId}
            spareOrderIdPda={spareOrderDetail.spareOrderIdPda}
          />
        )}
        {spareOrderDetail.openDeleteOrder && (
          <ConfirmDialog
            onClose={orderDeleteCloseHandler}
            maxWidth="sm"
            open={spareOrderDetail.openDeleteOrder}
            spareOrderId={spareOrderDetail.spareOrderId}
            spareOrderIdPda={spareOrderDetail.spareOrderIdPda}
          >
            <div>
              Czy na pewno chcesz usunąć zamówienie materiału:
              <Box component="h4">{spareOrderDetail.spareName}</Box>
            </div>
          </ConfirmDialog>
        )}
        {spareOrderDetail.openCancelOrder && (
          <ConfirmDialog
            onClose={orderCancelCloseHandler}
            maxWidth="sm"
            open={spareOrderDetail.openCancelOrder}
            spareOrderId={spareOrderDetail.spareOrderId}
            spareOrderIdPda={spareOrderDetail.spareOrderIdPda}
          >
            <div>
              Czy na pewno chcesz anulować zamówienie materiału:
              <Box component="h4">{spareOrderDetail.spareName}</Box>
              (Ponieważ zamówienie zostało wcześniej wysłane może być w trakcie realizacji,
              proszę o kontakt z magazynem oraz synchronizacje)
            </div>
          </ConfirmDialog>
        )}
      </div>
    </div>
  );
}

export default WarehouseList;
