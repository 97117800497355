export const synchroConstants = {
  DB_READ_PENDING: 'SYNCHRO_DB_READ_PENDING',
  UPLOAD_PENDING: 'SYNCHRO_UPLOAD_PENDING',
  DB_WRITE_PENDING: 'SYNCHRO_DB_WRITE_PENDING',
  DOWNLOAD_PENDING: 'SYNCHRO_DOWNLOAD_PENDING',
  ERROR: 'SYNCHRO_ERROR',
  OK: 'SYNCHRO_OK',
};

export const syncType = {
  ST_NORMAL: 'NORMAL',
  ST_FORCE_READ: 'FORCE_READ',
  ST_FORCE_WRITE: 'FORCE_WRITE',
  ST_ONLY_WAREHOUSE: 'ONLY_WAREHOUSE',
};
