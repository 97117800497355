import { db } from './db';

/**
 * @returns {Promise<TPos[]>}
 */
const findAllPos = async () => {
  const poses = await db.pos.toArray();

  await Promise.all(poses.map(async (pos) => {
    [pos.client, pos.posType, pos.posHistories] = await Promise.all([
      db.client.get(pos.clientId),
      db.posType.get(pos.posTypeId),
      db.ticketHistory
        .where({ posId: pos.id })
        .reverse()
        .sortBy('ticketNo'),
    ]);
  }));

  return poses;
};

/**
 * @param {number} clientId
 * @returns {Promise<TPos[]>}
 */
const findAllPosForClient = async (clientId) => {
  const poses = await db.pos
    .where({ clientId })
    .toArray();

  await Promise.all(poses.map(async (pos) => {
    [pos.client, pos.posType, pos.posHistories] = await Promise.all([
      db.client.get(pos.clientId),
      db.posType.get(pos.posTypeId),
      db.ticketHistory
        .where({ posId: pos.id })
        .reverse()
        .sortBy('ticketNo'),
    ]);
  }));

  return poses;
};

/**
 * @param {number} posId
 * @returns {Promise<TPos>}
 */
const findPos = async (posId) => {
  const pos = await db.pos.get(posId);

  [pos.client, pos.posType, pos.posHistories] = await Promise.all([
    db.client.get(pos.clientId),
    db.posType.get(pos.posTypeId),
    db.ticketHistory
      .where({ posId: pos.id })
      .reverse()
      .sortBy('ticketNo'),
  ]);

  return pos;
};

export const Pos = {
  findAllPos,
  findAllPosForClient,
  findPos,
};
