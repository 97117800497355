import React, { useState } from 'react';

import {
  Button,
  FormControl,
  FormHelperText, FormLabel,
  Grid,
  LinearProgress,
  Paper,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { history } from '../_helpers';
import { userService } from '../_services';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}`,
  },
  buttonWrapper: {
    margin: theme.spacing(),
    position: 'relative',
  },
});

function Login() {
  const [userData, setUserData] = useState({
    username: '',
    password: '',
    submitted: false,
    loggingIn: false,
    incorrectLogin: false,
  });

  const theme = useTheme();
  const classes = styles(theme);

  const handleInputChange = (field, date) => {
    setUserData({ ...userData, [field]: date, incorrectLogin: false });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setUserData({ ...userData, submitted: true });
    if (!userData.username || !userData.password) {
      return;
    }

    setUserData({ ...userData, loggingIn: true, incorrectLogin: false });
    userService.login(userData.username, userData.password)
      .then((result) => {
        if (result) {
          history.push('/');
          return;
        }

        setUserData({ ...userData, loggingIn: false, incorrectLogin: true });
      });
  };

  return (
    <Grid container sx={{ display: 'flex', flexDirection: 'columns', justifyContent: 'space-around' }}>
      <Grid item xs={10} sm={8} md={6} lg={5} xl={4}>
        <Paper style={classes.paper} elevation={8}>
          <Typography component="h1" variant="h5" data-testid="head-app-title">
            IPCOS 2
          </Typography>
          <form noValidate name="form" onSubmit={handleSubmit}>
            <FormControl margin="normal" required fullWidth>
              <TextField
                type="text"
                value={userData.username}
                name="username"
                label="Login"
                margin="normal"
                variant="standard"
                onChange={(event) => handleInputChange(event.target.name, event.target.value)}
                error={userData.submitted && !userData.username}
                autoFocus
                data-testid="input-username"
              />
              {userData.submitted && !userData.username && (
                <FormHelperText error data-testid="label-error-empty-username">
                  Podanie loginu jest wymagane
                </FormHelperText>
              )}
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <TextField
                value={userData.password}
                name="password"
                label="Hasło"
                margin="normal"
                variant="standard"
                type="password"
                onChange={(event) => handleInputChange(event.target.name, event.target.value)}
                error={userData.submitted && !userData.password}
                data-testid="input-password"
              />
              {userData.submitted && !userData.password && (
                <FormHelperText error data-testid="label-error-empty-password">
                  Podanie hasła jest wymagane
                </FormHelperText>
              )}
            </FormControl>
            {userData.incorrectLogin && (
              <FormLabel color="error" error data-testid="label-error-bad-credentials">
                Podany login lub hasło jest błędne
              </FormLabel>
            )}
            <Button
              color="primary"
              type="submit"
              variant="contained"
              fullWidth
              disabled={userData.loggingIn}
              data-testid="button-submit"
            >
              Zaloguj
            </Button>
            <div style={classes.buttonWrapper}>
              {userData.loggingIn && <LinearProgress data-testid="linearProgres" />}
            </div>
          </form>

        </Paper>
      </Grid>
    </Grid>
  );
}

export default Login;
