import React from 'react';
import PropTypes from 'prop-types';

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';

function ResolveStandardForm(props) {
  const {
    /** @type {TTicket} */ ticket,
    /** @type {{improperTicketReasons: array, posLocations: array}} */ dictionary,
    /** @type {TResolveTicket} */ resolveTicket,
    /** @type {function(propertyName: string, newValue: any)} */ onChange,
  } = props;

  /**
   * @param {Event} event
   */
  const handleInputChange = (event) => {
    let { value } = event.target;

    if (event.target.type === 'checkbox') {
      value = event.target.checked;
    }
    onChange(event.target.name, value);
  };

  return (
    <form
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
      noValidate
      name="form"
    >
      <FormGroup sx={{ mt: 0 }}>
        <FormLabel component="div">
          Ostatnia sanitacja:
          {ticket.pos.lastSanitDate}
        </FormLabel>
        <FormLabel component="div">
          Następna sanitacja:
          {ticket.pos.nextSanitDate}
        </FormLabel>
        <FormControlLabel
          control={<Checkbox name="sanit" onChange={handleInputChange} checked={resolveTicket.sanit} />}
          label="Wykonano sanitacje"
        />
      </FormGroup>
      <FormGroup sx={{ mt: 2 }}>
        <FormLabel component="div">
          Ostatni przegląd:
          {ticket.pos.lastInspDate}
        </FormLabel>
        <FormLabel component="div">
          Następny przegląd:
          {ticket.pos.nextInspDate}
        </FormLabel>
        <FormControlLabel
          control={<Checkbox name="insp" onChange={handleInputChange} checked={resolveTicket.insp} />}
          label="Wykonano przegląd"
        />
      </FormGroup>
      <FormControl size="small" fullWidth sx={{ mt: 2 }}>
        <InputLabel
          id="incorrect-use-percent-label"
          htmlFor="incorrect-use-percent"
        >
          Urządzenie użytkowane prawidłowo w:
        </InputLabel>
        <Select
          labelId="incorrect-use-percent-label"
          id="incorrect-use-percent"
          label="Urządzenie użytkowane prawidłowo w:"
          name="incorrectUsePercent"
          value={resolveTicket.incorrectUsePercent}
          onChange={handleInputChange}
        >
          {[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((sg) => (
            <MenuItem key={sg} value={sg}>{`${sg} %`}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl size="small" fullWidth sx={{ mt: 2 }}>
        <InputLabel
          id="pos-location-label"
          htmlFor="pos-location"
        >
          Lokalizacja u klienta:
        </InputLabel>
        <Select
          labelId="pos-location-label"
          id="pos-location"
          label="Lokalizacja u klienta:"
          name="posLocation"
          value={dictionary.posLocations.length > 0 ? resolveTicket.posLocation : ''}
          onChange={handleInputChange}
        >
          {dictionary.posLocations.map((sg) => (
            <MenuItem key={sg.id} value={sg.id}>{sg.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormGroup sx={{ mt: 2 }}>
        <FormControlLabel
          control={(
            <Checkbox
              name="improperTicket"
              onChange={handleInputChange}
              checked={resolveTicket.improperTicket}
            />
          )}
          label="Zgłoszenia niepoprawne"
        />
      </FormGroup>
      <FormControl disabled={!resolveTicket.improperTicket} size="small" fullWidth sx={{ mt: 0 }}>
        <InputLabel
          id="pos-location-label"
          htmlFor="pos-location"
        >
          Przyczyna niepoprawnego zgłoszenia:
        </InputLabel>
        <Select
          labelId="pos-location-label"
          id="pos-location"
          label="Przyczyna niepoprawnego zgłoszenia:"
          name="improperTicketReasonId"
          value={dictionary.improperTicketReasons.length > 0 ? resolveTicket.improperTicketReasonId : ''}
          onChange={handleInputChange}
        >
          {dictionary.improperTicketReasons.map((sg) => (
            <MenuItem key={sg.id} value={sg.id}>{sg.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </form>
  );
}

ResolveStandardForm.propTypes = {
  ticket: PropTypes.shape(Object).isRequired,
  dictionary: PropTypes.shape(Object).isRequired,
  resolveTicket: PropTypes.shape(Object).isRequired,
  onChange: PropTypes.func.isRequired,
};

ResolveStandardForm.defaultProps = {};

export default ResolveStandardForm;
