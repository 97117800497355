import { db } from './db';

/**
 * @returns {Promise<TTicketResolveReason[]>}
 */
const findAllTicketResolveReason = () => db.ticketResolveReason.toArray();

/**
 * @param {number} ticketResolveReasonId
 * @returns {Promise<TTicketResolveReason>}
 */
const findTicketResolveReason = (ticketResolveReasonId) => db.ticketResolveReason.get(Number(ticketResolveReasonId));

export const TicketResolveReason = {
  findAllTicketResolveReason,
  findTicketResolveReason,
};
