import React from 'react';
import { Box, Grid, Divider } from '@mui/material';
import PropTypes from 'prop-types';

/**
 * @param {{}} props
 * @returns {*}
 * @constructor
 */
function LabelValueList(props) {
  const { list, idPrefix } = props;

  return (
    <Box>
      {list.map((value) => (
        <Grid
          container
          key={`${idPrefix}-${value.label}`}
          spacing={1}
          sx={{ m: 0, p: 0, ':nth-of-type(even)': { background: 'rgba(25, 118, 210, 0.08)' } }}
        >
          <Grid item xs={4} sx={{ py: 0, px: 1 }}>
            {`${value.label}:`}
          </Grid>
          <Grid item xs={8} sx={{ py: 0, px: 1 }}>
            {value.value}
          </Grid>
          <Divider />
        </Grid>
      ))}
    </Box>
  );
}

LabelValueList.propTypes = {
  list: PropTypes.arrayOf(Object).isRequired,
  idPrefix: PropTypes.string.isRequired,
};

export { LabelValueList };
