import React from 'react';
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import PropTypes from 'prop-types';

function ContentDialog(props) {
  const {
    open,
    onAccept,
    onDecline,
    dialogTitle,
    children,
    acceptLabel,
    declineLabel,
    acceptDisabled,
    declineDisabled,
    maxWidth,
    dialogType,
  } = props;

  const handleAccept = () => onAccept();
  const handleDecline = () => onDecline();

  const dialogTypeColor = {
    normal: 'primary',
    success: 'success',
    info: 'info',
    alert: 'warning',
    error: 'error',
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleDecline}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={maxWidth}
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" sx={{ p: 0 }}>
          <AppBar sx={{ p: 1 }} variant="elevation" position="relative" color={dialogTypeColor[dialogType]}>
            {dialogTitle}
          </AppBar>
        </DialogTitle>
        <DialogContent sx={{ p: 1 }} id="alert-dialog-description">{children}</DialogContent>
        <DialogActions>
          {declineLabel !== '' && (
            <Button onClick={handleDecline} disabled={declineDisabled} color="secondary">{declineLabel}</Button>
          )}
          {acceptLabel !== '' && (
            <Button onClick={handleAccept} disabled={acceptDisabled} color="primary" autoFocus>{acceptLabel}</Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

ContentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
  dialogTitle: PropTypes.string,
  children: PropTypes.element.isRequired,
  acceptLabel: PropTypes.string,
  declineLabel: PropTypes.string,
  acceptDisabled: PropTypes.bool,
  declineDisabled: PropTypes.bool,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  dialogType: PropTypes.oneOf(['normal', 'success', 'info', 'alert', 'error']),
};

ContentDialog.defaultProps = {
  dialogTitle: 'IPCOS 2.0',
  acceptLabel: 'Tak',
  declineLabel: 'Nie',
  acceptDisabled: false,
  declineDisabled: false,
  maxWidth: 'md',
  dialogType: 'normal',
};

export { ContentDialog };
