export const ticketStatus = {
  NEW: 'NEW',
  ASSIGNED: 'ASSIGNED',
  CLOSED: 'CLOSED',
  RESOLVED: 'RESOLVED',
};

export const ticketStatusName = {
  NEW: 'Nowe',
  ASSIGNED: 'Przypisane',
  CLOSED: 'Zamknięte',
  RESOLVED: 'Rozwiązane',
};

export const ticketResolveType = {
  OPEN: 'OPEN',
  RESOLVED: 'RESOLVED',
  CANCELED: 'CANCELED',
};

export const ticketResolveTypeName = {
  OPEN: 'Otwarte',
  RESOLVED: 'Rozwiązane',
  CANCELED: 'Anulowane',
};

export const ticketPriority = {
  LEVEL_1: 'P2014 1',
  LEVEL_2: 'P2014 2',
  LEVEL_3: 'P2014 3',
  LEVEL_4: 'P2014 4',
  LEVEL_5: 'P2014 5',
  LEVEL_6: 'P2014 6',
  LEVEL_7: 'P2014 7',
  LEVEL_8: 'P2014 8',
  LEVEL_9: 'P2014 9',
  LEVEL_10: 'P2014 10',
  LEVEL_11: 'P2014 11',
  LEVEL_12: 'P2014 12',
  LEVEL_21: 'P2015 1',
  LEVEL_22: 'P2015 2',
  LEVEL_23: 'P2015 3',
  LEVEL_24: 'P2015 4',
  LEVEL_25: 'P2015 5',
  LEVEL_26: 'P2015 6',
  LEVEL_27: 'P2015 7',
  LEVEL_31: 'P 1',
  LEVEL_32: 'P 2',
  LEVEL_33: 'P 3',
  LEVEL_34: 'P 4',
  LEVEL_35: 'P 5',
  LEVEL_36: 'P 6',
};

export const posLocation = {
  ROOM: 'Sala',
  KITCHEN: 'Kuchnia',
};

export const SPARE_VALVE_ID = 1005;
