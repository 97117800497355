import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem, TextField, Box, Paper,
} from '@mui/material';

import { DS } from '../_services';
import { uuid } from '../_helpers/uuid';
import { ContentDialog } from '../Common';

function CreateTicketDialog(props) {
  const {
    open,
    onAccept,
    onDecline,
    maxWidth,
    posId,
  } = props;
  const { ticketId, ticketIdPda, clientId } = useParams();

  const [state, setState] = useState({
    dialogTitle: '',
  });

  const [newTicket, setNewTicket] = useState({
    id: 0,
    idPda: '',
    ticketTypeId: '',
    notice: 'Zgłoszenie dodatkowe',
  });
  const [availableTicketType, setAvailableTicketType] = useState([]);

  useEffect(() => {
    const fetchNecessaryData = async () => {
      const pos = await DS.Pos.findPos(posId);
      setState((s) => ({ ...s, dialogTitle: `${pos.posType.name} - ${pos.barcode}` }));

      const tickets = await DS.Ticket.findAllTicketForClient(pos.clientId);
      let ticketTypes = await DS.TicketType.findAllTicketType();

      tickets.map((ticket) => {
        if (ticket.posId === pos.id && ticket.status !== 'RESOLVED' && ticket.status !== 'CLOSED') {
          ticketTypes = ticketTypes.filter((t) => t.id !== ticket.ticketTypeId);
        }
        return true;
      });

      const baseTicket = await DS.Ticket.findTicket(ticketId, ticketIdPda);
      const nt = {
        id: 0,
        idPda: uuid(),
        parentTicketId: baseTicket.parentTicketId ?? baseTicket.id,
        parentTicketIdPda: Number(baseTicket.parentTicketId) !== 0 ? baseTicket.parentTicketIdPda : baseTicket.idPda,
        ticketNo: await DS.Ticket.generateTicketNo(baseTicket.ticketNo.substring(0, 12)),
        posId: pos.id,
        clientId: Number(clientId),
        ownerName: baseTicket.ownerName,
        vip: baseTicket.vip,
        incorrectUsePercent: pos.incorrectUsePercent,
        category: 'Brak kategorii zgłoszenia',
        ticketResolveType: 'OPEN',
        contactPerson: baseTicket.contactPerson,
        contactPhone: baseTicket.contactPhone,
        notice: `Zgłoszenie dodatkowe do zgłoszenia: ${baseTicket.ticketNo.substring(0, 12)}`,
        status: 'ASSIGNED',
        priorityLevel: 'LEVEL_36',
        warranty: 0,
        ticketTypeId: ticketTypes[0]?.id ?? '',
        ticketTypeName: ticketTypes[0]?.name ?? '',
        dateNew: moment().format('YYYY-MM-DD HH:mm:ss'),
        dateAssigned: moment().format('YYYY-MM-DD HH:mm:ss'),
        statusPda: 'N',
      };
      setAvailableTicketType(ticketTypes);
      setNewTicket((s) => ({ ...s, ...nt }));
    };

    fetchNecessaryData();
  }, [posId, ticketId, ticketIdPda, clientId]);

  const handleAccept = async (...prop) => {
    setState((s) => ({ ...s, error: '' }));
    try {
      await DS.Ticket.createTicket(newTicket);
    } catch (e) {
      setState((s) => ({ ...s, error: e.message }));
      return;
    }
    onAccept(...prop);
  };
  const handleDecline = (...prop) => onDecline(...prop);

  const handleTicketTypeChange = (value) => {
    const selectedType = availableTicketType.filter((t) => t.id === Number(value));
    setNewTicket((s) => ({ ...s, ticketTypeId: value, ticketTypeName: selectedType[0].name }));
  };

  const handleInputChange = (field, value) => {
    setNewTicket((s) => ({ ...s, [field]: value }));
  };

  const createTicketIsAvailable = availableTicketType.length > 0;

  return (
    <div>
      <ContentDialog
        open={open}
        onAccept={handleAccept}
        onDecline={handleDecline}
        dialogTitle={state.dialogTitle}
        maxWidth={maxWidth}
        acceptLabel={createTicketIsAvailable ? 'Dodaj zgłoszenie' : ''}
        declineLabel="Anuluj"
      >
        <>
          {createTicketIsAvailable && (
            <Box mt={3} mb={0} px={2}>
              <FormControl fullWidth>
                <InputLabel id="new-ticket-type-label" htmlFor="new-ticket-type">Typ zgłoszenia</InputLabel>
                <Select
                  labelId="new-ticket-type-label"
                  label="Typ Zgłoszenia"
                  id="new-ticket-type"
                  name="ticketTypeId"
                  value={newTicket.ticketTypeId}
                  onChange={(event) => handleTicketTypeChange(event.target.value)}
                >
                  {availableTicketType.map((tt) => (
                    <MenuItem key={tt.id} value={tt.id}>{tt.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <br />
              <FormControl margin="normal" required fullWidth>
                <TextField
                  sx={{ xs: 12 }}
                  value={newTicket.notice}
                  name="notice"
                  label="Dodatkowy opis:"
                  margin="normal"
                  variant="standard"
                  type="text"
                  multiline
                  onChange={(event) => handleInputChange(event.target.name, event.target.value)}
                  data-testid="input-notice"
                />
              </FormControl>
            </Box>
          )}
          {!createTicketIsAvailable && (
            <Box mt={3} mb={0} px={2}>
              Dla wskazanego urządzenia są aktywne zgłoszenia dla wszystkich typów. Możesz edytować istniejące
              zgłoszenia.
            </Box>
          )}
          {state.error && (
            <Paper sx={{ p: 1, mt: 3, mb: 1, bgcolor: 'error.main', color: 'error.contrastText' }}>
              {state.error}
            </Paper>
          )}
        </>
      </ContentDialog>
    </div>
  );
}

CreateTicketDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  posId: PropTypes.number.isRequired,
};

CreateTicketDialog.defaultProps = {
  maxWidth: 'md',
};

export default CreateTicketDialog;
