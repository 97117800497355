import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Box,
  FormControl,
  InputAdornment,
  Paper,
  TextField,
} from '@mui/material';
import { DataGrid, plPL } from '@mui/x-data-grid';

import { DS } from '../_services';
import { uuid } from '../_helpers/uuid';
import { ContentDialog } from '../Common';
import SpareFilter from '../Common/SpareFilter';

function CreateActivitySpareDialog(props) {
  const {
    open,
    onAccept,
    onDecline,
    maxWidth,
    activityId,
    activityIdPda,
  } = props;
  const { ticketId, ticketIdPda, visitId, visitIdPda } = useParams();

  const [state, setState] = useState({
    dialogTitle: '',
    gridPage: 0,
    gridPageSize: 25,
    gridSelection: [],
    selectedSpareType: null,
  });

  const [newActivitySpare, setNewActivitySpare] = useState({
    activityId,
    activityIdPda,
    description: '',
    id: 0,
    idPda: uuid(),
    quantity: 0,
    spareTypeId: '',
  });
  const [spareList, setSpareList] = useState([]);

  /**
   *
   * @type {GridColDef[]}
   */
  const columnsSpare = [
    {
      field: 'name',
      headerName: 'Materiał',
      minWidth: 200,
      flex: 2,
      valueGetter: (param) => param.row.name,
    },
    {
      field: 'sap4Id',
      headerName: 'ID',
      flex: 0.5,
      minWidth: 50,
      valueGetter: (param) => param.row.sap4Id || param.row.sapId || '',
    },
    {
      field: 'quantity',
      headerName: 'Ilość',
      flex: 0.5,
      minWidth: 50,
      valueGetter: (param) => param.row.stockQuantity,
    },
  ];

  useEffect(() => {
    const fetchNecessaryData = async () => {
      const nas = {
        activityId,
        activityIdPda,
        description: '',
        id: 0,
        idPda: uuid(),
        quantity: 0,
        spareTypeId: '',
      };
      setSpareList(await DS.Warehouse.findAllSpareWithStock());

      setNewActivitySpare((s) => ({ ...s, ...nas }));
    };

    fetchNecessaryData();
  }, [activityId, activityIdPda, ticketId, ticketIdPda, visitId, visitIdPda]);

  /**
   * @return {Promise<void>}
   */
  const handleAccept = async () => {
    setState((s) => ({ ...s, error: '' }));
    try {
      await DS.Warehouse.addActivitySpare(newActivitySpare);
      onAccept(newActivitySpare);
    } catch (e) {
      setState((s) => ({ ...s, error: e.message }));
    }
  };

  const handleDecline = (...prop) => onDecline(...prop);

  /**
   * @param {string} field
   * @param {string} value
   */
  const handleInputChange = (field, value) => {
    setNewActivitySpare((s) => ({ ...s, [field]: value }));
  };

  /**
   * @param {Object} whFilter
   * @param {number} whFilter.spareGroupId
   * @param {number} whFilter.spareCategory1Id
   * @param {number} whFilter.spareCategory2Id
   * @param {string} whFilter.spareName   * @return {Promise<void>}
   */
  const spareFilterChangeHandler = async (whFilter) => {
    setSpareList(await DS.Warehouse.findAllSpareWithStockByFilter(whFilter));
    setState((s) => ({ ...s, gridPage: 0, selectedSpareType: null, gridSelection: [] }));
  };

  /**
   * @param {Event} event
   */
  const handleNumericInputChange = (event) => {
    event.preventDefault();

    if (
      Number(event.target.value) >= 0
      && Number(event.target.value) <= (state.selectedSpareType?.stockQuantity ?? 0)
    ) {
      setNewActivitySpare((s) => ({ ...s, quantity: Number(event.target.value) }));
    }
  };

  /**
   * @param {number[]} newSelectionModel
   * @param {Object} details
   */
  const selectSpareTypeHandler = async (newSelectionModel, details) => {
    let selectedSpareType = null;

    if (newSelectionModel.length > 0) {
      selectedSpareType = await DS.Warehouse.findSpareWithStock(Number(newSelectionModel[0]));

      if (newActivitySpare.quantity > selectedSpareType.stockQuantity) {
        setNewActivitySpare((s) => ({ ...s, quantity: selectedSpareType.stockQuantity }));
      }
    }

    setNewActivitySpare((s) => ({ ...s, spareTypeId: selectedSpareType.id }));

    setState((s) => ({
      ...s,
      gridSelection: newSelectionModel,
      selectedSpareType,
    }));
  };

  return (
    <div>
      <ContentDialog
        open={open}
        onAccept={handleAccept}
        onDecline={handleDecline}
        dialogTitle="Dodaj część"
        maxWidth={maxWidth}
        acceptLabel="Dodaj"
        declineLabel="Anuluj"
        acceptDisabled={!state.selectedSpareType || Number(newActivitySpare.quantity) === 0}
      >
        <>
          <SpareFilter
            onChange={spareFilterChangeHandler}
          />
          <Box component="div" sx={{ my: 1, display: 'flex', height: 'calc((100vh - 160px) / 2)' }}>
            <Box component="div" sx={{ flexGrow: 1 }}>
              <DataGrid
                rows={spareList || []}
                columns={columnsSpare}
                getRowId={(row) => row.id}
                sx={{ mt: 1 }}
                rowsPerPageOptions={[5, 10, 25, 50]}
                pageSize={state.gridPageSize}
                page={state.gridPage}
                onPageChange={(page) => setState((s) => ({ ...s, gridPage: page }))}
                onPageSizeChange={(pageSize) => setState((s) => ({ ...s, gridPageSize: pageSize }))}
                onSelectionModelChange={selectSpareTypeHandler}
                selectionModel={state.gridSelection}
                density="compact"
                localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
                hideFooterSelectedRowCount
                hideFooterRowCount
              />
            </Box>
          </Box>
          <Box mt={0} mb={0} px={0} sx={{ display: 'flex', flexDirection: 'row' }}>
            <FormControl sx={{ width: '8rem', mr: 1 }}>
              <TextField
                value={newActivitySpare.quantity}
                name="quantity"
                label="Ilość:"
                margin="normal"
                variant="outlined"
                disabled={!state.selectedSpareType || state.selectedSpareType.stockQuantity === 0}
                InputProps={{
                  endAdornment: <InputAdornment position="end">szt.</InputAdornment>,
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                }}
                type="number"
                size="small"
                onChange={handleNumericInputChange}
                data-testid="input-spare-quantity"
              />
            </FormControl>
            <FormControl sx={{ width: '25rem' }}>
              <TextField
                value={newActivitySpare.description}
                name="description"
                label="Dodatkowy opis:"
                disabled={!state.selectedSpareType || state.selectedSpareType.stockQuantity === 0}
                margin="normal"
                variant="outlined"
                type="text"
                size="small"
                onChange={(event) => handleInputChange(event.target.name, event.target.value)}
                data-testid="input-comment"
              />
            </FormControl>
          </Box>
          {state.error && (
            <Paper sx={{ p: 1, mt: 3, mb: 1, bgcolor: 'error.main', color: 'error.contrastText' }}>
              {state.error}
            </Paper>
          )}
        </>
      </ContentDialog>
    </div>
  );
}

CreateActivitySpareDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  activityId: PropTypes.number.isRequired,
  activityIdPda: PropTypes.string.isRequired,
};

CreateActivitySpareDialog.defaultProps = {
  maxWidth: 'md',
};

export default CreateActivitySpareDialog;
