import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem, TextField, Box, Paper,
} from '@mui/material';

import { DS } from '../_services';
import { uuid } from '../_helpers/uuid';
import { ContentDialog } from '../Common';

function CreateActivityDialog(props) {
  const {
    open,
    onAccept,
    onDecline,
    maxWidth,
    ticketTypeId,
  } = props;
  const { ticketId, ticketIdPda, visitId, visitIdPda } = useParams();

  const [state, setState] = useState({
    dialogTitle: '',
  });

  const [newActivity, setNewActivity] = useState({
    id: 0,
    idPda: '',
    activityCategoryId: '',
    activityTypeId: '',
    comment: '',
  });
  const [activityCategories, setActivityCategories] = useState([]);
  const [activityTypes, setActivityTypes] = useState([]);

  useEffect(() => {
    const fetchNecessaryData = async () => {
      const na = {
        id: 0,
        idPda: uuid(),
        activityCategoryId: '',
        activityTypeId: '',
        comment: '',
        laborCost: 0,
        statusPda: 'N',
        ticketId: Number(ticketId),
        ticketIdPda,
        timeDuration: 0,
        timeStart: moment().format('YYYY-MM-DD HH:mm:ss'),
        visitId: Number(visitId),
        visitIdPda,
      };
      setActivityCategories(await DS.Activity.findAllActivityCategoriesForTicketType(ticketTypeId));
      setNewActivity((s) => ({ ...s, ...na }));
    };

    fetchNecessaryData();
  }, [ticketId, ticketIdPda, visitId, visitIdPda, ticketTypeId]);

  const handleAccept = async (...prop) => {
    setState((s) => ({ ...s, error: '' }));
    try {
      const createdActivity = await DS.Activity.createActivity(newActivity);
      onAccept(createdActivity);
    } catch (e) {
      setState((s) => ({ ...s, error: e.message }));
    }
  };
  const handleDecline = (...prop) => onDecline(...prop);

  const handleActivityCategoryChange = async (value) => {
    setActivityTypes(await DS.Activity.findAllActivityTypesForCategory(value));
    setNewActivity((s) => ({ ...s, activityCategoryId: value, activityTypeId: '' }));
  };

  const handleActivityTypeChange = (value) => setNewActivity((s) => ({ ...s, activityTypeId: Number(value) }));

  const handleInputChange = (field, value) => {
    setNewActivity((s) => ({ ...s, [field]: value }));
  };

  return (
    <div>
      <ContentDialog
        open={open}
        onAccept={handleAccept}
        onDecline={handleDecline}
        dialogTitle="Nowa czynność"
        maxWidth={maxWidth}
        acceptLabel="Dodaj"
        declineLabel="Anuluj"
        acceptDisabled={!newActivity.activityCategoryId || !newActivity.activityTypeId}
      >
        <>
          <Box mt={1} mb={0} px={2}>
            <FormControl fullWidth sx={{ my: 1 }}>
              <InputLabel id="new-activity-category-label" htmlFor="new-activity-category-label">Kategoria</InputLabel>
              <Select
                labelId="new-activity-category-label"
                label="Kategoria"
                id="new-activity-category"
                name="activityCategoryId"
                value={newActivity.activityCategoryId}
                onChange={(event) => handleActivityCategoryChange(event.target.value)}
              >
                {activityCategories.map((tt) => (
                  <MenuItem key={tt.id} value={tt.id}>{tt.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            <FormControl fullWidth sx={{ my: 1 }}>
              <InputLabel id="new-activity-type-label" htmlFor="new-activity-type">Typ</InputLabel>
              <Select
                labelId="new-activity-type-label"
                label="Rodzaj czynności"
                id="new-activity-type"
                name="activityTypeId"
                value={newActivity.activityTypeId}
                disabled={activityTypes?.length === 0}
                onChange={(event) => handleActivityTypeChange(event.target.value)}
              >
                {activityTypes.map((tt) => (
                  <MenuItem key={tt.id} value={tt.id}>{tt.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            <FormControl required fullWidth>
              <TextField
                sx={{ xs: 12 }}
                value={newActivity.comment}
                name="comment"
                label="Dodatkowy opis:"
                margin="normal"
                variant="outlined"
                type="text"
                multiline
                onChange={(event) => handleInputChange(event.target.name, event.target.value)}
                data-testid="input-comment"
              />
            </FormControl>
          </Box>
          {state.error && (
            <Paper sx={{ p: 1, mt: 3, mb: 1, bgcolor: 'error.main', color: 'error.contrastText' }}>
              {state.error}
            </Paper>
          )}
        </>
      </ContentDialog>
    </div>
  );
}

CreateActivityDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  ticketTypeId: PropTypes.number.isRequired,
};

CreateActivityDialog.defaultProps = {
  maxWidth: 'md',
};

export default CreateActivityDialog;
