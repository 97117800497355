import { db } from './db';

/**
 * @returns {Promise<TClient[]>}
 */
const findAllClient = () => db.client.toArray();

/**
 * @param {number} clientId
 * @returns {Promise<TClient>}
 */
const findClient = (clientId) => db.client.get(Number(clientId));

export const Client = {
  findAllClient,
  findClient,
};
