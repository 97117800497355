import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  InputAdornment,
  TextField,
} from '@mui/material';

function QuestionIntForm(props) {
  const {
    /** @type {number} */ fieldId,
    /** @type {string} */ label,
    /** @type {string} */ value,
    /** @type {boolean} */ disabled,
    /** @type {function} */ onChange,
  } = props;

  const [state, setState] = useState({});

  useEffect(() => {
    const ip = {
      inputProps: {
        inputMode: 'numeric',
        pattern: '[0-9]*',
      },
      minValue: 0,
      maxValue: 10000,
    };

    switch (fieldId) {
      case 30:
        ip.inputProps.endAdornment = <InputAdornment position="end">V</InputAdornment>;
        break;
      case 32:
        ip.inputProps.endAdornment = <InputAdornment position="end">g</InputAdornment>;
        break;
      case 34:
        ip.inputProps.endAdornment = <InputAdornment position="end">mA</InputAdornment>;
        ip.minValue = 30;
        break;
    }

    setState(ip);
  }, [fieldId]);

  const inputIntegerChangeHandler = (event) => {
    let { value: val } = event.target;

    if (Number(val) < state.minValue) {
      val = state.minValue;
    }
    if (Number(val) > state.maxValue) {
      val = state.maxValue;
    }

    if (onChange && typeof onChange === 'function') {
      onChange(val);
    }
  };

  return (
    <FormControl sx={{ my: 1 }} fullWidth>
      <TextField
        type="number"
        value={value}
        name={`field_${fieldId}`}
        label={label}
        variant="outlined"
        size="small"
        fullWidth
        InputProps={state.inputProps}
        onChange={inputIntegerChangeHandler}
        disabled={disabled}
      />
    </FormControl>
  );
}

QuestionIntForm.propTypes = {
  fieldId: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

QuestionIntForm.defaultProps = {
  disabled: false,
};

export default QuestionIntForm;
