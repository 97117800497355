import React from 'react';
import { Tab } from '@mui/material';
import PropTypes from 'prop-types';

/**
 * @param {{}} props
 * @param {string} props.label
 * @param {string} props.idPrefix
 * @param {number} props.index
 * @returns {*}
 * @constructor
 */
function TabsTab(props) {
  const { index, idPrefix, label, disabled, visible, ...other } = props;

  if (!visible) {
    return null;
  }

  return (
    <Tab
      label={label}
      id={`${idPrefix}-tab-${index}`}
      value={index}
      aria-controls={`${idPrefix}-tabpanel-${index}`}
      disabled={disabled}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...other}
    />
  );
}

TabsTab.propTypes = {
  index: PropTypes.number.isRequired,
  idPrefix: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  visible: PropTypes.bool,
};

TabsTab.defaultProps = {
  disabled: false,
  visible: true,
};

export { TabsTab };
