import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ContentDialog, LabelValueList } from '../Common';
import { DS } from '../_services';
import { whOrderStatusName } from '../_constants';

function SpareOrderDetailDialog(props) {
  const {
    open,
    onAccept,
    maxWidth,
    spareOrderId,
    spareOrderIdPda,
  } = props;

  const [state, setState] = useState({
    tabIndex: 0,
    dialogTitle: '',
  });

  const [detailSpareOrder, setDetailSpareOrder] = useState([]);

  useEffect(() => {
    const fetchSpareOrder = async () => {
      const spareOrder = await DS.Warehouse.findSpareOrder(spareOrderId, spareOrderIdPda);
      setState((s) => ({ ...s, dialogTitle: `${spareOrder.spareType.name}` }));

      setDetailSpareOrder([
        { label: 'Materiał', value: spareOrder.spareType.name },
        { label: 'ID', value: (spareOrder.spareType.sap4Id || spareOrder.spareType.sapId || '') },
        { label: 'Status', value: whOrderStatusName[`WH_${spareOrder.status}`] },
        { label: 'Źródło', value: spareOrder.sourceWarehouseName || '' },
        { label: 'Adresat', value: spareOrder.destinationWarehouseName || '' },
        { label: 'Data złożenia zamówienia', value: spareOrder.dateOrder },
        { label: 'Komentarz do złożonego zamówienia', value: spareOrder.noticeOrder || '' },
        { label: 'Data wysłania', value: spareOrder.dateSend || '' },
        { label: 'Komentarz do wysyłki', value: spareOrder.noticePrepared || '' },
      ]);
    };

    fetchSpareOrder();
  }, [spareOrderId, spareOrderIdPda]);

  const handleAccept = (...prop) => onAccept(...prop);

  return (
    <div>
      <ContentDialog
        open={open}
        onAccept={handleAccept}
        onDecline={handleAccept}
        dialogTitle={state.dialogTitle}
        maxWidth={maxWidth}
        acceptLabel="OK"
        declineLabel=""
      >
        <div>
          <LabelValueList list={detailSpareOrder} idPrefix="detail-ticket" />
        </div>
      </ContentDialog>
    </div>
  );
}

SpareOrderDetailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  spareOrderId: PropTypes.number.isRequired,
  spareOrderIdPda: PropTypes.string.isRequired,
};

SpareOrderDetailDialog.defaultProps = {
  maxWidth: 'md',
};

export default SpareOrderDetailDialog;
