import React from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './App.css';
import { history } from './_helpers';
import { db } from './_services';
import Login from './Login/Login';
import Synchronize from './Synchro/Synchro';
import WarehouseList from './Warehouse/WarehouseList';
import TicketList from './TicketList/TicketList';
import AppLayout from './Menu/AppLayout';
import Day from './Day/Day';
import Visit from './Visit/Visit';
import TicketResolve from './TicketResolve/TicketResolve';

function NavigateSetter() {
  history.navigate = useNavigate();

  return null;
}

function App() {
  const theme = createTheme();
  db.open().catch((err) => {
    console.error(`Failed to open db: ${err.stack || err}`);
  });

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <AppLayout>
          <BrowserRouter>
            <NavigateSetter />
            <Routes>
              <Route path="/" element={<div />} />
              <Route path="/login" element={<Login />} />
              <Route path="/ticketList" element={<TicketList />} />
              <Route path="/dayOpen" element={<Day />} />
              <Route path="/dayClose" element={<Day dayIsOpen />} />
              <Route path="/visit/:ticketId/:ticketIdPda/:clientId" element={<Visit />} />
              <Route path="/ticketResolve/:ticketId/:ticketIdPda/:visitId/:visitIdPda" element={<TicketResolve />} />
              <Route path="/synchronize" element={<Synchronize />} />
              <Route path="/warehouse/:listType" element={<WarehouseList />} />
            </Routes>
          </BrowserRouter>
        </AppLayout>
      </ThemeProvider>
    </div>
  );
}

export default App;
