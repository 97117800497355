import React from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  FormLabel,
} from '@mui/material';

function QuestionLabelForm(props) {
  const {
    /** @type {string} */ label,
  } = props;

  return (
    <FormGroup sx={{ mt: 2, mb: 0 }}>
      <FormLabel component="div" sx={{ fontWeight: 'bold' }}>
        {label}
      </FormLabel>
    </FormGroup>
  );
}

QuestionLabelForm.propTypes = {
  label: PropTypes.string.isRequired,
};

export default QuestionLabelForm;
