import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  InputAdornment,
  LinearProgress,
  TextField,
} from '@mui/material';
import { DS } from '../_services';
import { history } from '../_helpers';

function Day(props) {
  const { dayIsOpen } = props;
  const [day, setDay] = useState({ submitted: false, carOdometer: '', notice: '', dayIsOpen });

  const handleNumericInputChange = (field, value, min = 0, max = 1000) => {
    if (parseInt(value, 10) >= min && parseInt(value, 10) <= max) {
      setDay((s) => ({ ...s, [field]: value }));
    }
  };

  const handleInputChange = (field, value) => {
    setDay((s) => ({ ...s, [field]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDay((s) => ({ ...s, submitted: true }));
    if (!day.carOdometer) {
      return;
    }

    setDay((s) => ({ ...s, saving: true, error: false }));
    if (dayIsOpen) {
      await DS.VisitDay.stopVisitDay(day.carOdometer, day.notice);
    } else {
      await DS.VisitDay.startVisitDay(day.carOdometer);
    }
    // setDay((s) => ({ ...s, saving: false }));
    history.push('/synchronize');
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="normal"
      alignItems="flex-start"
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      rowSpacing={{ xs: 1 }}
    >
      <Grid item xs={8} sx={{ textAlign: 'left' }}>
        <form noValidate name="form" onSubmit={handleSubmit}>
          <FormControl margin="normal" required fullWidth>
            <TextField
              type="number"
              value={day.carOdometer}
              name="carOdometer"
              label="Licznik"
              margin="normal"
              variant="standard"
              InputProps={{
                endAdornment: <InputAdornment position="end">km</InputAdornment>,
                inputMode: 'numeric',
                pattern: '[0-9]*',
              }}
              onChange={(event) => handleNumericInputChange(event.target.name, event.target.value, 0, 1000000)}
              error={day.submitted && !day.carOdometer}
              autoFocus
              data-testid="input-carOdometer"
            />
            {day.submitted && !day.carOdometer && (
              <FormHelperText error data-testid="label-error-empty-carOdometer">
                Podanie stanu licznika jest wymagane
              </FormHelperText>
            )}
          </FormControl>
          {dayIsOpen && (
            <FormControl margin="normal" required fullWidth>
              <TextField
                value={day.notice}
                name="notice"
                label="Uwagi"
                margin="normal"
                variant="outlined"
                type="text"
                multiline
                onChange={(event) => handleInputChange(event.target.name, event.target.value)}
                data-testid="input-notice"
              />
            </FormControl>
          )}
          {day.error && (
            <FormLabel color="error" error data-testid="label-error-submit">
              Wystąpił problem z zapisem informacji
            </FormLabel>
          )}
          <Button
            color="primary"
            type="submit"
            variant="contained"
            fullWidth
            disabled={day.saving}
            data-testid="button-submit"
          >
            {dayIsOpen ? 'Zakończ dzień' : 'Rozpocznij dzień'}
          </Button>
          <div>
            {day.saving && <LinearProgress data-testid="linearProgres" />}
          </div>
        </form>
      </Grid>
    </Grid>
  );
}

Day.propTypes = {
  dayIsOpen: PropTypes.bool,
};

Day.defaultProps = {
  dayIsOpen: false,
};

export default Day;
