import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  InputAdornment,
  TextField,
} from '@mui/material';

import { DS } from '../_services';

function VisitForm(props) {
  const { sessionVisit, onChange } = props;
  const { clientId } = useParams();
  const [visit, setVisit] = useState(sessionVisit);

  /**
   * @param {Event} event
   * @param {number} min
   * @param {number} max
   */
  const handleNumericInputChange = (event, min = 0, max = 1000) => {
    event.preventDefault();
    if (parseInt(event.target.value, 10) >= min && parseInt(event.target.value, 10) <= max) {
      setVisit((s) => ({ ...s, [event.target.name]: event.target.value }));
    }
  };

  /**
   * @param {Event} e
   */
  const handleStartVisit = (e) => {
    e.preventDefault();
    setVisit((s) => ({ ...s, saving: true, submitted: true }));
    if (onChange && (typeof onChange === 'function')) {
      onChange();
    }
    if (visit.visitInProgress) {
      DS.Visit.stopVisitSession();
      setVisit((s) => ({ ...s, saving: false, error: false, visitInProgress: false }));
      return;
    }
    if (!visit.visitCarOdometer) {
      return;
    }
    DS.Visit.startVisitSession(clientId, visit.visitCarOdometer);
    setVisit((s) => ({ ...s, saving: false, error: false, visitInProgress: true }));
  };

  return (
    <>
      <Box component="h4" sx={{ mt: 1, mb: 0 }}>
        {visit.visitInProgress
          ? 'Aby zakończyć wizytę kliknij Zakończ:'
          : 'Aby rozpocząć wizytę wprowadź stan licznika:'}
      </Box>
      <form
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}
        noValidate
        name="form"
        onSubmit={handleStartVisit}
      >
        <FormControl required>
          <TextField
            type="number"
            value={visit.visitCarOdometer}
            name="visitCarOdometer"
            label="Licznik"
            variant="standard"
            sx={{ mr: 2, mt: 2 }}
            InputProps={{
              endAdornment: <InputAdornment position="end">km</InputAdornment>,
              inputMode: 'numeric',
              pattern: '[0-9]*',
            }}
            onChange={(event) => handleNumericInputChange(event, 0, 1000000)}
            error={visit.submitted && !visit.visitCarOdometer}
            disabled={visit.visitInProgress}
            autoFocus
            data-testid="input-visitCarOdometer"
          />
          {visit.submitted && !visit.visitCarOdometer && (
            <FormHelperText error data-testid="label-error-empty-visitCarOdometer">
              Podanie stanu licznika jest wymagane
            </FormHelperText>
          )}
        </FormControl>
        {visit.error && (
          <FormLabel color="error" error data-testid="label-error-submit">
            Wystąpił problem z zapisem informacji
          </FormLabel>
        )}
        <Button
          color="primary"
          type="submit"
          variant="contained"
          disabled={visit.saving}
          data-testid="button-submit"
        >
          {visit.visitInProgress ? 'Zakończ' : 'Rozpocznij'}
        </Button>
      </form>
    </>
  );
}

VisitForm.propTypes = {
  sessionVisit: PropTypes.shape(Object).isRequired,
  onChange: PropTypes.func,
};

VisitForm.defaultProps = {
  onChange: null,
};

export default VisitForm;
