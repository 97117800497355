import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  FormControl,
  FormGroup,
  FormLabel,
  InputAdornment,
  LinearProgress,
  Paper,
  TextField,
} from '@mui/material';

import { DS } from '../_services';
import { ContentDialog } from '../Common';

function CreateSpareOrderDialog(props) {
  const {
    open,
    onAccept,
    onDecline,
    maxWidth,
    spareTypeId,
  } = props;

  const [state, setState] = useState({
    dialogTitle: '',
    gridPage: 0,
    gridPageSize: 25,
    gridSelection: [],
    selectedSpareType: null,
    submitted: false,
  });

  const [newSpareOrder, setNewSpareOrder] = useState({
    spareTypeId: '',
    quantity: 0,
    description: '',
    spareType: null,
  });

  useEffect(() => {
    const fetchNecessaryData = async () => {
      const spareType = await DS.Warehouse.findSpareType(spareTypeId);
      const nas = {
        spareTypeId: Number(spareTypeId),
        quantity: 0,
        description: '',
        spareType,
      };
      setNewSpareOrder((s) => ({ ...s, ...nas }));
    };

    fetchNecessaryData();
  }, [spareTypeId]);

  /**
   * @return {Promise<void>}
   */
  const handleAccept = async () => {
    setState((s) => ({ ...s, error: '', submitted: true }));
    try {
      await DS.Warehouse.addSparePartOrder(newSpareOrder);
      onAccept(true);
    } catch (e) {
      setState((s) => ({ ...s, error: e.message, submitted: false }));
    }
  };

  const handleDecline = () => onDecline(false);

  /**
   * @param {string} field
   * @param {string} value
   */
  const handleInputChange = (field, value) => {
    setNewSpareOrder((s) => ({ ...s, [field]: value }));
  };

  /**
   * @param {Event} event
   */
  const handleNumericInputChange = (event) => {
    event.preventDefault();

    if (
      Number(event.target.value) >= 0
      && Number(event.target.value) <= 10000
    ) {
      setNewSpareOrder((s) => ({ ...s, quantity: Number(event.target.value) }));
    }
  };

  return (
    <div>
      <ContentDialog
        open={open}
        onAccept={handleAccept}
        onDecline={handleDecline}
        dialogTitle="Dodaj zamówienie części"
        maxWidth={maxWidth}
        acceptLabel="Dodaj"
        declineLabel="Anuluj"
        acceptDisabled={Number(newSpareOrder.quantity) === 0 || state.submitted}
      >
        <>
          <Box mt={0} mb={0} px={0} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <FormGroup sx={{ mt: 2, mb: 0 }}>
              <FormLabel component="div" sx={{ fontWeight: 'bold' }}>
                {newSpareOrder.spareType?.name}
              </FormLabel>
            </FormGroup>
            <FormControl sx={{ width: '16rem', mr: 1 }}>
              <TextField
                value={newSpareOrder.quantity}
                name="quantity"
                label="Ilość:"
                margin="normal"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {`opak. (${newSpareOrder.quantity * (newSpareOrder.spareType?.packQuantity ?? 0)} szt.)`}
                    </InputAdornment>
                  ),
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                }}
                type="number"
                size="small"
                onChange={handleNumericInputChange}
                data-testid="input-spare-quantity"
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                value={newSpareOrder.description}
                name="description"
                label="Dodatkowy opis:"
                disabled={newSpareOrder.quantity === 0}
                margin="normal"
                variant="outlined"
                type="text"
                size="small"
                onChange={(event) => handleInputChange(event.target.name, event.target.value)}
                data-testid="input-comment"
              />
            </FormControl>
          </Box>
          {state.error && (
            <Paper sx={{ p: 1, mt: 3, mb: 1, bgcolor: 'error.main', color: 'error.contrastText' }}>
              {state.error}
            </Paper>
          )}
          {state.submitted && <LinearProgress data-testid="linearProgres" />}
        </>
      </ContentDialog>
    </div>
  );
}

CreateSpareOrderDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  spareTypeId: PropTypes.number.isRequired,
};

CreateSpareOrderDialog.defaultProps = {
  maxWidth: 'md',
};

export default CreateSpareOrderDialog;
