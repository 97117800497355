import React from 'react';
import PropTypes from 'prop-types';
import {
  Chip,
} from '@mui/material';
import {
  Assignment as IconAssignment,
  AssignmentInd as IconAssignmentInd,
  AssignmentReturned as IconAssignmentReturned,
  AssignmentTurnedIn as IconAssignmentTurnedIn,
} from '@mui/icons-material';

function TicketStatusChip(props) {
  const { ticketStatus, ticketId, ticketIdPda, clientId, enabled, onClick } = props;

  const assignedComponent = enabled ? (
    <Chip
      size="small"
      color="success"
      icon={<IconAssignmentInd />}
      onClick={(event) => onClick(event, ticketId, ticketIdPda, clientId)}
      label="Przypisane"
    />
  ) : (
    <Chip
      size="small"
      icon={<IconAssignmentInd />}
      label="Przypisane"
    />
  );

  const statusList = {
    NEW: (<Chip size="small" icon={<IconAssignment />} label="Nowe" />),
    ASSIGNED: assignedComponent,
    RESOLVED: (<Chip size="small" icon={<IconAssignmentTurnedIn />} label="Rozwiązane" />),
    CLOSED: (<Chip size="small" icon={<IconAssignmentReturned />} label="Zamknięte" />),
  };

  return statusList[ticketStatus] ? statusList[ticketStatus] : null;
}

TicketStatusChip.propTypes = {
  ticketStatus: PropTypes.oneOf(['NEW', 'ASSIGNED', 'RESOLVED', 'CLOSED']).isRequired,
  clientId: PropTypes.number.isRequired,
  ticketId: PropTypes.number.isRequired,
  ticketIdPda: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default TicketStatusChip;
