import { db } from './db';

/**
 * @returns {Promise<TTicketType[]>}
 */
const findAllTicketType = () => db.ticketType.toArray();

/**
 * @param {number} ticketTypeId
 * @returns {Promise<TTicketType>}
 */
const findTicketType = (ticketTypeId) => db.ticketType.get(Number(ticketTypeId));

export const TicketType = {
  findAllTicketType,
  findTicketType,
};
