import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  FormControl,
  FormGroup,
  FormLabel,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper, Select,
  TextField,
} from '@mui/material';

import { DS } from '../_services';
import { ContentDialog } from '../Common';

function CreateSpareSendToOtherDialog(props) {
  const {
    open,
    onAccept,
    onDecline,
    maxWidth,
    spareTypeId,
  } = props;

  const [state, setState] = useState({
    dialogTitle: '',
    gridPage: 0,
    gridPageSize: 25,
    gridSelection: [],
    selectedSpareType: null,
    submitted: false,
  });
  const [recipientList, setRecipientList] = useState([]);

  const [newSpareSend, setNewSpareSend] = useState({
    spareTypeId: '',
    recipientId: '',
    quantity: 1,
    description: '',
    spareType: null,
  });

  useEffect(() => {
    const fetchNecessaryData = async () => {
      const spareType = await DS.Warehouse.findSpareType(spareTypeId);
      const nas = {
        spareTypeId: Number(spareTypeId),
        recipientId: '',
        quantity: 1,
        description: '',
        spareType,
      };
      setNewSpareSend((s) => ({ ...s, ...nas }));
      setRecipientList(await DS.Warehouse.findAllOtherUser());
    };

    fetchNecessaryData();
  }, [spareTypeId]);

  /**
   * @return {Promise<void>}
   */
  const handleAccept = async () => {
    setState((s) => ({ ...s, error: '', submitted: true }));
    try {
      await DS.Warehouse.addSparePartSendToOtherUser(newSpareSend);
      onAccept(true);
    } catch (e) {
      setState((s) => ({ ...s, error: e.message, submitted: false }));
    }
  };

  const handleDecline = () => onDecline(false);

  /**
   * @param {string} field
   * @param {string} value
   */
  const handleInputChange = (field, value) => {
    setNewSpareSend((s) => ({ ...s, [field]: value }));
  };

  /**
   * @param {Event} event
   */
  const handleNumericInputChange = (event) => {
    event.preventDefault();

    if (
      Number(event.target.value) >= 1
      && Number(event.target.value) <= 10000
    ) {
      setNewSpareSend((s) => ({ ...s, quantity: Number(event.target.value) }));
    }
  };

  return (
    <div>
      <ContentDialog
        open={open}
        onAccept={handleAccept}
        onDecline={handleDecline}
        dialogTitle="Wysłanie części"
        maxWidth={maxWidth}
        acceptLabel="Wyślij"
        declineLabel="Anuluj"
        acceptDisabled={Number(newSpareSend.quantity) === 0 || !newSpareSend.recipientId || state.submitted}
      >
        <>
          <Box mt={0} mb={0} px={0} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <FormGroup sx={{ mt: 2, mb: 0 }}>
              <FormLabel component="div" sx={{ fontWeight: 'bold' }}>
                {newSpareSend.spareType?.name}
              </FormLabel>
            </FormGroup>
            <FormControl sx={{ width: '20rem', mt: 2, mb: 0 }} size="small">
              <InputLabel
                id="recipient-list-label"
                htmlFor="recipient-list"
              >
                Adresat wysyłki
              </InputLabel>
              <Select
                labelId="recipient-list-label"
                id="recipient-list"
                label="Adresat wysyłki"
                name="recipientId"
                value={newSpareSend.recipientId}
                onChange={(event) => handleInputChange(event.target.name, event.target.value)}
              >
                {recipientList.map((sg) => (
                  <MenuItem key={sg.id} value={sg.id}>{sg.userName}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: '20rem', mt: 1, mb: 0, py: 0 }}>
              <TextField
                value={newSpareSend.quantity}
                name="quantity"
                label="Ilość:"
                margin="normal"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {`opak. (${newSpareSend.quantity * (newSpareSend.spareType?.packQuantity ?? 0)} szt.)`}
                    </InputAdornment>
                  ),
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                }}
                type="number"
                size="small"
                onChange={handleNumericInputChange}
                data-testid="input-spare-quantity"
              />
            </FormControl>
            <FormControl fullWidth sx={{ m: 0, p: 0 }} size="small">
              <TextField
                value={newSpareSend.description}
                name="description"
                label="Dodatkowy opis:"
                disabled={newSpareSend.quantity === 0}
                margin="normal"
                variant="outlined"
                type="text"
                size="small"
                onChange={(event) => handleInputChange(event.target.name, event.target.value)}
                data-testid="input-comment"
              />
            </FormControl>
          </Box>
          {state.error && (
            <Paper sx={{ p: 1, mt: 3, mb: 1, bgcolor: 'error.main', color: 'error.contrastText' }}>
              {state.error}
            </Paper>
          )}
          {state.submitted && <LinearProgress data-testid="linearProgres" />}
        </>
      </ContentDialog>
    </div>
  );
}

CreateSpareSendToOtherDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  spareTypeId: PropTypes.number.isRequired,
};

CreateSpareSendToOtherDialog.defaultProps = {
  maxWidth: 'md',
};

export default CreateSpareSendToOtherDialog;
