import { apiService } from './api.service';
import { userType } from '../_constants';

function logout() {
  // remove user from local storage to log user out
  sessionStorage.removeItem('token');
  localStorage.removeItem('userId');
  localStorage.removeItem('userTypeId');
  localStorage.removeItem('serviceId');
  localStorage.removeItem('warehouseId');
  localStorage.removeItem('userName');
}

/**
 * @param username {string}
 * @param password {string}
 * @returns {Promise<unknown>}
 */
function login(username, password) {
  return new Promise((resolve, reject) => {
    apiService.post('ipcosJson/login', { username, password })
      .then((response) => {
        sessionStorage.setItem('token', response.token || '');
        localStorage.setItem('userId', parseInt((response.userId || '0'), 10));
        localStorage.setItem('userTypeId', parseInt((response.userTypeId || 0), 10));
        localStorage.setItem('serviceId', parseInt((response.serviceId || 0), 10));
        localStorage.setItem('warehouseId', parseInt((response.warehouseId || 0), 10));
        localStorage.setItem('userName', response.userName || '');

        resolve(!!(response.token || false));
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * @param {string} configKey
 * @returns {string|null}
 */
function getAppConfigByKey(configKey) {
  const configurations = JSON.parse(localStorage.getItem('applicationConfig')) || [];
  const config = configurations.filter((conf) => conf.configKey === configKey);

  if (!config || !config[0] || !Object.prototype.hasOwnProperty.call(config[0], 'configValue')) {
    return null;
  }

  return config[0].configValue || null;
}

/**
 * @returns {{isTechnic: boolean, isController: boolean}}
 */
const getUserType = () => {
  const userTypeId = parseInt(localStorage.getItem('userTypeId') || 0, 10);
  return {
    isTechnic: userTypeId === userType.UT_TECHNIC,
    isController: userTypeId === userType.UT_CONTROLLER,
  };
};

export const userService = {
  login,
  logout,
  getAppConfigByKey,
  getUserType,
};
