import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  FormControl,
  TextField,
  Box,
  Paper,
  FormGroup,
  FormLabel,
} from '@mui/material';

import { DS } from '../_services';
import { SPARE_VALVE_ID } from '../_constants';
import { ContentDialog } from '../Common';
import { uuid } from '../_helpers/uuid';

function EditPosValveDialog(props) {
  const {
    open,
    onAccept,
    onDecline,
    maxWidth,
    posId,
    posValveId,
    posValveIdPda,
  } = props;

  const [state, setState] = useState({
    dialogTitle: '',
  });

  const [newPosValve, setNewPosValve] = useState({
    id: 0,
    idPda: '',
    active: 1,
    lastModifyByUserId: 0,
    lastModifyDate: moment().format('YYYY-MM-DD HH:mm:ss'),
    posId: Number(posId),
    serialNo: '',
    spareTypeId: 1005,
    statusPda: 'N',
  });

  useEffect(() => {
    const fetchNecessaryData = async () => {
      if (posValveId !== 0 || posValveIdPda !== '') {
        const posValve = await DS.Ticket.findPosValve(posValveId, posValveIdPda);
        setNewPosValve(posValve);
      } else {
        const spareType = await DS.Warehouse.findSpareType(SPARE_VALVE_ID);
        const npv = {
          id: Number(posValveId),
          idPda: uuid(),
          active: 1,
          lastModifyByUserId: 0,
          lastModifyDate: moment().format('YYYY-MM-DD HH:mm:ss'),
          posId: Number(posId),
          serialNo: '',
          spareTypeId: 1005,
          statusPda: 'N',
          spareType,
        };
        setNewPosValve(npv);
      }
    };

    fetchNecessaryData();
  }, [posId, posValveId, posValveIdPda]);

  const handleAccept = async (...prop) => {
    setState((s) => ({ ...s, error: '' }));
    try {
      const createdPosValve = await DS.Ticket.upsertPosValve(newPosValve);
      onAccept(createdPosValve);
    } catch (e) {
      setState((s) => ({ ...s, error: e.message }));
    }
  };
  const handleDecline = (...prop) => onDecline(...prop);

  const handleInputChange = (field, value) => {
    setNewPosValve((s) => ({ ...s, [field]: value }));
  };

  return (
    <div>
      <ContentDialog
        open={open}
        onAccept={handleAccept}
        onDecline={handleDecline}
        dialogTitle={Number(posValveId) === 0 && posValveIdPda === '' ? 'Dodaj zawór' : 'Edytuj zawór'}
        maxWidth={maxWidth}
        acceptLabel={Number(posValveId) === 0 && posValveIdPda === '' ? 'Dodaj' : 'Zapisz'}
        declineLabel="Anuluj"
        acceptDisabled={!newPosValve.serialNo}
      >
        <>
          <Box mt={1} mb={0} px={2}>
            <FormGroup sx={{ mt: 2, mb: 0 }}>
              <FormLabel component="div" sx={{ fontWeight: 'bold' }}>
                {newPosValve.spareType?.name}
              </FormLabel>
            </FormGroup>
            <FormControl required fullWidth>
              <TextField
                sx={{ xs: 12 }}
                value={newPosValve.serialNo}
                name="serialNo"
                label="Numer seryjny:"
                margin="normal"
                variant="outlined"
                type="text"
                size="small"
                multiline
                onChange={(event) => handleInputChange(event.target.name, event.target.value)}
                data-testid="input-comment"
              />
            </FormControl>
          </Box>
          {state.error && (
            <Paper sx={{ p: 1, mt: 3, mb: 1, bgcolor: 'error.main', color: 'error.contrastText' }}>
              {state.error}
            </Paper>
          )}
        </>
      </ContentDialog>
    </div>
  );
}

EditPosValveDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  posId: PropTypes.number.isRequired,
  posValveId: PropTypes.number.isRequired,
  posValveIdPda: PropTypes.string.isRequired,
};

EditPosValveDialog.defaultProps = {
  maxWidth: 'md',
};

export default EditPosValveDialog;
