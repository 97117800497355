import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { DS } from '../_services';

let spareCategoriesAll = [];
const loadData = async () => {
  spareCategoriesAll = await DS.Warehouse.findAllSpareCategory();
};
loadData();

function SpareFilter(props) {
  const { onChange } = props;
  const [whFilter, setWhFilter] = useState({
    spareGroupId: 'ALL',
    spareCategory1Id: '',
    spareCategory2Id: '',
    spareName: '',
  });
  const spareCategories1 = [
    { id: '', name: 'Wszystkie' },
    ...(spareCategoriesAll.filter((item) => item.level === 1)),
  ];
  const [spareCategories2, setSpareCategories2] = useState([]);
  const spareGroups = [
    { id: 'ALL', name: 'Wszystkie' },
    { id: 'ELEC', name: 'Elektryczne' },
    { id: 'MECH', name: 'Mechaniczne' },
  ];

  const changeFilterHandler = (filterValue) => {
    if (onChange && typeof onChange === 'function') {
      onChange(filterValue);
    }
  };

  const spareGroupChangeHandler = (newValue) => {
    changeFilterHandler({ ...whFilter, spareGroupId: newValue });
    setWhFilter((s) => ({ ...s, spareGroupId: newValue }));
  };

  const spareCategory1ChangeHandler = (newValue) => {
    changeFilterHandler({ ...whFilter, spareCategory1Id: newValue, spareCategory2Id: '' });
    setWhFilter((s) => ({ ...s, spareCategory1Id: newValue, spareCategory2Id: '' }));
    setSpareCategories2([
      { id: '', name: 'Wszystkie' },
      ...(spareCategoriesAll.filter((item) => item.parentCategoryId === Number(newValue))),
    ]);
  };

  const spareCategory2ChangeHandler = (newValue) => {
    changeFilterHandler({ ...whFilter, spareCategory2Id: newValue });
    setWhFilter((s) => ({ ...s, spareCategory2Id: newValue }));
  };

  const spareNameChangeHandler = (newValue) => {
    changeFilterHandler({ ...whFilter, spareName: newValue });
    setWhFilter((s) => ({ ...s, spareName: newValue }));
  };

  return (
    <Grid container sx={{ mt: 2, px: 0 }} alignItems="baseline">
      <form
        style={{ display: 'flex', flexDirection: 'row' }}
        noValidate
        name="form"
        onSubmit={changeFilterHandler}
      >
        <FormControl size="small" sx={{ mr: 1, minWidth: '8rem' }}>
          <InputLabel id="wh-spare-group-label" htmlFor="wh-spare-group">Grupa części</InputLabel>
          <Select
            labelId="wh-spare-group-label"
            label="Grupa części"
            id="wh-spare-group"
            name="spareGroupId"
            value={whFilter.spareGroupId}
            onChange={(event) => spareGroupChangeHandler(event.target.value)}
          >
            {spareGroups.map((sg) => (
              <MenuItem key={sg.id} value={sg.id}>{sg.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ mr: 1, minWidth: '8rem' }}>
          <InputLabel id="wh-spare-category1-label" htmlFor="wh-spare-category1">Kategoria 1</InputLabel>
          <Select
            labelId="wh-spare-category1-label"
            label="Kategoria 1"
            id="wh-spare-category1"
            name="spareCategory1Id"
            value={whFilter.spareCategory1Id}
            onChange={(event) => spareCategory1ChangeHandler(event.target.value)}
          >
            {spareCategories1.map((sg) => (
              <MenuItem key={sg.id} value={sg.id}>{sg.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ mr: 1, minWidth: '8em' }}>
          <InputLabel id="wh-spare-category2-label" htmlFor="wh-spare-category2">Kategoria 2</InputLabel>
          <Select
            labelId="wh-spare-category2-label"
            label="Kategoria 2"
            id="wh-spare-category2"
            name="spareCategory2Id"
            value={whFilter.spareCategory2Id}
            disabled={spareCategories2.length === 0}
            onChange={(event) => spareCategory2ChangeHandler(event.target.value)}
          >
            {spareCategories2.map((sg) => (
              <MenuItem key={sg.id} value={sg.id}>{sg.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ mr: 1, minWidth: '8rem' }}>
          <TextField
            size="small"
            value={whFilter.spareName}
            name="spareName"
            label="Wyszukaj"
            variant="outlined"
            type="text"
            onChange={(event) => spareNameChangeHandler(event.target.value)}
            data-testid="input-name"
          />
        </FormControl>
      </form>
    </Grid>
  );
}

SpareFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default SpareFilter;
