import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@mui/material';

function QuestionBoolForm(props) {
  const {
    /** @type {number} */ fieldId,
    /** @type {string} */ label,
    /** @type {boolean} */ value,
    /** @type {boolean} */ disabled,
    /** @type {function} */ onChange,
  } = props;

  const checkboxChangeHandler = (event) => {
    if (onChange && typeof onChange === 'function') {
      onChange(event.target.checked);
    }
  };

  return (
    <FormGroup sx={{ my: 0 }}>
      <FormControlLabel
        control={(
          <Checkbox
            disabled={disabled}
            name={`field_${fieldId}`}
            onChange={checkboxChangeHandler}
            checked={value}
          />
        )}
        label={label}
      />
    </FormGroup>
  );
}

QuestionBoolForm.propTypes = {
  fieldId: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

QuestionBoolForm.defaultProps = {
  disabled: false,
};

export default QuestionBoolForm;
